import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from "@angular/router";
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DataTablesModule } from 'angular-datatables';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { OpportunityNotInterestedListComponent } from './list/opportunity-not-interested-list/opportunity-not-interested-list.component';



const routes: Routes = [
  {
    path: "opportunityNotInterestedList",
    component: OpportunityNotInterestedListComponent
  },
];

@NgModule({
  declarations: [OpportunityNotInterestedListComponent],
  imports: [
    RouterModule.forChild(routes),
    CommonModule,
    ReactiveFormsModule,
    DataTablesModule,
    FormsModule,
    NgbModule
    
  ],
  
  entryComponents: [
    OpportunityNotInterestedListComponent  ],
})
export class OpportunityNotInterestedModule { }