import { Component, OnInit, Input } from '@angular/core';
import { FormArray, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { MastersService } from 'src/app/services/masters.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { data } from 'jquery';

@Component({
  selector: 'app-salarygradecreate',
  templateUrl: './salarygradecreate.component.html',
  styleUrls: ['./salarygradecreate.component.sass']
})
export class SalarygradecreateComponent implements OnInit {

  @Input() id;
  @Input() action;
  poupuTitle: string;
  submitted: boolean;
  salaryGradeForm: FormGroup;
  tableName: string;
  salaryCompList: any;
  salaryGradeDetails = [];
  addSalaryGradeDetailsDisabled = false;
  AddMoreDisabled = false;

  SalaryComponentList: any;
  
  userMasterId: any;
  constructor(
    private modalService: NgbModal,
    private _formBuilder: FormBuilder,
    private toastr: ToastrService,
    private router: Router,
    public MasterService: MastersService,
    public activeModal: NgbActiveModal,
    private spinnerService: Ng4LoadingSpinnerService
  ) { }

  ngOnInit() {
    this.userMasterId = localStorage.getItem("userMasterId");
    this.action = this.action;
    
    if (this.action === "Edit") {
      this.poupuTitle = "Edit Salary Grade";
      this.MasterService.GetSalaryGradebyId(this.id).then(data => {
        console.log("datapatch", data.data);
        this.salaryGradeForm.patchValue(data.data);
      });

      this.MasterService.SalaryComponentList(this.id).subscribe((e: any) => {
        e.data.forEach((x, index) => {
          const documentArray = this.salaryGradeForm.controls.salaryGradeDetails as FormArray;
          documentArray.push(
            this._formBuilder.group({
              id: x.salaryCompId,
              component: x.salaryComponent,
              percentage: x.percentage,
              isCheck: x.isCheck,
            })
          );
        });
      });

    } else if (this.action === "Add") {
      this.MasterService.SalaryComponentList(this.id).subscribe((e: any) => {
        e.data.forEach((x, index) => {
          const documentArray = this.salaryGradeForm.controls.salaryGradeDetails as FormArray;
          documentArray.push(
            this._formBuilder.group({
              id: x.salaryCompId,
              component: x.salaryComponent,
              percentage: x.percentage,
              isCheck: x.isCheck,
            })
          );
        });
      });
      this.poupuTitle = "Add Salary Grade";
    }
    this.salaryGradeForm = this.createDecreateSalaryGradeForm();
  }

  ngAfterViewInit(): void {
    // let contactDetails = ((this.salaryGradeForm.get('salaryGradeDetails') as FormArray).length + 1);
    // setTimeout(() => {
    //   // alert('here');
    //   for (let i = 0; i < contactDetails; i++) {
    //     $("#percentage_" + i).css('pointer-events', 'none');
    //   }
    // }, 1000);
  }

  salaryGradeId(salaryGradeId: any) {
    throw new Error("Method not implemented.");
  }

  createDecreateSalaryGradeForm(): FormGroup {
    return (this.salaryGradeForm = this._formBuilder.group({
      salaryGradeTitle: ["", Validators.required],
      salaryGradeType: ["", Validators.required],
      salaryGradeInfo: ["", Validators.required],
      salaryGradeDetails: this._formBuilder.array([]),
    }));
  }

  get f() {
    return this.salaryGradeForm.controls;
  }

  onSubmit() {
    this.spinnerService.show();
    this.submitted = true;
    if (this.salaryGradeForm.invalid) {
      this.toastr.error("Error !! Record not created");
      return;
    }
    //console.log('here-->');
    let costDetailsArray = ((this.salaryGradeForm.get('salaryGradeDetails') as FormArray).length);
    //console.log('costDetailsArray-->' + costDetailsArray);
    let totalPercentage = 0;
    for (let i = 0; i < costDetailsArray; i++) {
      let Percentage = this.salaryGradeForm.controls['salaryGradeDetails']['controls'][i]['controls']['percentage'].value;
      totalPercentage = totalPercentage + parseFloat(Percentage);
    }
    // alert(totalPercentage);
    if (totalPercentage > 100) {
      this.toastr.error("The sum of percentage must be less than 100.", "Error!");
      return;
    }
    // return;
    this.MasterService.createSalaryGrade(this.salaryGradeForm.value, this.salaryGradeId,this.userMasterId).subscribe((response: any) => {

      if (response.code === 1) {
        this.activeModal.close();
        this.router.navigateByUrl("/app/refresh", { skipLocationChange: true }).then(() => {
          this.router.navigate(["/masters/salarygrade/salarygradelist"]);
        });
        return true;
      } else if (response.code == 0) {
        this.toastr.error(response.msg, "Error!");
        return false;
      } else {
        this.toastr.error("Error !! Record not created");
        return false;
      }
    },
      error => {
        console.log("failure", error);
        return false;
      }
    );
  }

  alphabetOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if ((charCode > 64 && charCode < 91) || (charCode > 96 && charCode < 123) || charCode == 8 || (charCode == 32)) {
      // this.onlyAlphabet =false;

      return true;
    } else {
      //this.onlyAlphabet =true;
      //alert("only alphabates are allowed")
      return false;
    }
  }

  UpdateSalaryGrade() {
    this.spinnerService.show();
    this.submitted = true;
    //this.options.value.token = localStorage.getItem("token");
    // this.options.value.updated_by_id = localStorage.getItem("created_by_id");
    this.salaryGradeForm.value.id = this.id;
    if (this.salaryGradeForm.invalid) {
      this.toastr.error("Error !! Record not created");
      return;
    }
    let costDetailsArray = ((this.salaryGradeForm.get('salaryGradeDetails') as FormArray).length);
    //console.log('costDetailsArray-->' + costDetailsArray);
    let totalPercentage = 0;
    for (let i = 0; i < costDetailsArray; i++) {
      let Percentage = this.salaryGradeForm.controls['salaryGradeDetails']['controls'][i]['controls']['percentage'].value;
      totalPercentage = totalPercentage + parseFloat(Percentage);
    }
    // alert(totalPercentage);
    if (totalPercentage > 100) {
      this.toastr.error("The sum of percentage must be less than 100.", "Error!");
      return;
    }

    this.MasterService.UpdatesalaryGradeForm(this.id, this.salaryGradeForm.value,this.userMasterId).subscribe((response: any) => {
      if (response.code == 1) {
        this.activeModal.close();
        this.toastr.success("Success !", response.msg);

        this.router
          .navigateByUrl("/app/refresh", { skipLocationChange: true })
          .then(() => {
            this.router.navigate(["/masters/salarygrade/salarygradelist"]);
          });
        return true;
      } else if (response.code == 0) {
        this.toastr.error(response.msg, "Error!");
        return false;
      } else {
        this.toastr.error("Record not created!", "Error!");
        return false;
      }
    },
      error => {
        this.toastr.warning("Internal Server Error !!", "Error!");
        return false;
      }
    );

  }
  ///////////////////////////////////////
  addSalaryGradeDetails() {
    const documentArray = this.salaryGradeForm.controls.salaryGradeDetails as FormArray;
    documentArray.push(
      this._formBuilder.group({
        id: [""],
        component: [""],
        percentage: ["", Validators.required],
        isCheck: [""],
      })
    );
  }

  getControls() {
    return (this.salaryGradeForm.get("salaryGradeDetails") as FormArray).controls;
  }

  deletesalaryGradeDetails(index) {
    const salaryGradeDetails = this.salaryGradeForm.controls.salaryGradeDetails as FormArray;
    let projectTeamAllocationDetailsId = salaryGradeDetails.value[index].projectTeamAllocationDetailsId;
    if (this.action === "Add") {
      (this.salaryGradeForm.get('salaryGradeDetails') as FormArray).removeAt(index);
      this.toastr.success("Success !! Row deleted successfully");
    } else {
      if (!(projectTeamAllocationDetailsId)) {
        (this.salaryGradeForm.get('salaryGradeDetails') as FormArray).removeAt(index);
        this.toastr.success("Success !! Row deleted successfully");
      }
      //  else {
      //   this.MasterService.deletesalaryGradeDetails(projectTeamAllocationDetailsId).subscribe((response: any) => {
      //     if (response.code == 1) {
      //       this.toastr.success("Success !", response.msg);
      //       this.router
      //         .navigateByUrl("/app/refresh", { skipLocationChange: true })
      //         .then(() => {
      //           this.router.navigate(["/project/projectCreate/" + this.id + "/Edit"]);
      //         });
      //       return true;
      //     } else if (response.code == 0) {
      //       this.toastr.error(response.msg, "Error!");
      //       return false;
      //     } else {
      //       this.toastr.error("Record not created!", "Error!");
      //       return false;
      //     }
      //   },
      //     error => {
      //       this.toastr.warning("Internal Server Error !!", "Error!");
      //       return false;
      //     }
      //   );
      // }
    }
  }

  onCheckBoxSelected(e: any, index) {
    // this.spinnerService.show();
    if (e.target.checked) {
      this.salaryGradeForm.controls['salaryGradeDetails']['controls'][index]['controls']['checkedTextField'].setValue(1);
    } else {
      this.salaryGradeForm.controls['salaryGradeDetails']['controls'][index]['controls']['checkedTextField'].setValue(0);
    }
  }

  onCheckBoxSelectedNew(e, i) {
    if (e.target.checked) {
      this.salaryGradeForm.controls['salaryGradeDetails']['controls'][i]['controls']['percentage'].setValue('');
      $("#percentage_" + i).css('pointer-events', 'auto');
    } else {
      $("#percentage_" + i).css('pointer-events', 'none');
      this.salaryGradeForm.controls['salaryGradeDetails']['controls'][i]['controls']['percentage'].setValue(0);
    }
  }

  numberOnly(event): boolean {
    var txtId = event.target.value;
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode != 46) {
      return false;
    }
    return true;
  }

}
