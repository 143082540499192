import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { BaseLayoutComponent } from './Layout/base-layout/base-layout.component';
import { PagesLayoutComponent } from './Layout/pages-layout/pages-layout.component';

// DEMO PAGES

// Dashboards

import { AnalyticsComponent } from './DemoPages/Dashboards/analytics/analytics.component';

// Pages

import { ForgotPasswordBoxedComponent } from './DemoPages/UserPages/forgot-password-boxed/forgot-password-boxed.component';
import { LoginBoxedComponent } from './DemoPages/UserPages/login-boxed/login-boxed.component';
import { RegisterBoxedComponent } from './DemoPages/UserPages/register-boxed/register-boxed.component';

// Elements

import { StandardComponent } from './DemoPages/Elements/Buttons/standard/standard.component';
import { DropdownsComponent } from './DemoPages/Elements/dropdowns/dropdowns.component';
import { CardsComponent } from './DemoPages/Elements/cards/cards.component';
import { ListGroupsComponent } from './DemoPages/Elements/list-groups/list-groups.component';
import { TimelineComponent } from './DemoPages/Elements/timeline/timeline.component';
import { IconsComponent } from './DemoPages/Elements/icons/icons.component';

// Components

import { AccordionsComponent } from './DemoPages/Components/accordions/accordions.component';
import { TabsComponent } from './DemoPages/Components/tabs/tabs.component';
import { CarouselComponent } from './DemoPages/Components/carousel/carousel.component';
import { ModalsComponent } from './DemoPages/Components/modals/modals.component';
import { ProgressBarComponent } from './DemoPages/Components/progress-bar/progress-bar.component';
import { PaginationComponent } from './DemoPages/Components/pagination/pagination.component';
import { TooltipsPopoversComponent } from './DemoPages/Components/tooltips-popovers/tooltips-popovers.component';

// Tables

import { TablesMainComponent } from './DemoPages/Tables/tables-main/tables-main.component';

// Widgets

import { ChartBoxes3Component } from './DemoPages/Widgets/chart-boxes3/chart-boxes3.component';

// Forms Elements

import { ControlsComponent } from './DemoPages/Forms/Elements/controls/controls.component';
import { LayoutComponent } from './DemoPages/Forms/Elements/layout/layout.component';

// Charts

import { ChartjsComponent } from './DemoPages/Charts/chartjs/chartjs.component';

//import { DashboardComponent } from './dashboard/dashboard.component';
import { LoginComponent } from './login/login/login.component';
import { AuthGuard } from './authguard.guard';
import { DashboardComponent } from './dashboard/dashboard.component';
const routes: Routes = [
  {
    path: "",
    component: LoginComponent,
  },
  {
    path: '',
    component: BaseLayoutComponent,

    children: [{
      path: "masters",
      canActivate: [AuthGuard],
      loadChildren: () =>
        import("../../src/app/masters/masters.module").then(mod => mod.MastersModule),
      data: { extraParameter: 'masterMenu' }
    },
    {
      path: "opportinityModule",
      canActivate: [AuthGuard],
      loadChildren: () =>
        import("../../src/app/opportunity-main/opportunity-main.module").then(mod => mod.OpportunityMainModule),
      data: { extraParameter: 'opportunityMenu' }
    },
    {
      path: "proposal",
      canActivate: [AuthGuard],
      loadChildren: () =>
        import("../../src/app/proposalMain/proposal-main.module").then(mod => mod.ProposalMainModule),
      data: { extraParameter: 'masterMenu' }
    },
    {
      path: "project",
      canActivate: [AuthGuard],
      loadChildren: () =>
        import("../../src/app/projectCreationMain/project-creation-main.module").then(mod => mod.ProjectCreationMainModule),
      data: { extraParameter: 'masterMenu' }
    },
    {
      path: "timesheet",
      canActivate: [AuthGuard],
      loadChildren: () =>
        import("../../src/app/timesheet/timesheet.module").then(mod => mod.TimesheetModule),
      data: { extraParameter: 'masterMenu' }
    },
    {
      path: "paymentReceipt",
      canActivate: [AuthGuard],
      loadChildren: () =>
        import("../../src/app/payment/paymentReceiptMain.module").then(mod => mod.PaymentReceiptMainModule),
      data: { extraParameter: 'paymentMenu' }
    },

    {
      path: "invoiceMain",
      canActivate: [AuthGuard],
      loadChildren: () =>
        import("../../src/app/invoice_main/invoice-main.module").then(mod => mod.InvoiceMainModule),
        data: {extraParameter: 'InvoiceMenu'}
    },
    {
      path: "leaveapplymain",
      canActivate: [AuthGuard],
      loadChildren: () =>
        import("../../src/app/leave_apply_main/leave-apply-main.module").then(mod => mod.LeaveApplyMainModule),
        data: {extraParameter: 'LeaveApplyMenu'}
    },
    {
      path: "salaryProcessMain",
      canActivate: [AuthGuard],
      loadChildren: () =>
        import("../../src/app/salayProcessMain/salary-process-main.module").then(mod => mod.SalaryProcessMainModule),
        data: {extraParameter: 'menusalaryProcessMain'}
    },
   
    {
      path: "menuMasterMain",
      canActivate: [AuthGuard],
      loadChildren: () =>
        import("../../src/app/menu_master_main/menu-master-main.module").then(mod => mod.MenuMasterMainModule),
        data: {extraParameter: 'menuMasterMain'}
    },
   
    // Dashboads
    { path: '', component: AnalyticsComponent, data: { extraParameter: 'dashboardsMenu' } },


    // Elements

    { path: 'elements/buttons-standard', component: StandardComponent, data: { extraParameter: 'elementsMenu' } },
    { path: 'elements/dropdowns', component: DropdownsComponent, data: { extraParameter: 'elementsMenu' } },
    { path: 'elements/icons', component: IconsComponent, data: { extraParameter: 'elementsMenu' } },
    { path: 'elements/cards', component: CardsComponent, data: { extraParameter: 'elementsMenu' } },
    { path: 'elements/list-group', component: ListGroupsComponent, data: { extraParameter: 'elementsMenu' } },
    { path: 'elements/timeline', component: TimelineComponent, data: { extraParameter: 'elementsMenu' } },

    // Components


    { path: 'components/tabs', component: TabsComponent, data: { extraParameter: 'componentsMenu' } },
    { path: 'components/accordions', component: AccordionsComponent, data: { extraParameter: 'componentsMenu' } },
    { path: 'components/modals', component: ModalsComponent, data: { extraParameter: 'componentsMenu' } },
    { path: 'components/progress-bar', component: ProgressBarComponent, data: { extraParameter: 'componentsMenu' } },
    { path: 'components/tooltips-popovers', component: TooltipsPopoversComponent, data: { extraParameter: 'componentsMenu' } },
    { path: 'components/carousel', component: CarouselComponent, data: { extraParameter: 'componentsMenu' } },
    { path: 'components/pagination', component: PaginationComponent, data: { extraParameter: 'componentsMenu' } },

    // Tables

    { path: 'tables/bootstrap', component: TablesMainComponent, data: { extraParameter: 'tablesMenu' } },

    // Widgets

    { path: 'widgets/chart-boxes-3', component: ChartBoxes3Component, data: { extraParameter: 'pagesMenu3' } },

    // Forms Elements

    { path: 'forms/controls', component: ControlsComponent, data: { extraParameter: 'formElementsMenu' } },
    { path: 'forms/layouts', component: LayoutComponent, data: { extraParameter: 'formElementsMenu' } },

    // Charts

    { path: 'charts/chartjs', component: ChartjsComponent, data: { extraParameter: '' } },

    //Dashboard
    {
      path: 'dashboard',
      canActivate: [AuthGuard],
      component: DashboardComponent,
      data: { extraParameter: '' }
    },

    ]

  },

  {
    path: '',
    component: PagesLayoutComponent,
    children: [

      // User Pages
      { path: 'pages/login-boxed', component: LoginBoxedComponent, data: { extraParameter: '' } },
      { path: 'pages/register-boxed', component: RegisterBoxedComponent, data: { extraParameter: '' } },
      { path: 'pages/forgot-password-boxed', component: ForgotPasswordBoxedComponent, data: { extraParameter: '' } },
    ]
  },
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes,
    {
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
    })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
