import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MarkAsteriskDirectiveModule} from '../../directives/mark-asterisk.directive';
import { RouterModule, Routes } from "@angular/router";
import { DesignationlistComponent } from './list/designationlist/designationlist.component';
import { DesignationcreateComponent } from './create/designationcreate/designationcreate.component';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DataTablesModule } from 'angular-datatables';



const routes: Routes = [
  
  {
    path: "designationlist",
    component: DesignationlistComponent
  },
  {
    path: "designationcreate",
    component: DesignationcreateComponent
  }
];

@NgModule({
  declarations: [DesignationlistComponent,DesignationcreateComponent],
  imports: [
    RouterModule.forChild(routes),
    CommonModule,
    ReactiveFormsModule,
    DataTablesModule,
    MarkAsteriskDirectiveModule

    
  ],
  
  entryComponents: [
    DesignationcreateComponent  ]
})
export class DesignationModule { }