import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from "@angular/router";
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DataTablesModule } from 'angular-datatables';
import { ResourcemovementcreateComponent } from './create/resourcemovementcreate/resourcemovementcreate.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {MarkAsteriskDirectiveModule} from '../../directives/mark-asterisk.directive';
import { DragDropModule } from '@angular/cdk/drag-drop';
import {DayPilotModule} from "daypilot-pro-angular";
import { ResourcemovementlistComponent } from './list/resourcemovementlist/resourcemovementlist.component'
import { DragScrollModule } from "cdk-drag-scroll";
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown-angular7';

const routes: Routes = [
  {
    path: 'ResourcemovementcreateComponent',
    component: ResourcemovementcreateComponent
  },
  {
    path: "ResourcemovementcreateComponent/:id",
    component: ResourcemovementcreateComponent
  },
  {
    path: 'ResourcemovementlistComponent',
    component: ResourcemovementlistComponent
  },
];

@NgModule({
  declarations: [ResourcemovementcreateComponent, ResourcemovementlistComponent],
  imports: [
    RouterModule.forChild(routes),
    NgMultiSelectDropDownModule.forRoot(),
    CommonModule,
    ReactiveFormsModule,
    DataTablesModule,
    FormsModule,
    NgbModule,
    MarkAsteriskDirectiveModule,
    DragDropModule,
    DayPilotModule,
    DragScrollModule
  ],

  exports: [],
  bootstrap: [],
})
export class ResourcemovementModule { }
