import { Component, OnInit, Input } from '@angular/core';
import { FormArray, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { MastersService } from 'src/app/services/masters.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { projectScheduleService } from 'src/app/services/projectSchedule.service';


@Component({
  selector: 'app-resourcemovementlist',
  templateUrl: './resourcemovementlist.component.html',
  styleUrls: ['./resourcemovementlist.component.sass']
})
export class ResourcemovementlistComponent implements OnInit {
  @Input() id;
  @Input() action;
  @Input() ownerId;
  @Input() oldProjectId;
  @Input() projectId;


  poupuTitle: string;
  submitted: boolean;
  resourceMovementForm: FormGroup;
  allowanceApplicable: Boolean;
  userMasterId: any;
  route: any;
  TaskDetailsbyId: string;
  totalEstimatedEfforts: any;
  isDisabled: boolean;
  constructor(
    // private modalService: NgbModal,
    public projectScheduleService: projectScheduleService,
    private _formBuilder: FormBuilder,
    private toastr: ToastrService,
    private router: Router,
    public MasterService: MastersService,
    public activeModal: NgbActiveModal,
    private spinnerService: Ng4LoadingSpinnerService
  ) { }

  ngOnInit() {
    this.totalEstimatedEfforts = 0;


    this.resourceMovementForm = this.createDecreateResourceMovementForm();
    // if(this.ownerId==undefined){
    //   return;
    // }
    this.projectScheduleService.getResourceMovementItem("", this.oldProjectId, this.projectId, this.ownerId).subscribe((response: any) => {
      //console.log(response.data);

      this.resourceMovementForm.controls['projectId'].setValue(response.data['projectId']);
      this.resourceMovementForm.controls['oldProjectId'].setValue(response.data['oldProjectId']);
      this.resourceMovementForm.controls['action'].setValue(response.data['action']);
      this.resourceMovementForm.controls['oldEmpId'].setValue(response.data.employeeData['ownerId']);
      this.resourceMovementForm.controls['projectName'].setValue(response.data.projectmicroschedulingmainData['projectName']);
      this.resourceMovementForm.controls['projectCost'].setValue(response.data.projectmicroschedulingmainData['totalProjectCost']);
      this.resourceMovementForm.controls['receivedCost'].setValue(response.data.projectmicroschedulingmainData['paidReceiptAmount']);
      this.resourceMovementForm.controls['utilizeCost'].setValue(response.data.projectmicroschedulingmainData['expenses']);
      this.resourceMovementForm.controls['projectCostEstimation'].setValue(0);
      this.resourceMovementForm.controls['projectType'].setValue(response.data.projectmicroschedulingmainData['projectType']);
      this.resourceMovementForm.controls['projectStartDate'].setValue(response.data.projectmicroschedulingmainData['projectStartDate']);
      this.resourceMovementForm.controls['projectEndDate'].setValue(response.data.projectmicroschedulingmainData['projectEndDate']);

      this.resourceMovementForm.controls['employeeId'].setValue(response.data.employeeData['ownerId']);
      this.resourceMovementForm.controls['employeeName'].setValue(response.data.employeeData['employeeName']);
      this.resourceMovementForm.controls['rateInHrs'].setValue(response.data.employeeData['rateInHrs']);
      this.resourceMovementForm.controls['estimatedHrs'].setValue(0);    

      //response.data.employeeData['ownerId']
      response.data.projectmicroschedulingData;
      response.data.projectmicroschedulingData.forEach((x, index) => {
        const documentArray = this.resourceMovementForm.controls.taskListDetails as FormArray;
        documentArray.push(
          this._formBuilder.group({
            projectmicroschedulingId: x.projectmicroschedulingId,
            estimatedEfforts: x.estimatedEfforts,
            totalSpentHrs: x.totalSpentHrs,

            ptmTaskDescription: x.taskDescription + "(" + x.estimatedEfforts + "-" + x.totalSpentHrs + ")",
            EmpName: x.EmpName,
            //totalSpentHrs : x.totalSpentHrs,
            employeeEstimatedCost: x.employeeEstimatedCost,
            status: x.status,
            action: x.action,
            checkedTextField: x.checkedTextField,
            oldEmpId: x.employeeId,            
          })          
        );
        this.totalEstimatedEfforts = parseFloat(this.totalEstimatedEfforts) + parseFloat(x.employeeEstimatedCost);
      });   
      this.resourceMovementForm.controls['totalProjectCost'].setValue(this.totalEstimatedEfforts);
    });
  }

  ngAfterViewInit(): void {
    
    if (this.projectId == 'delete') {
      setTimeout(() => {
        $('.pointerEventCls_Completed').css('pointer-events', 'none');
        let taskListDetailsArray = ((this.resourceMovementForm.get('taskListDetails') as FormArray).length);
        for (let i = 0; i < taskListDetailsArray; i++) {
          let oldEmpId = this.resourceMovementForm.controls['taskListDetails']['controls'][i]['controls']['oldEmpId'].value;
          console.log('oldEmpId->'+oldEmpId);
          if (oldEmpId != this.ownerId) {
            $('.pointerEventCls_' + oldEmpId).css('pointer-events', 'none');
          }
        }
      }, 500);
    } else {
      setTimeout(() => {
        $('.pointerEventCls_Completed').css('pointer-events', 'none');
        $('.pointerEventCls_' + this.ownerId).css('pointer-events', 'none');
      }, 500);
    }
  };

  createDecreateResourceMovementForm(): FormGroup {
    return (this.resourceMovementForm = this._formBuilder.group({
      action: ["", Validators.required],
      projectId: ["", Validators.required],
      oldProjectId: ["", Validators.required],
      oldEmpId: [""],
      projectName: ["", Validators.required],
      projectCost: ["", Validators.required],
      receivedCost: ["", Validators.required],
      utilizeCost: ["", Validators.required],
      projectCostEstimation: ["", Validators.required],
      totalProjectCost: ["", Validators.required],
      projectType: ["", Validators.required],
      projectStartDate: ["", Validators.required],
      projectEndDate: ["", Validators.required],

      employeeId: ["", Validators.required],
      employeeName: ["", Validators.required],
      // employeeEstimatedCost: ["", Validators.required],
      rateInHrs: ["", Validators.required],
      estimatedHrs: ["", Validators.required],
      // resources: ["", Validators.required],
      taskListDetails: this._formBuilder.array([]),
    }));
  }
  get f() {
    return this.resourceMovementForm.controls;
  }
  onCancel() {
    this.activeModal.close();
    this.router.navigateByUrl("/app/refresh", { skipLocationChange: true }).then(() => {
      this.router.navigate(["/project/resourcemovement/ResourcemovementcreateComponent"]);
    });
  }

  getProposalServiceDetails() {
    return (this.resourceMovementForm.get("taskListDetails") as FormArray).controls;
  }

  selectedEmployeeEstimatedHrs = 0;
  totalcostEstimated: any;
  onCheckBoxSelected(e: any, index) {
    if (e.target.checked) {
      this.resourceMovementForm.controls['taskListDetails']['controls'][index]['controls']['checkedTextField'].setValue(1);
    } else {
      this.resourceMovementForm.controls['taskListDetails']['controls'][index]['controls']['checkedTextField'].setValue(0);
      this.resourceMovementForm.controls['estimatedHrs'].setValue(0);
    }

    let taskListDetailsArray = ((this.resourceMovementForm.get('taskListDetails') as FormArray).length);
    let totalEstimatedEfforts = 0;
    this.totalcostEstimated = 0;
    for (let i = 0; i < taskListDetailsArray; i++) {
      let EstimatedEfforts = this.resourceMovementForm.controls['taskListDetails']['controls'][i]['controls']['estimatedEfforts'].value;
      let checkedTextField = this.resourceMovementForm.controls['taskListDetails']['controls'][i]['controls']['checkedTextField'].value;

      if (checkedTextField == 1) {
        totalEstimatedEfforts = totalEstimatedEfforts + parseFloat(EstimatedEfforts);
        this.resourceMovementForm.controls['estimatedHrs'].setValue(totalEstimatedEfforts);
      } else {
        let employeeEstimatedCost = this.resourceMovementForm.controls['taskListDetails']['controls'][i]['controls']['employeeEstimatedCost'].value;
        this.totalcostEstimated = parseFloat(this.totalcostEstimated) + parseFloat(employeeEstimatedCost);
      }
    }

    let RateInHrs = this.resourceMovementForm.controls['rateInHrs'].value;
    let EmployeeEstimatedEfforts = totalEstimatedEfforts * RateInHrs;
    this.resourceMovementForm.controls['projectCostEstimation'].setValue(EmployeeEstimatedEfforts);

    this.totalcostEstimated = EmployeeEstimatedEfforts + this.totalcostEstimated;
    this.resourceMovementForm.controls['totalProjectCost'].setValue(this.totalcostEstimated);
  }

  onSubmit(regfrm: any) {
    this.submitted = true;
    const checked = $("input[type=checkbox]:checked").length;

    if (!checked) {
      this.toastr.error("Error !! You must check at least one checkbox.");
      return false;
    }
    // if (this.resourceMovementForm.invalid) {
    //   this.toastr.error("Error !! Record not created");
    //   return;
    // }
    this.projectScheduleService.createResourceMovement(this.resourceMovementForm.value).subscribe((response: any) => {
      //console.log("response", response.msg);
      if (response.code === 1) {
        this.toastr.success("Success !! " + response.msg);
        this.activeModal.close();
        this.router.navigateByUrl("/app/refresh", { skipLocationChange: true }).then(() => {
          this.router.navigate(["/project/resourcemovement/ResourcemovementcreateComponent"]);
        });

        return true;
      } else if (response.code == 0) {
        this.toastr.error(response.msg, "Error!");
        return false;
      } else {
        this.toastr.error("Error !! Record not created");
        return false;
      }
    },
      error => {
        console.log("failure", error);
        return false;
      }
    );
  }
}
