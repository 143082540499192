import { Component, OnInit } from '@angular/core';
import { Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MastersService } from 'src/app/services/masters.service';
import { environment } from "../../../../environments/environment";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-emp-view',
  templateUrl: './emp-view.component.html',
  styleUrls: ['./emp-view.component.sass']
})
export class EmpViewComponent implements OnInit {
  poupuTitle: string;
  empDetails:any;
  @Input() id;
  employeeBankDetailsData = [];
  employeeDocumnetData = [];
  envUrl:any;
  url:any;
  shiftStartTime: Date=new Date();
  constructor( private router: Router,
    public MasterService: MastersService,
    public route: ActivatedRoute,
    private modalService: NgbModal
    ) { }


  ngOnInit() {
    this.envUrl = environment.img_url;
    this.poupuTitle = "Employee Details";
    this.id = this.route.snapshot.paramMap.get("id");
    this.MasterService.getEmpbyId(this.id).then(data => {
      this.empDetails = data.data;
      this.employeeBankDetailsData = data.employeeBankDetailsData;
      this.employeeDocumnetData = data.employeeDocumentData;
    });
  }

  getWeekOff(days){
    if(days == 1){
      return "Monday";
    }else if(days == 2){
      return "Tuesday";
    }else if(days == 3){
      return "Wednesday";
    }else if(days == 4){
      return "Thursday";
    }else if(days == 5){
      return "Friday";
    }else if(days == 6){
      return "Saturday";
    }else if(days == 7){
      return "Sunday";
    }
    return "NA";
  }

  getImage(path){
    alert(path)
    return "<img [src]='"+path+"' (click)='openLarge(imageInModal)'  class='previewImage'>";
  }

  openLarge(content,src) {
    this.url = src;
    if(this.url){
      this.modalService.open(content, {
        size: 'lg'
      });
    }
  }

onCancel(){
  this.router.navigateByUrl("/app/refresh", { skipLocationChange: true }).then(() => {
    this.router.navigate(["/masters/empmaster/empmasterlist"]);
  });
}
}
