import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { MastersService } from 'src/app/services/masters.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';

@Component({
  selector: 'app-salarygrademastercreate',
  templateUrl: './salarygrademastercreate.component.html',
  styleUrls: ['./salarygrademastercreate.component.sass']
})
export class SalarygrademastercreateComponent implements OnInit {
  @Input() id;
  @Input() action;
  poupuTitle: string;
  submitted: boolean;
  salaryGradeMasterForm: FormGroup;
  userMasterId: string;
  salaryGradeId: any;
  tableName: string;
  salaryGradeList: any;
  salaryCompList: any;
  constructor(
    private modalService: NgbModal,
    private _formBuilder: FormBuilder,
    private toastr: ToastrService,
    private router: Router,
    public MasterService: MastersService,
    public activeModal: NgbActiveModal,
    private spinnerService: Ng4LoadingSpinnerService
  ) { }

  ngOnInit() {
     this.userMasterId = localStorage.getItem("userMasterId");
     this.action = this.action;
     // const token_key = localStorage.getItem("token");
      if (this.action === "Edit") {
        this.poupuTitle = "Edit Salary Grade";
        this.MasterService.GetSalaryGradeMasterById(this.id).then(data => {
          console.log("datapatch", data.data);
          this.salaryGradeMasterForm.patchValue(data.data);


        //this.salaryGradeMasterForm.patchValue(data.data[0]);
        this.tableName = "salary_grade";
        this.MasterService.getActiveDetailsListForUpdate(data.data[0].salaryGradeId, this.tableName).subscribe((e: any) => {
          console.log(e.data);
          this.salaryGradeList = e.data;
        });

        //this.salaryGradeMasterForm.patchValue(data.data[0]);
        this.tableName = "salary_component";
        this.MasterService.getActiveDetailsListForUpdate(data.data[0].salaryCompId, this.tableName).subscribe((e: any) => {
          console.log(e.data);
          this.salaryCompList = e.data;
        });
        });


      } else if (this.action === "Add") {
        this.poupuTitle = "Add Salary Grade";
        this.tableName = "salary_grade";
        this.MasterService.getActiveDetailsListForCreate(this.tableName).subscribe((e: any) => {
          this.salaryGradeList = e.data;
        });

        this.tableName = "salary_component";
        this.MasterService.getActiveDetailsListForCreate(this.tableName).subscribe((e: any) => {
          this.salaryCompList = e.data;
        });
      }
      this.salaryGradeMasterForm = this.createDecreateSalaryGradeMasterForm();
    }
    createDecreateSalaryGradeMasterForm(): FormGroup {
      return (this.salaryGradeMasterForm = this._formBuilder.group({
        salaryGradeId: ["", Validators.required],
        salaryCompId: ["", Validators.required],
        salaryCompPercentage: ["", Validators.required],
      }));
  }
  get f() {
    return this.salaryGradeMasterForm.controls;
  }
  onSubmit() {
    this.spinnerService.show();
    this.submitted = true;
    if (this.salaryGradeMasterForm.invalid) {
      this.toastr.error("Error !! Record not created");
      return;
    }
    this.MasterService.createSalaryGradeMaster(this.salaryGradeMasterForm.value, this.userMasterId).subscribe((response: any) => {

      if (response.code === 1) {
        this.activeModal.close();
        this.toastr.success("Salary grade added successfully");
        this.router.navigateByUrl("/app/refresh", { skipLocationChange: true }).then(() => {
         this.router.navigate(["/masters/salarygrademaster/salarygrademasterlist"]);
        });
        return true;
      } else if (response.code == 0) {
        this.toastr.error(response.msg, "Error!");
        return false;
      } else {
         this.toastr.error("Error !! Record not created");
         return false;
      }
   },
     error => {
       console.log("failure", error);
       return false;
     }
    );
   }
  salaryGradeMasterId(value: any, salaryGradeMasterId: any) {
    throw new Error("Method not implemented.");
  }
  numberOnly(event): boolean {
    var txtId = event.target.value;
    const charCode = (event.which) ? event.which : event.keyCode;
    console.log(charCode);
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  UpdateSalaryGradeMaster() {
    this.spinnerService.show();
    this.submitted = true;
    //this.options.value.token = localStorage.getItem("token");
    // this.options.value.updated_by_id = localStorage.getItem("created_by_id");
    this.salaryGradeMasterForm.value.id = this.id;

    this.MasterService.UpdateSalaryGradeMaster(this.id, this.salaryGradeMasterForm.value, this.userMasterId).subscribe((response: any) => {
      if (response.code == 1) {
        this.activeModal.close();
        this.toastr.success("Salary grade updated successfully");

        this.router
          .navigateByUrl("/app/refresh", { skipLocationChange: true })
          .then(() => {
            this.router.navigate(["/masters/salarygrademaster/salarygrademasterlist"]);
          });
        return true;
      } else if (response.code == 0) {
        this.toastr.error(response.msg, "Error!");
        return false;
      } else {
        this.toastr.error("Record not created!", "Error!");
        return false;
      }
    },
    error => {
      this.toastr.warning("Internal Server Error !!", "Error!");
      return false;
    }
  );

  }
}
