// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  // url: "http://localhost/Ingrain_backend/index.php?r=",
  // url: "http://ingrainadmin.disctesting.in/index.php?r=",
  url: "https://ingrainadmin.disclive.in/index.php?r=",
  img_url: "http://localhost/Ingrain_backend/",
  // img_url: "http://localhost:8080/VinodraiAPI_Testing_Code/",
  production: false,
  hmr: false
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
