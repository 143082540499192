import { Component, OnInit } from '@angular/core';
import { Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from "ngx-toastr";
import { MastersService } from 'src/app/services/masters.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';

@Component({
  selector: 'app-company-bank-details-create',
  templateUrl: './company-bank-details-create.component.html',
  styleUrls: ['./company-bank-details-create.component.sass']
})
export class CompanyBankDetailsCreateComponent implements OnInit {

  @Input() id;
  @Input() action;
  Company_Bank_DetailsForm: FormGroup;
  poupuTitle: string;
  submitted: boolean;
  companyList:[];
  loginId:any;

  constructor(private _formBuilder: FormBuilder,
    private toastr: ToastrService,
    private router: Router,
    public MasterService: MastersService,
    public activeModal: NgbActiveModal,private spinnerService: Ng4LoadingSpinnerService) { }

  ngOnInit() {
    console.log(this.id);
    this.action = this.action;

    const token_key = localStorage.getItem("token");
    this.loginId = localStorage.getItem("userMasterId");

    this.MasterService.getCompanyMasterList().subscribe((e: any) => {
      console.log(e.data);
      this.companyList = e.data;
      // console.log(this.deptlist);
    });

    if (this.action === "Edit") {

      this.poupuTitle = "Edit Company Bank Details";
      this.MasterService.getCompanyBankMasterbyId(this.id).then(data => {
        console.log("datapatch", data.data);
        this.Company_Bank_DetailsForm.patchValue(data.data);
      });

    } else if (this.action === "Add") {

      this.poupuTitle = "Add Company Bank Details";
    }
    this.Company_Bank_DetailsForm = this.createCompany_Bank_DetailsForm();
  }

  createCompany_Bank_DetailsForm(): FormGroup {
    return (this.Company_Bank_DetailsForm = this._formBuilder.group({
      companyId: ["", Validators.required],
      bankName: ["", Validators.required],
      accountNo: ["", Validators.required],
      branchName: ["", Validators.required],
      IFSCCode: ["", Validators.required],
      CIFNo: ["", Validators.required],
      bankAddress: ["", Validators.required],
      accountType: ["", Validators.required],
      // comapnyname
    }));
  }

  get f() {
    return this.Company_Bank_DetailsForm.controls;
  }

  onSubmit(regfrm: any) {
    this.spinnerService.show();
    this.submitted = true;
    if (this.Company_Bank_DetailsForm.invalid) {
      this.toastr.error("Error !! Record not created");
      return;
    }
    this.MasterService.createCompanyBankMaster(this.Company_Bank_DetailsForm.value).subscribe((response: any) => {
      console.log("response", response.msg);
      this.activeModal.close();
      if (response.code === 1) {
        this.activeModal.close();
        this.toastr.success("Success !! " + response.msg);

        this.router.navigateByUrl("/app/refresh", { skipLocationChange: true }).then(() => {
          this.router.navigate(["/masters/companyBankDetail/company_bank_List"]);
        });
        return true;
      } else if (response.code == 0) {
        this.toastr.error(response.msg, "Error!");
        return false;
      } else {
        this.toastr.error("Error !! Record not created");
        return false;
      }
    },
      error => {
        console.log("failure", error);
        return false;
      }
    );


  }

  UpdateCompanyBankMaster() {
    this.spinnerService.show();
    this.submitted = true;
    this.Company_Bank_DetailsForm.value.id = this.id;

    if (this.Company_Bank_DetailsForm.invalid) {
      this.toastr.error("Error !! Record not updated");
      return;
    }

    this.MasterService.updateCompanyBankMaster(this.id,this.Company_Bank_DetailsForm.value).subscribe((response: any) => {

      console.log("response");
        if (response.code == 1) {
          // alert("update")
          this.activeModal.close();
          this.toastr.success("Success !", response.msg);

          this.router
            .navigateByUrl("/app/refresh", { skipLocationChange: true })
            .then(() => {
              this.router.navigate(["/masters/companyBankDetail/company_bank_List"]);
            });
          return true;
        } else if (response.code == 0) {
          this.toastr.error(response.msg, "Error!");
          return false;
        } else {
          this.toastr.error("Record not created!", "Error!");
          return false;
        }
      },
      error => {
        this.toastr.warning("Internal Server Error !!", "Error!");
        return false;
      }
    );
  }

  numberOnly(event): boolean {
    var txtId = event.target.value;
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode != 46) {
      return false;
    }
    return true;
  }

  alphabetOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if ((charCode > 64 && charCode < 91) || (charCode > 96 && charCode < 123) || charCode == 8 || (charCode == 32)){
      return true;
    }else{
      return false;
    }
  }


}
