import { Component, OnInit } from '@angular/core';
import { Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from "ngx-toastr";
import { MastersService } from 'src/app/services/masters.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';

@Component({
  selector: 'app-project-mapping-create',
  templateUrl: './project-mapping-create.component.html',
  styleUrls: ['./project-mapping-create.component.sass']
})
export class ProjectMappingCreateComponent implements OnInit {
  @Input() id;
  @Input() action;
  projectMappingForm: FormGroup;
  poupuTitle: string;
  tableName: string;
  submitted: boolean;
  projectTypeList = [];
  taskList = [];

  constructor(
    private _formBuilder: FormBuilder,
    private toastr: ToastrService,
    private router: Router,
    public MasterService: MastersService,
    public activeModal: NgbActiveModal,private spinnerService: Ng4LoadingSpinnerService
  ) { }

  ngOnInit() {
    this.action = this.action;
    const token_key = localStorage.getItem("token");
    if (this.action === "Edit") {

      this.poupuTitle = "Edit Project Mapping";
      this.MasterService.GetProjectMappingDetailsById(this.id).then(data => {

        this.projectMappingForm.patchValue(data.data);

        this.tableName = "project_type";
        this.MasterService.getActiveDetailsListForUpdate(data.data.projectTypeId, this.tableName).subscribe((e: any) => {
          this.projectTypeList = e.data;
        });
        this.tableName = "project_task_master";
        this.MasterService.getActiveDetailsListForUpdate(data.data.taskId, this.tableName).subscribe((e: any) => {
          this.taskList = e.data;
        });
      });

    } else if (this.action === "Add") {
      this.tableName = "project_task_master";
      this.MasterService.getActiveDetailsListForCreate(this.tableName).subscribe((e: any) => {
        this.taskList = e.data;
      });

      this.MasterService.getActiveProjectTypeList().subscribe((e: any) => {
        this.projectTypeList = e.data;
      });
      this.poupuTitle = "Add Project Mapping";
    }


    // this.MasterService.getTaskList().subscribe((e: any) => {
    //   this.taskList = e.data;
    // });

    this.projectMappingForm = this.createProjectMappingForm();
  }
  createProjectMappingForm(): FormGroup {
    return (this.projectMappingForm = this._formBuilder.group({
      taskId: ["", Validators.required],
      projectTypeId: ["", Validators.required],
    }));
  }

  get f() {
    return this.projectMappingForm.controls;
  }

  onSubmit(regfrm: any) {
    this.spinnerService.show();
    this.submitted = true;
    if (this.projectMappingForm.invalid) {
      this.toastr.error("Error !! Record not created");
      return;
    }
    this.MasterService.createProjectMapping(this.projectMappingForm.value).subscribe((response: any) => {

      if (response.code === 1) {
        this.activeModal.close();
        this.toastr.success("Success !! " + response.msg);
        this.router.navigateByUrl("/app/refresh", { skipLocationChange: true }).then(() => {
          this.router.navigate(["/masters/projectMapping/projectMappingList"]);
        });
        return true;
      } else if (response.code == 0) {
        this.toastr.error(response.msg, "Error!");
        return false;
      } else {
        this.toastr.error("Error !! Record not created");
        return false;
      }
    },
      error => {
        console.log("failure", error);
        return false;
      }
    );
  }
  UpdateProjectMapping() {
    this.spinnerService.show();
    this.submitted = true;
    //this.options.value.token = localStorage.getItem("token");
    // this.options.value.updated_by_id = localStorage.getItem("created_by_id");
    this.projectMappingForm.value.id = this.id;
    if (this.projectMappingForm.invalid) {
      this.toastr.error("Error !! Record not updated");
      return;
    }
    this.MasterService.updateProjectMapping(this.id, this.projectMappingForm.value).subscribe((response: any) => {
      if (response.code == 1) {
        this.activeModal.close();
        this.toastr.success("Success !", response.msg);

        this.router
          .navigateByUrl("/app/refresh", { skipLocationChange: true })
          .then(() => {
            this.router.navigate(["/masters/projectMapping/projectMappingList"]);
          });
        return true;
      } else if (response.code == 0) {
        this.toastr.error(response.msg, "Error!");
        return false;
      } else {
        this.toastr.error("Record not created!", "Error!");
        return false;
      }
    },
      error => {
        this.toastr.warning("Internal Server Error !!", "Error!");
        return false;
      }
    );
  }
}
