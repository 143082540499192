import { Component, OnInit } from '@angular/core';
import { Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from "ngx-toastr";
import { MastersService } from 'src/app/services/masters.service';
import { removeSpaces } from './firmType.validator';

@Component({
  selector: 'app-firm-type-create',
  templateUrl: './firm-type-create.component.html',
  styleUrls: ['./firm-type-create.component.sass']
})
export class FirmTypeCreateComponent implements OnInit {

  @Input() id;
  @Input() action;
  stateList = [];
  firmTypeForm: FormGroup;
  poupuTitle: string;
  submitted: boolean;

  constructor(
    private _formBuilder: FormBuilder,
    private toastr: ToastrService,
    private router: Router,
    public MasterService: MastersService,
    public activeModal: NgbActiveModal) { }

  ngOnInit() {
    console.log(this.id);
    this.action = this.action;

    const token_key = localStorage.getItem("token");

    if (this.action === "Edit") {
      //alert('Edit');
      this.poupuTitle = "Edit Firm Type Master";
      this.MasterService.GetFirmTypebyId(this.id).then(data => {
        console.log("datapatch", data.data);
        this.firmTypeForm.patchValue(data.data);
      });

    } else if (this.action === "Add") {
      //alert('Add');
      this.poupuTitle = "Add Firm Type Master";
    }
    this.firmTypeForm = this.createCountryForm();
  }

  createCountryForm(): FormGroup {
    return (this.firmTypeForm = this._formBuilder.group({
      //firmType: ["",  [Validators.required,Validators.pattern('[a-zA-Z ]*'),removeSpaces]],
      firmType: ["", [Validators.required,Validators.pattern('[a-zA-Z ]*'),removeSpaces]]
    }));
  }

  get f() {
    return this.firmTypeForm.controls;
  }

  onSubmit(regfrm: any) {
    this.submitted = true;
    if (this.firmTypeForm.invalid) {
      this.toastr.error("Error !! Record not created");
      return;
    }
    this.MasterService.createFirmType(this.f.firmType.value).subscribe((response: any) => {
      if (response.code === 1) {
        this.activeModal.close();
        this.toastr.success("Success !! " + response.msg);

        this.router.navigateByUrl("/app/refresh", { skipLocationChange: true }).then(() => {
          this.router.navigate(["/masters/firmtype/firmtypelist"]);
        });
        return true;
      } else if (response.code == 0) {
        this.toastr.error(response.msg, "Error!");
        return false;
      } else {
        this.toastr.error("Error !! Record not created");
        return false;
      }
    },
      error => {
        console.log("failure", error);
        return false;
      }
    );

    // this.regfrm.reset();
  }

  UpdateProjectType() {
    this.submitted = true;
    //this.options.value.token = localStorage.getItem("token");
    // this.options.value.updated_by_id = localStorage.getItem("created_by_id");
    this.firmTypeForm.value.id = this.id;
    //alert('update funtion');
    this.MasterService.updateFirmType(this.id, this.f.firmType.value).subscribe((response: any) => {
        if (response.code == 1) {
          //alert("update")
          this.activeModal.close();
          this.toastr.success("Success !", response.msg);

          this.router
            .navigateByUrl("/app/refresh", { skipLocationChange: true })
            .then(() => {
              this.router.navigate(["/masters/firmtype/firmtypelist"]);
            });
          return true;
        } else if (response.code == 0) {
          this.toastr.error(response.msg, "Error!");
          return false;
        } else {
          this.toastr.error("Record not created!", "Error!");
          return false;
        }
      },
      error => {
        this.toastr.warning("Internal Server Error !!", "Error!");
        return false;
      }
    );
  }

  alphabetOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if ((charCode > 64 && charCode < 91) || (charCode > 96 && charCode < 123) || charCode == 8 || (charCode == 32)){
     // this.onlyAlphabet =false;
     
      return true;
    }else{
      //this.onlyAlphabet =true;
      //alert("only alphabates are allowed")
      return false;
    }
  }

}
