import { Component, OnInit, ViewChild } from '@angular/core';
import { Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbDateStruct, NgbCalendar, NgbDatepicker } from '@ng-bootstrap/ng-bootstrap';

import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from "ngx-toastr";
import { MastersService } from 'src/app/services/masters.service';
import { ProposalModuleService } from 'src/app/services/proposal.service';

@Component({
  selector: 'app-view-revision',
  templateUrl: './view-revision.component.html',
  styleUrls: ['./view-revision.component.sass']
})
export class ViewRevisionComponent implements OnInit {
  @Input() id;
  @Input() action;
  poupuTitle: string;

  ProposalData = [];
  scopeOfWorkDetailsData = [];
  projectScheduleDetailsData = [];
  followupDetailsData = [];
  paymentStageDetailsData = [];
  serviceDetailsData = [];
  contactDetailsData = [];
  proposalRevisionDetailsData = [];

  constructor(
    public ProposalModuleService: ProposalModuleService,
    public activeModal: NgbActiveModal,
    private router: Router,
    ) { }

  ngOnInit() {
    this.action = this.action;
    this.poupuTitle = "View Proposal Revision";

    if (this.action === "View") {
      this.ProposalModuleService.viewProposalbyId(this.id).then(data => {

        this.ProposalData = data.data;
        this.scopeOfWorkDetailsData = data.data.proposalScopeDefine;
        this.projectScheduleDetailsData = data.data.proposalProjectSchedule;
        this.followupDetailsData = data.data.followup;
        this.paymentStageDetailsData = data.data.proposalPaymentSchedule;
        this.serviceDetailsData = data.data.serviceResult;
        this.contactDetailsData = data.data.contactDetails;
        this.proposalRevisionDetailsData = data.data.revisionResult;
      });
    }
  }

}
