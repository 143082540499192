import { Component, OnInit } from '@angular/core';
import { Input } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from "ngx-toastr";
import { MastersService } from 'src/app/services/masters.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';

@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.sass']
})
export class CreateComponent implements OnInit {
  @Input() id;
  @Input() action;
  stateList = [];
  cityList = [];
  areaList = [];
  areafrm: FormGroup;
  poupuTitle: string;
  submitted: boolean;
  tableName: string;

  constructor(
    private _formBuilder: FormBuilder,
    private toastr: ToastrService,
    private router: Router,
    public MasterService: MastersService,
    public activeModal: NgbActiveModal,
    private spinnerService: Ng4LoadingSpinnerService
    ) { }

  ngOnInit() {
    console.log(this.id);
    this.action = this.action;

    const token_key = localStorage.getItem("token");


    if (this.action === "Edit") {
      //alert('Edit');

      this.poupuTitle = "Edit Company";
      this.MasterService.getCompanyDetails(this.id).then(data => {
        // this.changeState(data.data[0]['stateId']);
        // this.changeCity(data.data[0]['cityId']);
        this.areafrm.patchValue(data.data[0]);

        this.tableName = "state_master";
        this.MasterService.getActiveDetailsListForUpdate(data.data[0].stateId, this.tableName).subscribe((e: any) => {
          this.stateList = e.data;
        });

        this.tableName = "city_master";
        this.MasterService.getActiveDetailsListForUpdate(data.data[0].cityId, this.tableName).subscribe((e: any) => {
          this.cityList = e.data;
        });

        this.tableName = "area_master";
        this.MasterService.getActiveDetailsListForUpdate(data.data[0].areaId, this.tableName).subscribe((e: any) => {
          this.areaList = e.data;
        });
      });

    } else if (this.action === "Add") {
      this.poupuTitle = "Add Company";
      this.tableName = "state_master";
      this.MasterService.getActiveDetailsListForCreate(this.tableName).subscribe((e: any) => {
        console.log(e.data);
        this.stateList = e.data;
      });

    }
    this.areafrm = this.createCountryForm();
  }
  createCountryForm(): FormGroup {
    return (this.areafrm = this._formBuilder.group({
      companyName: ["", Validators.required],
      companyCode: ["", Validators.required],
      officeAddress: ["", Validators.required],
      stateId: ["", Validators.required],
      cityId: ["", Validators.required],
      areaId: ["", Validators.required],
      //emailId: ["", Validators.required],
      emailId: new FormControl("", [
        Validators.required,
        Validators.email
      ]),
      // panNumber: ["", Validators.required],
      // gstinNumber: ["", Validators.required],
      panNumber: ["", [Validators.pattern("[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}")]],
      gstinNumber: ["", [Validators.pattern("^([0][1-9]|[1-2][0-9]|[3][0-7])([a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9a-zA-Z]{1}[zZ]{1}[0-9a-zA-Z]{1})+$")]],

    }));
  }

  get f() {
    return this.areafrm.controls;
  }

  onSubmit(regfrm: any) {
    this.submitted = true;
    if (this.areafrm.invalid) {
      this.toastr.error("Error !! Record not created");
      return;
    }
    this.MasterService.createCompany(
      this.f.companyName.value,
      this.f.companyCode.value,
      this.f.officeAddress.value,
      this.f.stateId.value,
      this.f.cityId.value,
      this.f.areaId.value,
      this.f.emailId.value,
      this.f.panNumber.value,
      this.f.gstinNumber.value).subscribe((response: any) => {
        console.log("response", response.msg);

        if (response.code === 1) {
          this.activeModal.close();
          this.toastr.success("Success !! " + response.msg);

          this.router.navigateByUrl("/app/refresh", { skipLocationChange: true }).then(() => {
            this.router.navigate(["/masters/company/companylist"]);
          });
          return true;
        } else if (response.code == 0) {
          this.toastr.error(response.msg, "Error!");
          return false;
        } else {
          this.toastr.error("Error !! Record not created");
          return false;
        }
      },
        error => {
          console.log("failure", error);
          return false;
        }
      );

    // this.regfrm.reset();
  }

  changeState(stateId: any) {

    this.MasterService.getCityByStateId(stateId).subscribe((e: any) => {
      console.log(e.data);
      this.cityList = e.data;
    });
  }

  changeCity(cityId: any) {
    // alert('changeState');
    this.MasterService.getAreaByCityId(cityId).subscribe((e: any) => {
      console.log(e.data);
      this.areaList = e.data;
    });
  }

  UpdateCompany() {
    this.spinnerService.show();
    this.submitted = true;
    //this.options.value.token = localStorage.getItem("token");
    // this.options.value.updated_by_id = localStorage.getItem("created_by_id");
    this.areafrm.value.id = this.id;
    if (this.areafrm.invalid) {
      this.toastr.error("Error !! Record not created");
      return;
    }
    this.MasterService.updateCompany(this.id,
      this.f.companyName.value,
      this.f.companyCode.value,
      this.f.officeAddress.value,
      this.f.stateId.value,
      this.f.cityId.value,
      this.f.areaId.value,
      this.f.emailId.value,
      this.f.panNumber.value,
      this.f.gstinNumber.value).subscribe(
        (response: any) => {
          if (response.code == 1) {
            // alert("update")
            this.activeModal.close();
            this.toastr.success("Success !", response.msg);

            this.router
              .navigateByUrl("/app/refresh", { skipLocationChange: true })
              .then(() => {
                this.router.navigate(["/masters/company/companylist"]);
              });
            return true;
          } else if (response.code == 0) {
            this.toastr.error(response.msg, "Error!");
            return false;
          } else {
            this.toastr.error("Record not created!", "Error!");
            return false;
          }
        },
        error => {
          this.toastr.warning("Internal Server Error !!", "Error!");
          return false;
        }
      );
  }



}
