import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from "@angular/router";

import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DataTablesModule } from 'angular-datatables';

import { BrowserModule } from '@angular/platform-browser';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LeaveCreditListComponent } from './leave-credit-list/leave-credit-list.component';
import { LeaveCreditCreateComponent } from './leave-credit-create/leave-credit-create.component';
// import { MarkAsteriskDirective } from 'src/app/directives/mark-asterisk.directive';


const routes: Routes = [
  
  {
    path: "leaveCreditlist",
    component:LeaveCreditListComponent  
  },
  {
    path: "leaveCreditcreate",
    component: LeaveCreditCreateComponent
  },
  
];

@NgModule({
  declarations: [LeaveCreditListComponent,LeaveCreditCreateComponent],
  imports: [
    RouterModule.forChild(routes),
    CommonModule,
    ReactiveFormsModule,
    DataTablesModule,
    // MarkAsteriskDirective
    // FormsModule,
    // NgbModule,
  ]
})
export class LeaveCreditModule { }
