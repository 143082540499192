import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MastersService } from 'src/app/services/masters.service';
import { DataTableDirective, DataTablesModule } from 'angular-datatables';
import { Subject } from 'rxjs';
import { TermsConditionCreateComponent } from '../../terms-condition/terms-condition-create/terms-condition-create.component';
import { ToastrService } from "ngx-toastr";
import { Router } from '@angular/router';

@Component({
  selector: 'app-terms-condition-list',
  templateUrl: './terms-condition-list.component.html',
  styleUrls: ['./terms-condition-list.component.sass']
})
export class TermsConditionListComponent implements OnInit {
  dtTrigger: Subject<any> = new Subject();
  dtOptions: DataTables.Settings = {};
  termsList = [];
  datatableElement: DataTableDirective;
  constructor(private modalService: NgbModal, public MasterService: MastersService,  private toastr: ToastrService,
    private router: Router,) { }

  ngOnInit() {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      "columns": [
        { "orderable": false },
        { "orderable": true },
        { "orderable": true },
        { "orderable": true },
      ]
    };

    this.MasterService.gettermsconditions().subscribe((e: any) => {
      this.termsList = e.data;
      this.dtTrigger.next();
    });
  }

  onAdd() {
   
    const modalRef = this.modalService.open(TermsConditionCreateComponent);
    let action = "Add";
    modalRef.componentInstance.action = action;
  }

  onEdit(id) {
    // alert("test")
    const modalRef = this.modalService.open(TermsConditionCreateComponent);
    let getid = id;
    let action = "Edit";
    modalRef.componentInstance.action = action;
    modalRef.componentInstance.id = getid;
  }

  StatusChanged(activeStatus:any,id:any){
    if(activeStatus == 1){
      activeStatus = 0;
    }else{
      activeStatus = 1;
    }

    this.MasterService.updatetermstatus(id,activeStatus).subscribe((response: any) => {
      if (response.code == 1) {
        this.toastr.success("Success !", response.msg);

        this.router
          .navigateByUrl("/app/refresh", { skipLocationChange: true })
          .then(() => {
            this.router.navigate(["/masters/termsandconditions/termsandconditionslist"]);
          });
        return true;
        } else if (response.code == 0) {
          this.toastr.error(response.msg, "Error!");
          return false;
        } else {
          this.toastr.error("Record not created!", "Error!");
          return false;
        }
      },
      error => {
        this.toastr.warning("Internal Server Error !!", "Error!");
        return false;
      }
    );
  }


}
