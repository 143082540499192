import {Component} from '@angular/core';
import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'ngbd-datepicker',
  templateUrl: './create.component.html',
})
export class NgbdDatepicker {
  model: NgbDateStruct;
}
