import { Component, OnInit } from '@angular/core';
import { Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from "ngx-toastr";
import { MastersService } from 'src/app/services/masters.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';

@Component({
  selector: 'app-project-task-create',
  templateUrl: './project-task-create.component.html',
  styleUrls: ['./project-task-create.component.sass']
})
export class ProjectTaskCreateComponent implements OnInit {
  @Input() id;
  @Input() action;
  projectTaskForm: FormGroup;
  poupuTitle: string;
  submitted: boolean;
  deliveryScheduleList = [];


  constructor(
    private _formBuilder: FormBuilder,
    private toastr: ToastrService,
    private router: Router,
    public MasterService: MastersService,
    public activeModal: NgbActiveModal,private spinnerService: Ng4LoadingSpinnerService
  ) { }

  ngOnInit() {
    this.action = this.action;
    const token_key = localStorage.getItem("token");

    this.MasterService.getDeliveryScheduleList().subscribe((e: any) => {
      this.deliveryScheduleList = e.data;
    });

    if (this.action === "Edit") {
      this.poupuTitle = "Edit Project Task";
      this.MasterService.GetProjectTaskDetailsById(this.id).then(data => {
        console.log("datapatch", data.data);
        this.projectTaskForm.patchValue(data.data);
      });
    } else if (this.action === "Add") {
      this.poupuTitle = "Add Project Task";
    }
    this.projectTaskForm = this.createProjectTaskForm();
  }

  createProjectTaskForm(): FormGroup {
    return (this.projectTaskForm = this._formBuilder.group({
      taskDescription: ["", Validators.required],
      taskWeightage: ["", [Validators.pattern("^[0-9]*$")]],
      isEmailNotificationRequired : ["", Validators.required],
      deliveryScheduleId: ["", Validators.required],
      estimatedEfforts: ["", [Validators.pattern("^[0-9.]*$")]],
    }));
  }

  get f() {
    return this.projectTaskForm.controls;
  }
  onSubmit(regfrm: any) {
    this.spinnerService.show();
    this.submitted = true;
    if (this.projectTaskForm.invalid) {
      this.toastr.error("Error !! Record not created");
      return;
    }
    this.MasterService.createProjectTask(this.projectTaskForm.value).subscribe((response: any) => {
      console.log("response", response.msg);
      this.activeModal.close();
      if (response.code === 1) {
        this.activeModal.close();
        this.toastr.success("Success !! " + response.msg);

        this.router.navigateByUrl("/app/refresh", { skipLocationChange: true }).then(() => {
          this.router.navigate(["/masters/projectTask/projectTaskList"]);
        });
        return true;
      } else if (response.code == 0) {
        this.toastr.error(response.msg, "Error!");
        return false;
      } else {
        this.toastr.error("Error !! Record not created");
        return false;
      }
    },
      error => {
        console.log("failure", error);
        return false;
      }
    );
  }
  UpdateProjectTask() {
    this.spinnerService.show();
    this.submitted = true;
    //this.options.value.token = localStorage.getItem("token");
    // this.options.value.updated_by_id = localStorage.getItem("created_by_id");
    this.projectTaskForm.value.id = this.id;
    if (this.projectTaskForm.invalid) {
      this.toastr.error("Error !! Please Fill Required Fields");
      return;
    }
    this.MasterService.updateProjectTask(this.id, this.projectTaskForm.value).subscribe((response: any) => {
        if (response.code == 1) {
          this.activeModal.close();
          this.toastr.success("Success !", response.msg);

          this.router
            .navigateByUrl("/app/refresh", { skipLocationChange: true })
            .then(() => {
              this.router.navigate(["/masters/projectTask/projectTaskList"]);
            });
          return true;
        } else if (response.code == 0) {
          this.toastr.error(response.msg, "Error!");
          return false;
        } else {
          this.toastr.error("Record not created!", "Error!");
          return false;
        }
      },
      error => {
        this.toastr.warning("Internal Server Error !!", "Error!");
        return false;
      }
    );
  }

}
