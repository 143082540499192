import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from "@angular/router";
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DataTablesModule } from 'angular-datatables';
import { BrowserModule } from '@angular/platform-browser';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {MarkAsteriskDirectiveModule} from '../../directives/mark-asterisk.directive';
import { EmpListComponent } from './emp-list/emp-list.component';
import { EmpCreateComponent } from './emp-create/emp-create.component';
import { EmpViewComponent } from './emp-view/emp-view.component';
import { StatuschangeComponent } from './statuschange/statuschange.component';

const routes: Routes = [
  
  {
    path: "empmasterlist",
    component:EmpListComponent  
  },
  {
    path: "empmastercreate/:action",
    component: EmpCreateComponent
  },
  {
    path: "empmastercreate/:action/:id",
    component: EmpCreateComponent
  },
  {
    path: "empmasterview/:id",
    component: EmpViewComponent
  }, {
    path: "statuschange/:id",
    component: StatuschangeComponent
  }
];

// path: "opportunityView/:id",
//     component: OpportunityViewComponent

@NgModule({
  declarations: [EmpListComponent,EmpCreateComponent, EmpViewComponent, StatuschangeComponent],
  imports: [
    RouterModule.forChild(routes),
    CommonModule,
    ReactiveFormsModule,
    DataTablesModule,
    FormsModule,
    NgbModule,
    MarkAsteriskDirectiveModule
    
  ],
  entryComponents: [
    EmpCreateComponent,
    EmpViewComponent 
  ]
})
export class EmployeeMasterModule { } 
