import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MastersService } from 'src/app/services/masters.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { SalarygradecreateComponent } from '../../create/salarygradecreate/salarygradecreate.component';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { SalarygradeviewComponent } from '../../view/salarygradeview/salarygradeview.component';

@Component({
  selector: 'app-salarygradelist',
  templateUrl: './salarygradelist.component.html',
  styleUrls: ['./salarygradelist.component.sass']
})
export class SalarygradelistComponent implements OnInit {
  dtTrigger: Subject<any> = new Subject();
  datatableElement: any;
  dtOptions: { pagingType: string; pageLength: number; "columns": { "orderable": boolean; }[]; };
  salaryGradeList: any;

  constructor(
    private modalService: NgbModal,
    public SalaryGradeService: MastersService,
    private toastr: ToastrService,
    private router: Router,
    private spinnerService: Ng4LoadingSpinnerService
  ) { }

  ngOnInit() {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      "columns": [
        { "orderable": false },
        { "orderable": true },
        { "orderable": true },
        { "orderable": true },
        { "orderable": true },
        { "orderable": true },
      ]
    };

    this.SalaryGradeService.getSalaryGradeList().subscribe((e: any) => {
      this.salaryGradeList = e.data;
      this.dtTrigger.next();
    });
  }
  ngAfterViewInit(): void {

    this.dtTrigger.subscribe(() => {

      this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.columns().every(function (i) {
          const that = this;

          $('input', this.header()).on('click', function (e) {
            e.stopPropagation();
          });

          $('input', this.header()).on('keyup change', function () {
            if (that.search() !== this['value']) {
              if (i == 0) {
                var val = this['value'];
                that
                  .search(val ? '^' + this['value'] + '$' : val, true, false)
                  .draw();
              } else {
                that
                  .search(this['value'])
                  .draw();
              }
            }
          });
        });
      });
    });
  }
  onAdd() {
    this.spinnerService.show();
    // const modalRef = this.modalService.open(SalarygradecreateComponent);
    const modalRef = this.modalService.open(SalarygradecreateComponent, {
      size: 'lg'
    });
    let action = "Add";
    modalRef.componentInstance.action = action;
  }

  onEdit(id) {
    this.spinnerService.show();
    //const modalRef = this.modalService.open(SalarygradecreateComponent);
    const modalRef = this.modalService.open(SalarygradecreateComponent, {
      size: 'lg'
    });
    //console.log("Done");
    let getid = id;
    let action = "Edit";
    modalRef.componentInstance.action = action;
    modalRef.componentInstance.id = getid;
    // var elem = document.getElementById('')
  }
  onView(id){
    this.spinnerService.show();
    const modalRef = this.modalService.open(SalarygradeviewComponent,{
      size: 'lg'
    });
    let getid = id;
    modalRef.componentInstance.id = getid;
  }


  StatusChanged(activeStatus: any, id: any) {
    this.spinnerService.show();
    if (activeStatus == 1) {
      activeStatus = 0;
    } else {
      activeStatus = 1;
    }


    this.SalaryGradeService.UpdateSalaryGradeStatus(id, activeStatus).subscribe((response: any) => {
      if (response.code == 1) {
        this.toastr.success("Success !", response.msg);

        this.router
          .navigateByUrl("/app/refresh", { skipLocationChange: true })
          .then(() => {
            this.router.navigate(["/masters/salarygrade/salarygradelist"]);
          });
        return true;
      } else if (response.code == 0) {
        this.toastr.error(response.msg, "Error!");
        return false;
      } else {
        this.toastr.error("Record not created!", "Error!");
        return false;
      }
    },
      error => {
        this.toastr.warning("Internal Server Error !!", "Error!");
        return false;
      }
    );
  }

}
