import { Component, OnInit } from '@angular/core';
import { Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from "ngx-toastr";
import { MastersService } from 'src/app/services/masters.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';

@Component({
  selector: 'app-city-create',
  templateUrl: './city-create.component.html',
  styleUrls: ['./city-create.component.sass']
})
export class CityCreateComponent implements OnInit {
  @Input() id;
  @Input() action;
  stateList = [];
  data = [];
  tableName: string;
  cityfrm: FormGroup;
  poupuTitle: string;
  submitted: boolean;

  constructor(
    private _formBuilder: FormBuilder,
    private toastr: ToastrService,
    private router: Router,
    public MasterService: MastersService,
    public activeModal: NgbActiveModal,
    private spinnerService: Ng4LoadingSpinnerService
  ) { }

  ngOnInit() {
    console.log(this.id);
    this.action = this.action;

    const token_key = localStorage.getItem("token");

    if (this.action === "Edit") {
      this.poupuTitle = "Edit City";
      this.MasterService.getcitydetails(this.id).then(data => {
        this.cityfrm.patchValue(data.data);
        this.tableName = "state_master";
        this.MasterService.getActiveDetailsListForUpdate(data.data.stateId, this.tableName).subscribe((e: any) => {
          this.stateList = e.data;
        });
      });

    } else if (this.action === "Add") {
      this.poupuTitle = "Add City";

      this.tableName = "state_master";
      this.MasterService.getActiveDetailsListForCreate(this.tableName).subscribe((e: any) => {
        this.stateList = e.data;
      });
    }
    this.cityfrm = this.createCityform();
  }
  createCityform(): FormGroup {
    return (this.cityfrm = this._formBuilder.group({
      stateName: ["", Validators.required],
      cityName: ["", Validators.required],
    }));
  }

  get f() {
    return this.cityfrm.controls;
  }

  onSubmit(regfrm: any) {
    this.spinnerService.show();
    this.submitted = true;
    if (this.cityfrm.invalid) {
      this.toastr.error("Error !! Record not created");
      return;
    }
    this.MasterService.createcity(this.cityfrm.value).subscribe((response: any) => {
      console.log("response", response.msg);

      if (response.code === 1) {
        this.activeModal.close();
        this.toastr.success("Success !! " + response.msg);

        this.router.navigateByUrl("/app/refresh", { skipLocationChange: true }).then(() => {
          this.router.navigate(["/masters/city/citylist"]);
        });
        return true;
      } else if (response.code == 0) {
        this.toastr.error(response.msg, "Error!");
        return false;
      } else {
        this.toastr.error("Error !! Record not created");
        return false;
      }
    },
      error => {
        console.log("failure", error);
        return false;
      }
    );

    // this.regfrm.reset();
  }
  UpdateProjectType() {
    this.spinnerService.show();
    this.submitted = true;
    //this.options.value.token = localStorage.getItem("token");
    // this.options.value.updated_by_id = localStorage.getItem("created_by_id");
    if (this.cityfrm.invalid) {
      this.toastr.error("Error !! Record not created");
      return;
    }
    this.cityfrm.value.id = this.id;
    this.MasterService.updatecity(this.id, this.cityfrm.value).subscribe(
      (response: any) => {
        if (response.code == 1) {
          //alert("update")
          this.activeModal.close();
          this.toastr.success("Success !", response.msg);

          this.router
            .navigateByUrl("/app/refresh", { skipLocationChange: true })
            .then(() => {
              this.router.navigate(["/masters/city/citylist"]);
            });
          return true;
        } else if (response.code == 0) {
          this.toastr.error(response.msg, "Error!");
          return false;
        } else {
          this.toastr.error("Record not created!", "Error!");
          return false;
        }
      },
      error => {
        this.toastr.warning("Internal Server Error !!", "Error!");
        return false;
      }
    );
  }

}
