import {Component, HostListener, OnInit} from '@angular/core';
import {ThemeOptions} from '../../../theme-options';
import {select} from '@angular-redux/store';
import {Observable} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {AppRoutingModule} from '../../../app-routing.module'
import { MenuMasterMainServService } from 'src/app/services/menu-master-main-serv.service';


@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
})
export class SidebarComponent implements OnInit {
  public extraParameter: any;
  userMasterId: any;
  loginName: any;
  menuArray = [];
  childMenu = [];
  constructor(
    public globals: ThemeOptions,
    private activatedRoute: ActivatedRoute,
    public MenuMasterMainServService: MenuMasterMainServService,
  ) {

  }

  @select('config') public config$: Observable<any>;

  private newInnerWidth: number;
  private innerWidth: number;
  activeId = 'dashboardsMenu';

  toggleSidebar() {
    this.globals.toggleSidebar = !this.globals.toggleSidebar;
  }

  sidebarHover() {
    this.globals.sidebarHover = !this.globals.sidebarHover;
  }

  ngOnInit() {
    this.userMasterId = localStorage.getItem("userMasterId");
    this.loginName = localStorage.getItem("loginName");

    setTimeout(() => {
      this.innerWidth = window.innerWidth;
      if (this.innerWidth < 1200) {
        this.globals.toggleSidebar = true;
      }
    });
    this.extraParameter = this.activatedRoute.snapshot.firstChild.data.extraParameter;
    this.MenuMasterMainServService.getMenuList(this.userMasterId).subscribe((e: any) => {
      this.menuArray = e.parentMenu;
      this.childMenu = e.childMenu;
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.newInnerWidth = event.target.innerWidth;

    if (this.newInnerWidth < 1200) {
      this.globals.toggleSidebar = true;
    } else {
      this.globals.toggleSidebar = false;
    }

  }



  // my_menu = {
  //   'Geography': ['sub1', 'sub2'],
  //   'HR': ['sub1', 'sub2', 'sub3'],
  // };

}
