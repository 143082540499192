import { NgModule } from '@angular/core';
import { RouterModule, Routes } from "@angular/router";
import { CommonModule } from '@angular/common';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DataTablesModule } from 'angular-datatables';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MarkAsteriskDirectiveModule } from '../../directives/mark-asterisk.directive';
import { GenerateComponent } from './generate/generate.component';
import { BsDatepickerModule } from 'ngx-bootstrap';
import { SalarySlipComponent } from './salary-slip/salary-slip.component';

const routes: Routes = [

  {
    path: "generate",
    component: GenerateComponent
  },
  {
    path: "SalarySlip",
    component: SalarySlipComponent
  },

];

@NgModule({
  declarations: [GenerateComponent, SalarySlipComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    CommonModule,
    ReactiveFormsModule,
    DataTablesModule,
    FormsModule,
    NgbModule,
    MarkAsteriskDirectiveModule,
    BsDatepickerModule.forRoot()
  ],
  entryComponents: [
    GenerateComponent
  ]
})
export class SalaryProcessModule { }
