import { Component, OnInit, ViewChild } from '@angular/core';
import { Input } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from "ngx-toastr";
import { OpportunityModuleService } from 'src/app/services/opportunity-module.service';
import { SalaryModuleService } from 'src/app/services/salary.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbDateStruct, NgbCalendar, NgbDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { AmazingTimePickerService } from 'amazing-time-picker';
import { environment } from "../../../../environments/environment";
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';

@Component({
  selector: 'app-salary-slip',
  templateUrl: './salary-slip.component.html',
  styleUrls: ['./salary-slip.component.sass']
})
export class SalarySlipComponent implements OnInit {
  SalaryProcessForm: FormGroup;
  myDateValue: Date;
  loginId: any;
  url: any;
  employeeId = 0;
  employeeList = [];
  salaryComponentList = [];
  pageTitle = "";
  submitted: boolean;
  userMasterId: any;
  isSalaryComBtnDisplay: boolean;
  isSalarySlipDisplay: boolean;
  salaryDetails = [];
  salarySlipDetails = [];
  employeeDetails = [];
  DeductionEmolumentsDetails = [];
  salaryOtherDetailsEmolumentsResult = [];

  constructor(
    private modalService: NgbModal,
    private _formBuilder: FormBuilder,
    private toastr: ToastrService,
    private router: Router,
    private route: ActivatedRoute,
    public OpportunityModuleService: OpportunityModuleService,
    public SalaryModuleService: SalaryModuleService,
    private calendar: NgbCalendar,
    private atp: AmazingTimePickerService,
    private spinnerService: Ng4LoadingSpinnerService
  ) { }

  ngOnInit() {
    this.pageTitle = "Employee Salary Slip";
    this.isSalaryComBtnDisplay = false;
    this.isSalarySlipDisplay = false;
    // this.url = environment.url+"/dashboard";
    this.userMasterId = localStorage.getItem("userMasterId");
    this.OpportunityModuleService.getEmployeeListFroViewSalarySlip().subscribe((e: any) => {
      this.employeeList = e.data;
    });
    this.SalaryModuleService.getOtherSalaryComponentList().subscribe((e: any) => {
      this.salaryComponentList = e.data;
    });
    this.SalaryProcessForm = this.createOppertunityForm();
  }
  createOppertunityForm(): FormGroup {

    return (this.SalaryProcessForm = this._formBuilder.group({
      month: ["", Validators.required],
      employeeId: ["", Validators.required],
      salaryComponentDetails: this._formBuilder.array([]),
    }));
  }

  addSalaryComponents() {
    const documentArray = this.SalaryProcessForm.controls.salaryComponentDetails as FormArray;
    documentArray.push(
      this._formBuilder.group({
        otherSalaryCompId: ["", Validators.required],
        otherSalaryCompMode: ["", Validators.required],
        otherSalaryCompAmt: ["", Validators.required],
      })
    );
    this.isSalaryComBtnDisplay = true;
  }

  getControls() {
    return (this.SalaryProcessForm.get("salaryComponentDetails") as FormArray).controls;
  }


  onOpenCalendar(container) {
    container.monthSelectHandler = (event: any): void => {
      container._store.dispatch(container._actions.select(event.date));
    };
    container.setViewMode('month');
  }

  onGo() {
    this.spinnerService.show();
    //let month = this.SalaryProcessForm.value.month;
    //alert(month);
    if (this.SalaryProcessForm.invalid) {
      this.toastr.error("Error !! Please Select month and employee");
      return;
    }
    this.OpportunityModuleService.getEmployeeSalarySlip(this.SalaryProcessForm.value).subscribe((response: any) => {
      if (response.code === 1) {
        this.isSalarySlipDisplay = true;
        this.toastr.success("Salary slip record found successfully", "Success!");   
        this.salaryDetails = response.salaryDetails;
        this.salarySlipDetails = response.salarySlipDetails;
        this.employeeDetails = response.employeeDetails;
        this.DeductionEmolumentsDetails = response.DeductionEmolumentsDetails;
        this.salaryOtherDetailsEmolumentsResult = response.salaryOtherDetailsEmolumentsResult;
      }else{
        this.isSalarySlipDisplay = false;    
        this.toastr.error("Salary slip record not found", "Error!");   
      }
    });
  }

  goBack() {
    this.router.navigateByUrl("/app/refresh", { skipLocationChange: true }).then(() => {
      this.router.navigate(["/dashboard"]);
    });
  }

  deleteSalaryComponentDetails(index) {

    (this.SalaryProcessForm.get('salaryComponentDetails') as FormArray).removeAt(index);
    let salaryComponentDetailsCount = ((this.SalaryProcessForm.get('salaryComponentDetails') as FormArray).length);
    if (salaryComponentDetailsCount === 0) {
      this.isSalaryComBtnDisplay = false;
    }
    this.toastr.success("Success !! Row deleted successfully");
  }

  saveSalaryComponent() {
    this.submitted = true;
    if (this.SalaryProcessForm.invalid) {
      this.toastr.error("Error !! Record not created");
      return;
    }
    let salaryComponentDetailsCount = ((this.SalaryProcessForm.get('salaryComponentDetails') as FormArray).length);
    if (salaryComponentDetailsCount === 0) {
      this.toastr.error("Atleast one salary component is required.", "Error!");
      return;
    }
    this.SalaryModuleService.SaveSalaryComponent(this.SalaryProcessForm.value, this.userMasterId).subscribe((response: any) => {
      if (response.code === 1) {

        let contactDetails = ((this.SalaryProcessForm.get('salaryComponentDetails') as FormArray).length + 1);
        for (let i = 0; i < contactDetails; i++) {
          (this.SalaryProcessForm.get('salaryComponentDetails') as FormArray).removeAt(0);
        }
        this.onGo();
        this.isSalaryComBtnDisplay = false;
        this.toastr.success("Success !! " + response.msg);
        return true;
      } else if (response.code == 0) {
        this.toastr.error(response.msg, "Error!");
        return false;
      } else {
        this.toastr.error("Error !! Record not created");
        return false;
      }
    },
      error => {
        console.log("failure", error);
        return false;
      }
    );

  }

}
