import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from "@angular/router";
import { LocationStrategy, HashLocationStrategy } from "@angular/common";
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DataTablesModule } from 'angular-datatables';
import {MarkAsteriskDirectiveModule} from '../../directives/mark-asterisk.directive';
import { SalaryslabmasterlistComponent } from './list/salaryslabmasterlist/salaryslabmasterlist.component';
import { SalaryslabmastercreateComponent } from './create/salaryslabmastercreate/salaryslabmastercreate.component';
import { MastersService } from 'src/app/services/masters.service';

const routes: Routes = [
  {
    path: "salaryslabmasterlist",
    component: SalaryslabmasterlistComponent
  },
  {
    path: "salaryslabmastercreate",
    component: SalaryslabmastercreateComponent
  }
];

@NgModule({
  declarations: [SalaryslabmastercreateComponent, SalaryslabmasterlistComponent],

  imports: [
    RouterModule.forChild(routes),
    CommonModule,
    ReactiveFormsModule,
    DataTablesModule,
    MarkAsteriskDirectiveModule
  ],
  entryComponents: [
    SalaryslabmastercreateComponent
  ],
  providers : [MastersService]
})
export class SalaryslabmasterModule { }
