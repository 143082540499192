import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from "@angular/router";
import { LocationStrategy, HashLocationStrategy } from "@angular/common";
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DataTablesModule } from 'angular-datatables';

import { TermsAndConditionCategoryCreateComponent } from './terms-and-condition-category-create/terms-and-condition-category-create.component';
import { TermsAndConditionCategoryListComponent } from './terms-and-condition-category-list/terms-and-condition-category-list.component';

const routes: Routes = [
  
  {
    path: "termsandconditioncategorylist",
    component: TermsAndConditionCategoryListComponent
  },
  {
    path: "termsandconditioncategorycreate",
    component: TermsAndConditionCategoryCreateComponent
  }
];

@NgModule({
  declarations: [TermsAndConditionCategoryListComponent,TermsAndConditionCategoryCreateComponent],
  imports: [
    RouterModule.forChild(routes),
    CommonModule,
    ReactiveFormsModule,
    DataTablesModule
  ],
  entryComponents: [
    TermsAndConditionCategoryCreateComponent  
  ]
})
export class TermsAndConditionCategoryModule { }
