import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from "@angular/router";
import { LocationStrategy, HashLocationStrategy } from "@angular/common";
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DataTablesModule } from 'angular-datatables';
import { CompanyBankDetailsListComponent } from './company-bank-details-list/company-bank-details-list.component';
import { CompanyBankDetailsCreateComponent } from './company-bank-details-create/company-bank-details-create.component';
const routes: Routes = [
  {
    path: "company_bank_List",
    component: CompanyBankDetailsListComponent
  },
  {
    path: "company_bank_Create",
    component:CompanyBankDetailsCreateComponent
  }
];


@NgModule({
  declarations: [CompanyBankDetailsListComponent,CompanyBankDetailsCreateComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    ReactiveFormsModule,
    DataTablesModule
  ]
})
export class CompanyBankDetailsModule { }
