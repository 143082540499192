import { Component, OnInit } from '@angular/core';
import { Input } from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from "ngx-toastr";
import { MastersService } from 'src/app/services/masters.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';

@Component({
  selector: 'app-leave-credit-create',
  templateUrl: './leave-credit-create.component.html',
  styleUrls: ['./leave-credit-create.component.sass']
})
export class LeaveCreditCreateComponent implements OnInit {

  @Input() id;
  @Input() action;
  stateList = [];
  financialYearList=[];
  leaveCreditTypeList=[];
  empdata=[];
  LeaveCreditForm: FormGroup;
  poupuTitle: string;
  submitted: boolean;
  leavetypevar:any;
  leaveCreditCount:any;
  butDisabled:boolean;
  butDisabled2:boolean;
  butDisabled3:boolean;
  leaveTypeList:[];
  tableName:any;
  LeavesCountStyle: boolean;

  constructor( private _formBuilder: FormBuilder,
    private toastr: ToastrService,
    private router: Router,
    public MasterService: MastersService,
    public activeModal: NgbActiveModal,private spinnerService: Ng4LoadingSpinnerService) { }

  ngOnInit() {
    this.LeavesCountStyle = true;
    this.action = this.action;
    const token_key = localStorage.getItem("token");
    this.MasterService.getFinancialYrMasterList().subscribe((e: any) => {
      console.log(e.data);
      this.financialYearList = e.data;
      console.log("finanace",this.financialYearList);
    });



    this.tableName = "leave_type_master";
      this.MasterService.getActiveDetailsListForCreate(this.tableName).subscribe((e: any) => {
        this.leaveTypeList = e.data;
      });

    this.MasterService.getLeaveCreditTypeList().subscribe((e: any) => {
      console.log(e.data);
      this.leaveCreditTypeList = e.data;
      console.log("leavecredittype",this.leaveCreditTypeList);
    });

    if (this.action === "Edit") {
      this.LeavesCountStyle = false;
      this.butDisabled=true;

      this.poupuTitle = "Edit Credit Leave";
      this.MasterService.getLeaveCreditbyId(this.id).then(data => {
        this.LeaveCreditForm.patchValue(data.data);
        this.leavetypevar = data.data.leaveType;

        // this.LeaveCreditForm.controls['financialYearId'].setValue(data.data.financialYearId);
        // this.LeaveCreditForm.controls['leaveTypeId'].setValue(data.data.leaveTypeId);

        data.data.employeeList.forEach((x,index )=> {
          const documentArray = this.LeaveCreditForm.controls.employeeList as FormArray;
          documentArray.push(
            this._formBuilder.group({
              id: x.id,
              empId:x.empId,
              employeeCode : x.employeeCode,
              firstName	: x.firstName+ " " + x.lastName,
              leaves:x.leaves,

            })
          );
        });
      });


    } else if (this.action === "Add") {

      this.butDisabled=false;
      this.butDisabled2=true;
      this.butDisabled3=false;

      this.poupuTitle = "Add Credit Leave";
    }
    this.LeaveCreditForm = this.createLeaveCreditForm();
  }

  createLeaveCreditForm(): FormGroup {
    return (this.LeaveCreditForm = this._formBuilder.group({
      employeeType: ["", Validators.required],
      leaveTypeId: ["", Validators.required],
      leaves: ["", Validators.required],
      financialYearId: ["", Validators.required] ,
      employeeList: this._formBuilder.array([]),
    }));
  }


  get f() {
    return this.LeaveCreditForm.controls;

  }

  getLeaveDetails() {
    return (this.LeaveCreditForm.get("employeeList") as FormArray).controls;
  }

  addLeavedetails() {
    const documentArray = this.LeaveCreditForm.controls.employeeList as FormArray;
    documentArray.push(
      this._formBuilder.group({
        id:[""],
        empId:[""],
        employeeCode:[""],
        firstName	:[""],
        lastName	:[""],
        // leaves:[""],
        leaves:[""],
      })
    );
  }

  onSubmit(regfrm: any) {
    this.spinnerService.show();
    this.submitted = true;
    if (this.LeaveCreditForm.invalid) {
      this.toastr.error("Error !! Record not created");
      return;
    }
    this.MasterService.createLeaveCredit(this.LeaveCreditForm.value).subscribe((response: any) => {

      if (response.code === 1) {
        this.activeModal.close();
        this.toastr.success("Success !! Leaves credited successfully" );

        this.router.navigateByUrl("/app/refresh", { skipLocationChange: true }).then(() => {
          this.router.navigate(["/masters/leavecreditmaster/leaveCreditlist"]);
        });
        return true;
      } else if (response.code == 0) {
        this.toastr.error(response.msg, "Error!");
        return false;
      } else {
        this.toastr.error("Error !! Record not created");
        return false;
      }
    },
      error => {
        console.log("failure", error);
        return false;
      }
    );


  }

  UpdateProjectType() {
    this.spinnerService.show();
    this.submitted = true;
    this.LeaveCreditForm.value.id = this.id;
    console.log

    if (this.LeaveCreditForm.invalid) {
      this.toastr.error("Error !! Record not updated");
      return;
    }

    this.MasterService.updateLeaveCredit(this.id,this.LeaveCreditForm.value).subscribe((response: any) => {

        if (response.code == 1) {
          // alert("update")
          this.activeModal.close();
          this.toastr.success("Success ! Leaves updates successfully");

          this.router
            .navigateByUrl("/app/refresh", { skipLocationChange: true })
            .then(() => {
              this.router.navigate(["/masters/leavecreditmaster/leaveCreditlist"]);
            });
          return true;
        } else if (response.code == 0) {
          this.toastr.error(response.msg, "Error!");
          return false;
        } else {
          this.toastr.error("Record not created!", "Error!");
          return false;
        }
      },
      error => {
        this.toastr.warning("Internal Server Error !!", "Error!");
        return false;
      }
    );
  }
  // numberOnly(event): boolean {
  //   const step="0.01"
  //   var txtId = event.target.value;
  //   const charCode = (event.which) ? event.which : event.keyCode;
  //   if (charCode > 31 && (charCode < 48 || charCode > 57)) {
  //     return false;
  //   }
  //   return true;
  // }

  alphabetOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if ((charCode > 64 && charCode < 91) || (charCode > 96 && charCode < 123) || charCode == 8 || (charCode == 32)){
     // this.onlyAlphabet =false;

      return true;
    }else{
      //this.onlyAlphabet =true;
      //alert("only alphabates are allowed")
      return false;
    }
  }

}
