import { Injectable } from '@angular/core';
import { HttpClient, HttpClientModule, HttpHeaders } from "@angular/common/http";
import { from, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "./../../environments/environment";

const httpOptions = {
  headers: new HttpHeaders({
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Method": "POST",
    "Access-Control-Allow-Headers":
      "Access-Control-Allow-Headers,Content-Type,Access-Control-Allow-Methods, Authorization, X-Requested-With",
    "Access-Control-Request-Headers":
      "access-control-allow-origin, content-type"
  })
};

@Injectable({
  providedIn: 'root'
})
export class ProjectCreationService {

  headers: HttpHeaders | { [header: string]: string | string[] };

  constructor(private httpClient: HttpClient) { }

  private handleError(error: any): Promise<any> {
    return Promise.reject(error.message || error);
  }

  getProjectList() {
    return this.httpClient.get(environment.url + "ProjectCreation/projectList");
  }

  getProposalList(id:any) {
    return this.httpClient.get(environment.url + "ProjectCreation/getProposalList&id="+id);
  }

  getProposalDetailsByProposalId(opportunityId) {
    return this.httpClient.get(environment.url + "ProjectCreation/getProposalDetailsByProposalId&proposalId=" + opportunityId);
  }

  GetActiveDesignationList() {
    return this.httpClient.get(environment.url + "ProjectCreation/GetActiveDesignationList");
  }

  getEmployeeByDesignationId(designationId) {
    return this.httpClient.get(environment.url + "ProjectCreation/getEmployeeByDesignationId&designationId=" + designationId);
  }

  getEmployeePerHoursRate(employeeId) {
    return this.httpClient.get(environment.url + "ProjectCreation/getEmployeePerHoursRate&employeeId=" + employeeId);
  }

  createProject(userMasterId: any, FormDetails: any) {
    return this.httpClient.post<boolean>(
      environment.url + "/ProjectCreation/projectCreateUpdate&userMasterId=" + userMasterId,
      JSON.stringify(FormDetails)
    );
  }

  GetProjectDetailsByIdForView(id: any): Promise<any> {
    return this.httpClient
      .post(environment.url + "ProjectCreation/GetProjectDetailsByIdForView&id=" + id, {
        id: id
      })
      .toPromise()
      .catch(this.handleError);
  }

  GetProjectDetailsById(id: any): Promise<any> {
    return this.httpClient
      .post(environment.url + "ProjectCreation/GetProjectDetailsByIdForUpdate&id=" + id, {
        id: id
      })
      .toPromise()
      .catch(this.handleError);
  }
  deleteprojectTeamAllocationDetails(id:any){
    return this.httpClient.post(
      environment.url + "ProjectCreation/deleteprojectTeamAllocationDetails&id=" + id,
      {
        id: id,
      }
    );
  }
  updateProject(userMasterId:any,id:any,FormDetails:any){
    return this.httpClient.post<boolean>(
      environment.url + "/ProjectCreation/projectCreateUpdate&userMasterId=" + userMasterId +"&id="+id,
      JSON.stringify(FormDetails)
    );
  }
  ApproveProject(userMasterId:any,id:any,FormDetails:any){
    return this.httpClient.post<boolean>(
      environment.url + "/ProjectCreation/projectCreateUpdate&userMasterId=" + userMasterId +"&id="+id+"&flag=1",
      JSON.stringify(FormDetails)
    );
  }
}
/////////////////////////////////////////////////////////////////////////////////////
