import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MastersService } from 'src/app/services/masters.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { OthersalarycomponentcreateComponent } from '../../create/othersalarycomponentcreate/othersalarycomponentcreate.component';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';

@Component({
  selector: 'app-othersalarycomponentlist',
  templateUrl: './othersalarycomponentlist.component.html',
  styleUrls: ['./othersalarycomponentlist.component.sass']
})
export class OthersalarycomponentlistComponent implements OnInit {
  dtTrigger: Subject<any> = new Subject();
  datatableElement: any;
  dtOptions: { pagingType: string; pageLength: number; "columns": { "orderable": boolean; }[]; };
  otherSalaryComponentList: any;

  constructor(
    private modalService: NgbModal,
    public OtherSalaryComponentService: MastersService,
    private toastr: ToastrService,
    private router: Router,private spinnerService: Ng4LoadingSpinnerService
  ) { }

  ngOnInit() {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      "columns": [
        { "orderable": false },
        { "orderable": true },
        { "orderable": true },
        { "orderable": true },
      ]
    };

    this.OtherSalaryComponentService.getOthersalarycomponentList().subscribe((e: any) => {
      this.otherSalaryComponentList = e.data;
      this.dtTrigger.next();
    });
  }
  ngAfterViewInit(): void {

    this.dtTrigger.subscribe(() => {

      this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.columns().every(function (i) {
          const that = this;

          $('input', this.header()).on('click', function (e) {
            e.stopPropagation();
          });

          $('input', this.header()).on('keyup change', function () {
            if (that.search() !== this['value']) {
              if (i == 0) {
                var val = this['value'];
                that
                  .search(val ? '^' + this['value'] + '$' : val, true, false)
                  .draw();
              } else {
                that
                  .search(this['value'])
                  .draw();
              }
            }
          });
        });
      });
    });
  }


  onAdd() {
    this.spinnerService.show();
    const modalRef = this.modalService.open(OthersalarycomponentcreateComponent);
    let action = "Add";
    modalRef.componentInstance.action = action;
  }
  onEdit(id) {
    this.spinnerService.show();
    const modalRef = this.modalService.open(OthersalarycomponentcreateComponent);
    //console.log("Done");
    let getid = id;
    let action = "Edit";
    modalRef.componentInstance.action = action;
    modalRef.componentInstance.id = getid;
    // var elem = document.getElementById('')
  }



  StatusChanged(activeStatus: any, id: any) {
    this.spinnerService.show();
    if (activeStatus == 1) {
      activeStatus = 0;
    } else {
      activeStatus = 1;
    }
    this.OtherSalaryComponentService.UpdateOtherSalaryComponentStatus(id,activeStatus).subscribe((response: any) => {
      if (response.code == 1) {
        this.toastr.success("Other salary component status updated successfully");

        this.router
          .navigateByUrl("/app/refresh", { skipLocationChange: true })
          .then(() => {
            this.router.navigate(["/masters/othersalarycomponent/othersalarycomponentlist"]);
          });
        return true;
        } else if (response.code == 0) {
          this.toastr.error(response.msg, "Error!");
          return false;
        } else {
          this.toastr.error("Record not created!", "Error!");
          return false;
        }
      },
      error => {
        this.toastr.warning("Internal Server Error !!", "Error!");
        return false;
      }
    );
  }
}
