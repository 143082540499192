import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from "@angular/router";
import { DataTablesModule } from 'angular-datatables';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BsDatepickerModule} from 'ngx-bootstrap/datepicker';
import { DashboardmenumanagementcreateComponent } from './create/dashboardmenumanagementcreate/dashboardmenumanagementcreate.component';
import { DashboardmenumanagementlistComponent } from './list/dashboardmenumanagementlist/dashboardmenumanagementlist.component';

const routes: Routes = [

  {
    path: "DashboardMenuManagementList",
    component: DashboardmenumanagementlistComponent
  },

  {
    path: 'DashboardMenuManagementCreate',
    component: DashboardmenumanagementcreateComponent
  },
];

@NgModule({
  declarations: [DashboardmenumanagementlistComponent,DashboardmenumanagementcreateComponent],
  imports: [
    RouterModule.forChild(routes),
    CommonModule,
    ReactiveFormsModule,
    DataTablesModule,
    FormsModule,
    NgbModule,
    BsDatepickerModule.forRoot()
  ]
})
export class DashboardmenumanagementModule { }
