import { Component, OnInit, ViewChild } from '@angular/core';
import { Input } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from "ngx-toastr";
import { OpportunityModuleService } from 'src/app/services/opportunity-module.service';
import { ClientCreateComponent } from '../../ncreate/client-create/client-create.component';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbDateStruct, NgbCalendar, NgbDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { AmazingTimePickerService } from 'amazing-time-picker';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
@Component({
  selector: 'app-opportunity-create',
  templateUrl: './opportunity-create.component.html',
  styleUrls: ['./opportunity-create.component.sass']
})
export class OpportunityCreateComponent implements OnInit {
  @Input() id;
  @Input() action;
  @Input() customerList;
  opportunityForm: FormGroup;
  poupuTitle: string;
  submitted: boolean;
  pageTitle: string;
  // customerList = [];
  topologyList = [];
  areaList = [];
  uomList = [];
  projectTypeList = [];
  userList = [];
  newProjectCode: string;
  myDateValue: Date;
  model: NgbDateStruct;
  AddMoreDisabled = false;
  date: { year: number, month: number };
  @ViewChild('dp') dp: NgbDatepicker;
  time = { hour: 13, minute: 30 };
  time2 = { hour: 13, minute: 30 };
  notAbleToEditStyle: boolean;
  minDate: { year: number; month: number; day: number; };

  constructor(
    private modalService: NgbModal,
    private _formBuilder: FormBuilder,
    private toastr: ToastrService,
    private router: Router,
    private route: ActivatedRoute,
    public OpportunityModuleService: OpportunityModuleService,
    private calendar: NgbCalendar,
    private atp: AmazingTimePickerService, private spinnerService: Ng4LoadingSpinnerService
  ) {
    {
      const current = new Date();
      this.minDate = {
        year: current.getFullYear(),
        month: current.getMonth() + 1,
        day: current.getDate()
      };
    }
  }

  ngOnInit() {
    const token_key = localStorage.getItem("token");
    this.myDateValue = new Date();
    this.opportunityForm = this.createOppertunityForm();
    this.action = this.route.snapshot.paramMap.get("action");
    this.id = this.route.snapshot.paramMap.get("id");

    this.OpportunityModuleService.getCustomerList().subscribe((e: any) => {
      this.customerList = e.data;
    });

    this.OpportunityModuleService.getTopologyList().subscribe((e: any) => {
      this.topologyList = e.data;
    });

    this.OpportunityModuleService.getUOMList().subscribe((e: any) => {
      this.uomList = e.data;
    });

    this.OpportunityModuleService.getProjectTypeList().subscribe((e: any) => {
      this.projectTypeList = e.data;
    });

    this.OpportunityModuleService.getEmployeeList().subscribe((e: any) => {
      this.userList = e.data;
    });

    this.OpportunityModuleService.getAreaList().subscribe((e: any) => {
      this.areaList = e.data;
    });

    if (this.action === "Edit") {
      this.pageTitle = "Edit Opportunity";
      this.notAbleToEditStyle = true;
      this.OpportunityModuleService.GetOpportunityDetailsById(this.id).then(data => {
        this.opportunityForm.patchValue(data.data.opportunity);
        const expectedSigningDate = new Date(data.data.opportunity.expectedSigningDate);
        this.opportunityForm.controls['expectedSigningDate'].setValue({ year: expectedSigningDate.getFullYear(), month: expectedSigningDate.getMonth() + 1, day: expectedSigningDate.getDate() });
        if (data.data.opportunityDetails.length >= 1) {
          this.AddMoreDisabled = true;
        }
        let custId = data.data.opportunity.clientName;
        this.getCustomerContactDetails(custId);
        data.data.opportunityDetails.forEach((x, index) => {
          const documentArray = this.opportunityForm.controls.opportunityDetails as FormArray;
          const next_followup_date = new Date(x.nextFollowupDate);

          documentArray.push(
            this._formBuilder.group({
              id: x.id,
              assigned_to: x.assigned_to,
              next_followup_date: { year: next_followup_date.getFullYear(), month: next_followup_date.getMonth() + 1, day: next_followup_date.getDate() },
              nextFollowUpTime: x.nextFollowUpTime,
              remark: x.remark,
              status: x.status,
            })
          );
          //this.getopportunityDetails(x.machine_type_id,index);
        });

      });

    } else if (this.action === "Add") {
      this.notAbleToEditStyle = false;
      this.OpportunityModuleService.getProjectCode().subscribe((e: any) => {
        this.newProjectCode = e.data;
        this.opportunityForm.controls['projectCode'].setValue(this.newProjectCode);
      });
      this.pageTitle = "Create Opportunity";
    }

  }

  // getopportunityDetails(id,index){
  //   //  this.showCard = true;
  //     let model = "MachineMaster";
  //     this.masterservice.List("MachineMaster",{"condition":"t.active = 1 and machine_type= "+id}).then(
  //       res => {
  //         this.machineList[index] = res;
  //       }
  //     );
  //   }

  createOppertunityForm(): FormGroup {
    return (this.opportunityForm = this._formBuilder.group({
      clientType: ["", Validators.required],
      clientName: ["", Validators.required],
      projectName: ["", Validators.required],
      projectCode: ["", Validators.required],
      siteAddress: ["", Validators.required],
      landArea: ["", [Validators.pattern("^[0-9]*$")]],
      landAreaUOM: ["", Validators.required],
      location: [""],
      inchargePersonName: ["0"],
      typology: ["", Validators.required],
      fsiApproximate: ["", [Validators.pattern("^[0-9]*$")]],
      fsiUom: ["", Validators.required],
      constructionApproximate: ["", [Validators.pattern("^[0-9]*$")]],
      constructionUOM: ["", Validators.required],
      projectScale: ["", Validators.required],
      projectTypeId: ["", Validators.required],
      survey: ["", Validators.required],
      estimatedOpportunityValue: ["", [Validators.pattern("^[0-9]*$")]],
      // opportunityStatus: ["", Validators.required],
      expectedSigningDate: ["", Validators.required],
      reraNumber: ["", Validators.required],
      latLong: [""],
      opportunityDetails: this._formBuilder.array([]),
      contactDetails: this._formBuilder.array([]),
    }));
  }

  get f() {
    return this.opportunityForm.controls;
  }

  addContactDetails() {
    const documentArray = this.opportunityForm.controls.contactDetails as FormArray;
    documentArray.push(
      this._formBuilder.group({
        cotactId: [""],
        contactName: ["", Validators.required],
        contactNumber: ["", Validators.required],
        contactDesignation: ["", Validators.required],
        contactEmailId: ["", Validators.required],
      })
    );
  }

  getContactFormControls() {
    return (this.opportunityForm.get("contactDetails") as FormArray).controls;
  }


  selectToday() {
    this.model = this.calendar.getToday();
  }

  setCurrent() {
    //Current Date
    this.dp.navigateTo()
  }

  setDate() {
    //Set specific date
    this.dp.navigateTo({ year: 2013, month: 2 });
  }

  navigateEvent(event) {
    this.date = event.next;
  }

  addOpportunityDetails() {

    const documentArray = this.opportunityForm.controls.opportunityDetails as FormArray;
    documentArray.push(
      this._formBuilder.group({
        id: [""],
        assigned_to: ["", Validators.required],
        next_followup_date: ["", Validators.required],
        nextFollowUpTime: ["", Validators.required],
        remark: ["", Validators.required],
        status: ["", Validators.required],
      })
    );
    this.AddMoreDisabled = true;
  }
  getControls() {
    return (this.opportunityForm.get("opportunityDetails") as FormArray).controls;
  }
  deleteOpportunityDetails(index) {
    let opportunityDetails = this.opportunityForm.controls.opportunityDetails as FormArray;
    let opportunityDetails_id = opportunityDetails.value[index].id;
    if (this.action === "Add") {
      (this.opportunityForm.get('opportunityDetails') as FormArray).removeAt(index);
      this.toastr.success("Success !! Row deleted successfully");
    } else {
      if (!(opportunityDetails_id)) {
        (this.opportunityForm.get('opportunityDetails') as FormArray).removeAt(index);
        this.toastr.success("Success !! Row deleted successfully");
      } else {
        this.OpportunityModuleService.deleteOpportunityDetails(opportunityDetails_id).subscribe((response: any) => {
          if (response.code == 1) {
            this.toastr.success("Success !", response.msg);

            this.router
              .navigateByUrl("/app/refresh", { skipLocationChange: true })
              .then(() => {
                this.router.navigate(["opportinityModule/opportunity/opportunityCreate", this.id, this.action]);
              });


            return true;
          } else if (response.code == 0) {
            this.toastr.error(response.msg, "Error!");
            return false;
          } else {
            this.toastr.error("Record not created!", "Error!");
            return false;
          }
        },
          error => {
            this.toastr.warning("Internal Server Error !!", "Error!");
            return false;
          }
        );
      }
    }
    this.AddMoreDisabled = false;
  }

  onSubmit(regfrm: any) {
    this.spinnerService.show();
    this.submitted = true;
    if (this.opportunityForm.invalid) {
      this.toastr.error("Error !! Record not created");
      return;
    }
    this.OpportunityModuleService.createOpportunity(this.opportunityForm.value).subscribe((response: any) => {
      console.log("response", response.msg);
      if (response.code === 1) {
        this.toastr.success("Success !! " + response.msg);

        this.router.navigateByUrl("/app/refresh", { skipLocationChange: true }).then(() => {
          this.router.navigate(["/opportinityModule/opportunity/opportunityList"]);
        });
        return true;
      } else if (response.code == 0) {
        this.toastr.error(response.msg, "Error!");
        return false;
      } else {
        this.toastr.error("Error !! Record not created");
        return false;
      }
    },
      error => {
        console.log("failure", error);
        return false;
      }
    );
  }

  UpdateProjectTask() {
    this.spinnerService.show();
    this.submitted = true;
    //this.options.value.token = localStorage.getItem("token");
    // this.options.value.updated_by_id = localStorage.getItem("created_by_id");
    this.opportunityForm.value.id = this.id;
    if (this.opportunityForm.invalid) {
      this.toastr.error("Error !! Record not updated");
      return;
    }
    this.OpportunityModuleService.updateOpportunity(this.id, this.opportunityForm.value).subscribe((response: any) => {
      if (response.code == 1) {
        this.toastr.success("Success !", response.msg);
        this.router
          .navigateByUrl("/app/refresh", { skipLocationChange: true })
          .then(() => {
            this.router.navigate(["/opportinityModule/opportunity/opportunityList"]);
          });
        return true;
      } else if (response.code == 0) {
        this.toastr.error(response.msg, "Error!");
        return false;
      } else {
        this.toastr.error("Record not created!", "Error!");
        return false;
      }
    },
      error => {
        this.toastr.warning("Internal Server Error !!", "Error!");
        return false;
      }
    );
  }
  addClient(type: any) {
    if (type == "New" && this.action === "Add") {
      const modalRef = this.modalService.open(ClientCreateComponent, {
        size: 'lg'
      });
      let action = "Add";
      modalRef.componentInstance.action = action;
    }
  }

  openTimePicker(ev: any, index) {
    const amazingTimePicker = this.atp.open();
    let opportunityDetails = this.opportunityForm.controls.opportunityDetails as FormArray;

    amazingTimePicker.afterClose().subscribe(time => {
      this.opportunityForm.controls['opportunityDetails']['controls'][index]['controls']['nextFollowUpTime'].setValue(time);
    });
  }
  getCustomerContactDetails(id: any) {
    let contactDetails = ((this.opportunityForm.get('contactDetails') as FormArray).length + 1);
    for (let i = 0; i < contactDetails; i++) {
      (this.opportunityForm.get('contactDetails') as FormArray).removeAt(i);
    }
    this.OpportunityModuleService.getCustomerContactDetails(id).then(data => {
      data.data.forEach((x, index) => {
        const documentArray = this.opportunityForm.controls.contactDetails as FormArray;
        const next_followup_date = new Date(x.nextFollowupDate);

        documentArray.push(
          this._formBuilder.group({
            cotactId: x.id,
            contactName: x.name,
            contactNumber: x.number,
            contactDesignation: x.designation,
            contactEmailId: x.emailId,
          })
        );
      });
    });
    this.generateProjectCode();
  }
  generateProjectCode() {
    if (this.action === "Add" || this.action === "add") {
      let clientName = $("#clientName option:selected").text();
      let location = $("#location option:selected").text();
      let typology = $("#typology option:selected").text();
      let locationArray = location.split("/");
      let locationValue;
      if (locationArray.length == 3) {
        locationValue = locationArray[2];
      }
      if (clientName && locationValue && typology) {
        let codeString = clientName + "/" + locationValue + "/" + typology;
        this.opportunityForm.controls['projectCode'].setValue(codeString);
      } else {
        this.opportunityForm.controls['projectCode'].setValue(this.newProjectCode);
      }
    }
  }

  numberOnly(event): boolean {
    var txtId = event.target.value;
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode != 46) {
      return false;
    }
    return true;
  }

  goBack() {
    this.router.navigateByUrl("/app/refresh", { skipLocationChange: true }).then(() => {
      this.router.navigate(["/opportinityModule/opportunity/opportunityList"]);
    });
  }

}
