import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from "@angular/router";
import { LocationStrategy, HashLocationStrategy } from "@angular/common";
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DataTablesModule } from 'angular-datatables';
import {MarkAsteriskDirectiveModule} from '../../directives/mark-asterisk.directive';
import { SalarygradecreateComponent } from './create/salarygradecreate/salarygradecreate.component';
import { SalarygradelistComponent } from './list/salarygradelist/salarygradelist.component';
import { MastersService } from 'src/app/services/masters.service';
import { SalarygradeviewComponent } from './view/salarygradeview/salarygradeview.component';

const routes: Routes = [
  {
    path: "salarygradelist",
    component: SalarygradelistComponent
  },
  {
    path: "salarygradecreate",
    component: SalarygradecreateComponent
  },
  {
    path: "salarygradeview",
    component: SalarygradeviewComponent
  }
];

@NgModule({
  declarations: [SalarygradecreateComponent, SalarygradelistComponent, SalarygradeviewComponent],

  imports: [
    RouterModule.forChild(routes),
    CommonModule,
    ReactiveFormsModule,
    DataTablesModule,
    MarkAsteriskDirectiveModule
  ],
  entryComponents: [
    SalarygradecreateComponent
  ],
 // providers : [MastersService]
})
export class SalarygradeModule { }
