import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { MastersService } from 'src/app/services/masters.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';

@Component({
  selector: 'app-salaryslabmastercreate',
  templateUrl: './salaryslabmastercreate.component.html',
  styleUrls: ['./salaryslabmastercreate.component.sass']
})
export class SalaryslabmastercreateComponent implements OnInit {

  @Input() id;
  @Input() action;
  poupuTitle: string;
  submitted: boolean;
  salarySlabMasterForm: FormGroup;

  userMasterId: any;

  constructor(
    private modalService: NgbModal,
    private _formBuilder: FormBuilder,
    private toastr: ToastrService,
    private router: Router,
    public MasterService: MastersService,
    public activeModal: NgbActiveModal,
    private spinnerService: Ng4LoadingSpinnerService
  ) { }

  ngOnInit() {

    this.userMasterId = localStorage.getItem("userMasterId");
    this.action = this.action;
   // const token_key = localStorage.getItem("token");
    if (this.action === "Edit") {
      this.poupuTitle = "Edit Salary Slab";
      this.MasterService.GetSalarySlabMasterbyId(this.id).then(data => {
        console.log("datapatch", data.data);
        this.salarySlabMasterForm.patchValue(data.data);
      });


    } else if (this.action === "Add") {
      this.poupuTitle = "Add Salary Slab";
    }
    this.salarySlabMasterForm = this.createDecreatesalarySlabMasterForm();
  }

  createDecreatesalarySlabMasterForm(): FormGroup {
    return (this.salarySlabMasterForm = this._formBuilder.group({
      salaryRangeFrom: ["", Validators.required],//salaryRangeTo
      salaryRangeTo: ["", Validators.required],
      incrementPercentage: ["", Validators.required],
      performanceBonus: ["", Validators.required],
    }));
  }

  get f() {
    return this.salarySlabMasterForm.controls;
  }

  onSubmit() {
    this.spinnerService.show();
    this.submitted = true;
    if (this.salarySlabMasterForm.invalid) {
      this.toastr.error("Error !! Record not created");
      return;
    }
    this.MasterService.createSalarySlabMaster(this.salarySlabMasterForm.value, this.userMasterId).subscribe((response: any) => {

      if (response.code === 1) {
        this.activeModal.close();
        this.toastr.success("Salary slab added successfully");

        this.router.navigateByUrl("/app/refresh", { skipLocationChange: true }).then(() => {
         this.router.navigate(["/masters/salaryslabmaster/salaryslabmasterlist"]);
        });
        return true;
      } else if (response.code == 0) {
        this.toastr.error(response.msg, "Error!");
        return false;
      } else {
         this.toastr.error("Error !! Record not created");
         return false;
      }
   },
     error => {
       console.log("failure", error);
       return false;
     }
    );
   }
  UpdateSalarySlabMaster() {
    this.spinnerService.show();
    this.submitted = true;
    //this.options.value.token = localStorage.getItem("token");
    // this.options.value.updated_by_id = localStorage.getItem("created_by_id");
    this.salarySlabMasterForm.value.id = this.id;
    if (this.salarySlabMasterForm.invalid) {
      this.toastr.error("Error !! Record not created");
      return;
    }
    this.MasterService.UpdateSalarySlabMaster(this.id, this.salarySlabMasterForm.value, this.userMasterId).subscribe((response: any) => {
      if (response.code == 1) {
        this.activeModal.close();
        this.toastr.success("Salary slab updated successfully");

        this.router
          .navigateByUrl("/app/refresh", { skipLocationChange: true })
          .then(() => {
            this.router.navigate(["/masters/salaryslabmaster/salaryslabmasterlist"]);
          });
        return true;
      } else if (response.code == 0) {
        this.toastr.error(response.msg, "Error!");
        return false;
      } else {
        this.toastr.error("Record not created!", "Error!");
        return false;
      }
    },
    error => {
      this.toastr.warning("Internal Server Error !!", "Error!");
      return false;
    }
  );

  }



  numberOnly(event): boolean {
    var txtId = event.target.value;
    const charCode = (event.which) ? event.which : event.keyCode;
    console.log(charCode);
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
}
