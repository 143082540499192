import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from "@angular/router";
import { LocationStrategy, HashLocationStrategy } from "@angular/common";
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DataTablesModule } from 'angular-datatables';

import { FirmTypeListComponent } from './firm-type-list/firm-type-list.component';
import { FirmTypeCreateComponent } from './firm-type-create/firm-type-create.component';


const routes: Routes = [
  
  {
    path: "firmtypelist",
    component: FirmTypeListComponent
  },
  {
    path: "firmtypecreate",
    component: FirmTypeCreateComponent
  }
];

@NgModule({
  declarations: [FirmTypeListComponent,FirmTypeCreateComponent],
  imports: [
    RouterModule.forChild(routes),
    CommonModule,
    ReactiveFormsModule,
    DataTablesModule
  ],
  entryComponents: [
    FirmTypeCreateComponent  
  ]
})
export class FirmTypeModule { }
