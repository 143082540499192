import { Component, OnInit, ViewChild, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { CdkDragDrop, moveItemInArray, transferArrayItem, CdkDragStart, CdkDragEnd, CdkDragMove } from '@angular/cdk/drag-drop';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ToastrService } from "ngx-toastr";
import { ProjectCreationService } from 'src/app/services/project-creation.service';
import { ActivatedRoute, Router } from '@angular/router';
import { projectScheduleService } from 'src/app/services/projectSchedule.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { Subject } from 'rxjs';
import { FormArray, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DayPilot, DayPilotCalendarComponent, DayPilotSchedulerComponent } from "daypilot-pro-angular";
import { ResourcemovementlistComponent } from '../../list/resourcemovementlist/resourcemovementlist.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { config } from 'process';
import { data } from 'jquery';
// import {dataservice} from ''

@Component({
  selector: 'app-resourcemovementcreate',
  templateUrl: './resourcemovementcreate.component.html',
  styleUrls: ['./resourcemovementcreate.component.sass']
})
export class ResourcemovementcreateComponent implements OnInit {
  dtTrigger: Subject<any> = new Subject();
  resourceMovementList: any;
  groups: any;
  projectList: any;
  resourceMovementForm: FormGroup;
  ds: any;
  dp: any;
  resources: any;
  percentage: any;
  CalendarName: string;
  isCalanderShow: boolean;
  // events: any;
  config: DayPilot.SchedulerConfig;
  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {};
  selectedCityIds: string[];
  selectedObject;
  projectIds: any;
  ResourceMovementForm: FormGroup;
  constructor(
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private router: Router,
    public ProjectCreationService: ProjectCreationService,
    public projectScheduleService: projectScheduleService,
    private spinnerService: Ng4LoadingSpinnerService,
    private _formBuilder: FormBuilder,
    private modalService: NgbModal,
    // private ds: dataservice
    // public DayPilotModule : DayPilotModule
  ) { }

  ngOnInit() {
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'projectId',
      textField: 'projectName',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 2,
      allowSearchFilter: true
    };
    this.projectIds = this.route.snapshot.paramMap.get("id");
    this.projectScheduleService.getProjectDropDownList(this.projectIds).subscribe((e: any) => {
      this.projectList = e.data;
      if (this.projectIds != '') {
        this.selectedItems = e.projectmicroschedulingmainDataSelected;
      }
    });

    this.isCalanderShow = false;
    this.projectScheduleService.getResourceMovementList(this.projectIds).subscribe((e: any) => {
      this.groups = e.groups;
    });
    this.ResourceMovementForm = this.createloginForm();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
    }, 500);
  };

  createloginForm(): FormGroup {
    return (this.ResourceMovementForm = this._formBuilder.group({
      projectId: ["", Validators.required],
    }));
  }

  oldProjectId = '';
  ownerId = '';
  dropItem(event: CdkDragDrop<string[]>, projectId: any) {

    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
      return;
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
      // console.log(`After drop update: ${this.groups}`);
      // console.log('projectId' + projectId);
      console.log('container' + JSON.stringify(event.container.data));

      for (let i = 0; i < event.container.data.length; i++) {
        // alert(event.container.data[i]['ownerId']);
        if (event.container.data[i]['ownerId'] != null) {
          let ownerIdTemp = event.container.data[i]['ownerId'];
          console.log(event.container.data[i]['ownerId']);
          const myArr = ownerIdTemp.split("_");
          this.oldProjectId = myArr[0];
          this.ownerId = myArr[1];
          if (projectId != this.oldProjectId) {
            break;
          }
        }
      }
      console.log("projectId-->" + projectId);
      console.log("oldProjectId-->" + this.oldProjectId);
      console.log("ownerId-->" + this.ownerId);

      if (this.oldProjectId == 'newEmployee' && projectId == 'delete') {
        this.projectScheduleService.getResourceMovementList(this.projectIds).subscribe((e: any) => {
          this.groups = e.groups;
        });
        return false;
      }
      //NewEmployee
      if (this.oldProjectId == 'newEmployee' && projectId == 'NewEmployee') {
        this.projectScheduleService.getResourceMovementList(this.projectIds).subscribe((e: any) => {
          this.groups = e.groups;
        });
        return false;
      }
      if (projectId == 'NewEmployee') {
        this.projectScheduleService.getResourceMovementList(this.projectIds).subscribe((e: any) => {
          this.groups = e.groups;
        });
        return false;
      }

    }

    if (this.ownerId == undefined || this.ownerId == 'undefined') {
      return;
    }

    const modalRef = this.modalService.open(ResourcemovementlistComponent, {
      size: 'lg',
      backdrop: 'static',

    });


    let action = "Add";
    modalRef.componentInstance.action = action;
    modalRef.componentInstance.ownerId = this.ownerId;
    modalRef.componentInstance.projectId = projectId;
    modalRef.componentInstance.oldProjectId = this.oldProjectId;

  }

  state = '';
  position = '';

  dragMoved(event: CdkDragMove) {
    if (event.delta.x === 1) {
      this.position += 10;
    } else {
    }
  }


  getConnectedList(): any[] {
    return this.groups.map(x => `${x.id}`);
  }

  dropGroup(event: CdkDragDrop<string[]>) {
    console.log("Drag Group-->" + event.item.data);
    moveItemInArray(this.groups, event.previousIndex, event.currentIndex);
  }

  @ViewChild("scheduler1")
  scheduler!: DayPilotSchedulerComponent;
  message() {
    this.scheduler.control.message("Welcome!");
  }



  events: any;

  ////////////////////////////
  ProjectName(id: any, title: any, isResourceCalander: any, isAllProject: any) {
    this.CalendarName = ' ';
    if (isAllProject == 0) {

    }
    console.log(title);
    if (title == "Delete") {
      return false;
    }
    this.CalendarName = title + ' Calendar';

    this.projectScheduleService.createResourceMovementProjectName(id, isResourceCalander, isAllProject).subscribe((response: any) => {
      console.log("response", response.msg);
      if (response.code === 1) {
        this.config = {
          timeHeaders: [
            { groupBy: "Month", format: "MMMM yyyy" },
            { groupBy: "Week" },
          ],
          scale: "Day",
          cellWidth: 10,
          onEventClick: args => {
            alert("Event clicked: " + args.e.text() + "\n" + "Event start date:" + args.e.start() + "\n" + "Event end date:" + args.e.end());
          },
          separators: [{
            color: "#d3d3d3",
            location: new DayPilot.Date().firstDayOfWeek(),
            layer: "BelowEvents",
            width: 60
          }],
          days: 365,
          startDate: response.calenderStartDate,
          // resources: [ { name: "Resource 1", id: "R1" },{ name: "Resource 2", id: "R2" },{ name: "Resource 3", id: "R3" },],
          resources: response.resources,
          onEventMoved: args => {
            console.log("Event moved");
          },
          onBeforeTimeHeaderRender: args => {
            if (args.header.level === 1) {
              args.header.html = "" + args.header.start.getDay() + "-" + args.header.end.getDay();
            }
          }
        }
        // this.events = [ { id: "1", resource: "R1", start: "2021-09-10", end: "2021-09-25", text: "Project 1234", backColor: "green" },{ id: "2", resource: "R2", start: "2021-09-20", end: "2021-09-30", text: "Event 2", backColor: "Yellow" }, { id: "3", resource: "R3", start: "2021-10-10", end: "2021-11-15", text: "Event 3", backColor: "blue" }]
        this.events = response.data;

      }

    });
  }

  resorceCalander(ownerId, title) {
    this.isCalanderShow = false;
    this.CalendarName = ' ';
    if (title == "Delete") {
      return false;
    }
    if (title == "NewEmployee") {
      return false;
    }
    this.CalendarName = title + ' Calendar';
    this.projectScheduleService.getResorceCalander(ownerId).subscribe((response: any) => {
      // let start = response.data.start;
      // alert(start);
      // if(response.data.start== '0000-00-00'){
      //   alert("error")
      // }
      if (response.code === 1) {
        this.isCalanderShow = true;
        this.config = {
          timeHeaders: [
            { groupBy: "Month", format: "MMMM yyyy" },
            { groupBy: "Week" },
          ],
          scale: "Day",
          cellWidth: 10,
          onEventClick: args => {
            alert("Event clicked: " + args.e.text() + "\n" + "Event start date:" + args.e.start() + "\n" + "Event end date:" + args.e.end());
          },
          bubble: new DayPilot.Bubble({
            onLoad: args => {
              let event = args.source;
              args.html =  event.text() + " Details" + "</div>"  + "</div><div>Resource " + event.resource()  + "</div><div>Starting on " + event.start().toString("MMMM d, yyyy") + "</div>" + "</div>" + "</div><div>Ending at " + event.end().toString("MMMM d, yyyy") + "</div>";
            }
          }),
          separators: [{
            color: "#d3d3d3",
            location: new DayPilot.Date().firstDayOfWeek(),
            layer: "BelowEvents",
            width: 60
          }],
          days: 365,
          startDate: response.calenderStartDate,
          // endDate: "2021-11-11",
          // resources: [{ name: "Resource 1", id: "R1" },{ name: "Resource 2", id: "R2" },{ name: "Resource 3", id: "R3" }, ], 
          resources: response.resources,
          onEventMoved: args => {
            console.log("Event moved");
          },
          onBeforeTimeHeaderRender: args => {
            if (args.header.level === 1) {
              args.header.html = "" + args.header.start.getDay() + "-" + args.header.end.getDay();
            }
          }
        }
        // this.events = [{ id: "1", resource: "R1", start: "2021-09-10", end: "2021-09-25", text: "Project 1234", backColor: "green" },{ id: "2", resource: "R2", start: "2021-09-20", end: "2021-09-30", text: "Event 2", backColor: "Yellow" },{ id: "3", resource: "R3", start: "2021-10-10", end: "2021-11-15", text: "Event 3", backColor: "blue" }]
        this.events = response.data;
      }
    });
    setTimeout(() => {
      $(".scheduler_default_corner_inner").next("div").html('Task');
      $(".scheduler_default_corner_inner").next("div").css({"background-color": "rgb(243, 243, 243)", "padding": "25px", "color" : "black","position": "absolute","font-weight":"bold"});
    }, 500);

  }

  ProjectMilestoneCalander(projectId, title: any) {
    this.isCalanderShow = false;
    this.CalendarName = ' ';
    if (title == "Delete") {
      return false;
    }
    if (title == "NewEmployee") {
      return false;
    }
    this.CalendarName = title + ' Calendar';
    this.projectScheduleService.getProjectMilestoneCalander(projectId).subscribe((response: any) => {
      if (response.code === 1) {
        this.isCalanderShow = true;
        this.config = {
          timeHeaders: [
            { groupBy: "Month", format: "MMMM yyyy" },
            { groupBy: "Week" },
          ],
          scale: "Day",
          cellWidth: 10,
          onEventClick: args => {
            alert("Event clicked: " + args.e.text() + "\n" + "Event start date:" + args.e.start() + "\n" + "Event end date:" + args.e.end());
          },
          bubble: new DayPilot.Bubble({
            onLoad: args => {
              let event = args.source;
              args.html = event.text() + " Details" + "</div><div>Starting on " + event.start().toString("MMMM d, yyyy") + "</div>" + "</div>" + "</div><div>Ending at " + event.end().toString("MMMM d, yyyy") + "</div>" +  event.tag("percentage")+ "% Project is Completed"+ "</div>";
            }
          }),

          days: 365,
          separators: [{
            color: "#d3d3d3",
            location: new DayPilot.Date().firstDayOfWeek(),
            layer: "BelowEvents",
            width: 60
          }],
          startDate: "2021-08-01",
          // resources: [ { name: "Resource 1", id: "R1" },{ name: "Resource 2", id: "R2" },{ name: "Resource 3", id: "R3" },],
          resources: response.resources,
          onEventMoved: args => {
            console.log("Event moved");
          },
          onBeforeTimeHeaderRender: args => {
            if (args.header.level === 1) {
              args.header.html = "" + args.header.start.getDay() + "-" + args.header.end.getDay();
            }
          }
        }
        // this.events = [{id: "1", resource: "R1", start: "2021-09-10", end: "2021-09-25", text: "Project 1234", backColor: "green",tags: {percentage: "10"} },{id: "2", resource: "R2", start: "2021-09-20", end: "2021-09-30", text: "Event 2",  backColor: "Yellow",tags: {percentage: "50"} },{id: "3", resource: "R3", start: "2021-10-10", end: "2021-11-15", text: "Event 3",  backColor: "blue",tags: { percentage: "80"} }]
        this.events = response.data;
      }

    });
    setTimeout(() => {
      $(".scheduler_default_corner_inner").next("div").html('Milestone');
      $(".scheduler_default_corner_inner").next("div").css({"background-color": "rgb(243, 243, 243)", "padding": "25px","top": "0","left" : -10, "color" : "black","position": "absolute","font-weight":"bold"});
    }, 500);

  }
  //////////////////////////////////////////////////////////////////////////////////////////////

  onItemSelect() {
  }

  onSelectAll() {
  }

  onItemDeSelect() {
  }

  onGoProjectId() {
    let rootlistArray = this.ResourceMovementForm.controls['projectId'].value;
    let rootlistcount = (this.ResourceMovementForm.controls['projectId'].value).length;
    this.projectIds = '';
    for (let i = 0; i < rootlistcount; i++) {
      let rootlistId = rootlistArray[i]['projectId'];
      if (i == 0) {
        if (rootlistId) {
          this.projectIds = rootlistId;
        }
      } else {
        if (rootlistId) {
          this.projectIds = this.projectIds + "," + rootlistId;
        }
      }
    }
    //this.router.navigate(["/project/resourcemovement/ResourcemovementcreateComponent", this.projectIds]);
    this.router.navigateByUrl("/app/refresh", { skipLocationChange: true }).then(() => {
      this.router.navigate(["/project/resourcemovement/ResourcemovementcreateComponent", this.projectIds]);
    });
  }
}
