import { Injectable } from '@angular/core';
import { HttpClient, HttpClientModule, HttpHeaders } from "@angular/common/http";
import { from, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "./../../environments/environment";

const httpOptions = {
  headers: new HttpHeaders({
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Method": "POST",
    "Access-Control-Allow-Headers":
      "Access-Control-Allow-Headers,Content-Type,Access-Control-Allow-Methods, Authorization, X-Requested-With",
    "Access-Control-Request-Headers":
      "access-control-allow-origin, content-type"
  })
};

@Injectable({
  providedIn: 'root'
})
export class projectScheduleService {

  headers: HttpHeaders | { [header: string]: string | string[] };

  constructor(private httpClient: HttpClient) { }

  private handleError(error: any): Promise<any> {
    return Promise.reject(error.message || error);
  }

  getProjectListForSchedule() {
    return this.httpClient.get(environment.url + "ProjectCreation/getProjectListForSchedule");
  }

  getProjectDetailsbyId(id: any): Promise<any> {
    return this.httpClient.post(environment.url + "ProjectCreation/getProjectDetailsbyId&projectId=" + id, {
      projectId: id
    }).toPromise().catch(this.handleError);
  }
  getEmpListForScheduleList(empArray: any) {
    return this.httpClient.get(environment.url + "ProjectCreation/getEmpListForScheduleList&empArray=" + empArray);
  }
  getTaskForScheduleList() {
    return this.httpClient.get(environment.url + "ProjectCreation/getTaskForScheduleList");
  }
  createProjectSchedule(FormDetails: any) {
    return this.httpClient.post<boolean>(
      environment.url + "ProjectCreation/createUpdateProjectSchedule",
      JSON.stringify(FormDetails)
    );
  }
  updateProjectSchedule(id, FormDetails: any) {
    return this.httpClient.post<boolean>(
      environment.url + "ProjectCreation/createUpdateProjectSchedule&id=" + id,
      JSON.stringify(FormDetails)
    );
  }

  getProjectScheduledList() {
    return this.httpClient.get(environment.url + "ProjectCreation/getProjectScheduledList");
  }

  GetProjectScheduleDetailsByIdForView(id: any): Promise<any> {
    return this.httpClient.post(environment.url + "ProjectCreation/GetProjectScheduleDetailsByIdForView&mainTableId=" + id, {
      mainTableId: id
    }).toPromise().catch(this.handleError);
  }

  getPendingForApprovelProjectList() {
    return this.httpClient.get(environment.url + "ProjectCreation/getPendingForApprovelProjectList");
  }
  burningProjectList() {
    return this.httpClient.get(environment.url + "ProjectCreation/burningProjectList");
  }

  GetProjectDetailsByIdForBurningProject(id: any): Promise<any> {
    return this.httpClient
      .post(environment.url + "ProjectCreation/GetProjectDetailsByIdForBurningProject&id=" + id, {
        id: id
      })
      .toPromise()
      .catch(this.handleError);
  }

  holdProject(id: any, status: any, loginId: any) {
    return this.httpClient.post<boolean>(
      environment.url + "ProjectCreation/holdProject&id=" + id + "&status=" + status + "&loginId=" + loginId,
      JSON.stringify(id)
    );
  }
  /////////////////////////////////////////////////////
  getResourceMovementList(projectIds) {
    return this.httpClient.get(environment.url + "ProjectCreation/ResourceMovementList&projectIds=" + projectIds);
  }

  getResourceMovementItem(FormDetails: any, oldProjectId: any, projectId: any, ownerId: any) {
    return this.httpClient.post<boolean>(
      environment.url + "ProjectCreation/ResourceMovementListData&oldProjectId=" + oldProjectId + "&projectId=" + projectId + "&ownerId=" + ownerId,
      JSON.stringify(FormDetails)
    );
  }

  // getResourceMovementData(FormDetails: any,oldProjectId: any, projectId: any,ownerId: any) {
  //   return this.httpClient.post<boolean>(
  //     environment.url + "ProjectCreation/ResourceMovementListData&oldProjectId=" + oldProjectId, "&projectId=" + projectId,"&ownerId=" + ownerId,
  //     JSON.stringify(FormDetails)
  //   );
  // }

  createResourceMovement(FormDetails: any) {
    return this.httpClient.post<boolean>(
      environment.url + "ProjectCreation/createResourceMovement",
      JSON.stringify(FormDetails)
    );
  }

  createResourceMovementProjectName(id: any, isResourceCalander: any, isAllProject: any) {
    return this.httpClient.post<boolean>(
      environment.url + "ProjectCreation/GetResourceMovementCalender&projectName=" + id + "&isResourceCalander=" + isResourceCalander + "&isAllProject=" + isAllProject,
      JSON.stringify(id)
    );
  }

  getResorceCalander(ownerId: any) {
    return this.httpClient.post<boolean>(
      environment.url + "ProjectCreation/getResorceCalander&ownerId=" + ownerId,
      JSON.stringify(ownerId)
    );
  }

  getProjectMilestoneCalander(projectId: any) {
    return this.httpClient.post<boolean>(
      environment.url + "ProjectCreation/getProjectMilestoneCalander&projectId=" + projectId,
      JSON.stringify(projectId)
    );
  }

  /////////////////////////////////////////////////////////////////////////////////////////
  getProjectDropDownList(projectIds: any) {
    return this.httpClient.get(environment.url + "ProjectCreation/ResourseMovementProjectList&projectIds=" + projectIds);
  }
}
