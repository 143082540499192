import { Injectable } from '@angular/core';
import { HttpClient, HttpClientModule, HttpHeaders } from "@angular/common/http";
import { from, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "./../../environments/environment";

const httpOptions = {
  headers: new HttpHeaders({
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Method": "POST",
    "Access-Control-Allow-Headers":
      "Access-Control-Allow-Headers,Content-Type,Access-Control-Allow-Methods, Authorization, X-Requested-With",
    "Access-Control-Request-Headers":
      "access-control-allow-origin, content-type"
  })
};


@Injectable({
  providedIn: 'root'
})
export class LeaveApplyMainServiceService {



  headers: HttpHeaders | { [header: string]: string | string[] };
  constructor(private httpClient: HttpClient) { }


  private handleError(error: any): Promise<any> {
    return Promise.reject(error.message || error);
  }

  getLeaveCreditTypeList(loginId: any) {
    return this.httpClient.get(environment.url + "api/getLeavecreaditTypeList&loginId=" + loginId);
  }

  getEmpList() {
    return this.httpClient.get(environment.url + "api/getEmployeeLeaveList");
  }

  getleaveApplyDetailList(loginId: any, isApplyLeaveFromHr: any) {
    return this.httpClient.get(environment.url + "api/getLeaveApplyDetailList&loginId=" + loginId + "&isApplyLeaveFromHr=" + isApplyLeaveFromHr);
  }

  viewLeaveApplybyId(id: any): Promise<any> {
    return this.httpClient.post(environment.url + "api/getLeaveDetailsbyId&id=" + id, {
      id: id
    }).toPromise().catch(this.handleError);
  }


  updateFeasibilityCriteriaStatus(id: any, status: any): any {
    return this.httpClient.post(
      environment.url + "api/updateFeasibilityCriteriaStatus&status=" + status + "&id=" + id,
      {
        id: id,
        occupation: status
      });
  }


  createLeaveApply(FormDetails: any, loginId: any) {
    return this.httpClient.post<boolean>(
      environment.url + "/api/createUpdateLeaveApply&loginId=" + loginId,
      JSON.stringify(FormDetails)
    );
  }

  createLeaveApplyByEmp(FormDetails: any, loginId: any) {
    return this.httpClient.post<boolean>(
      environment.url + "/api/createUpdateLeaveApplyByEmp&loginId=" + loginId,
      JSON.stringify(FormDetails)
    );
  }
  updateLeaveApply(id: any, FormDetails: any) {
    return this.httpClient.post<boolean>(
      environment.url + "/api/createUpdateLeaveApply&id=" + id,
      JSON.stringify(FormDetails)
    );
  }

  updateLeaveApplyStatus(id: any, status: any): any {
    return this.httpClient.post(
      environment.url + "api/updateLeaveApplyStatus&status=" + status + "&id=" + id,
      {
        id: id,
        occupation: status
      });
  }

  // updateLeaveApply(id: any, FormDetails: any,loginId:any) {
  //   return this.httpClient.post<boolean>(
  //     environment.url + "/api/createUpdateLeaveApply&id=" + id + "&loginId=" +loginId,
  //     JSON.stringify(FormDetails)
  //   );
  // }

  // updateLeaveApplyEmp(id: any, FormDetails: any) {
  //   return this.httpClient.post<boolean>(
  //     environment.url + "/api/createUpdateLeaveApplyEmp&id=" + id,
  //     JSON.stringify(FormDetails)
  //   );
  // }

  getLeaveApplyId(id: any): Promise<any> {
    return this.httpClient
      .post(environment.url + "/api/getLeaveApplyDetailById&id=" + id, {
        id: id
      })
      .toPromise()
      .catch(this.handleError);
  }


  getManager(value: any): Promise<any> {
    return this.httpClient
      .post(environment.url + "api/getManagerList&id=" + value, {
        value: value
      })
      .toPromise()
      .catch(this.handleError);
  }

  getleaveApprovalDetailList() {
    return this.httpClient.get(environment.url + "api/getLeaveApprovalDetailList");
  }

  // getLeaveList() {
  //   return this.httpClient.get(environment.url + "api/getLeaveList");
  // }

  // getLeaveList(loginId:any) {
  //   return this.httpClient.get(environment.url + "api/getLeaveList&loginId="+loginId);
  // }

  getLeaveList(loginId: any) {
    return this.httpClient.get(environment.url + "api/getPeningLeaveListForDashboard&loginId=" + loginId);
  }



  getLeaveList2(id: any): Promise<any> {
    return this.httpClient
      .post(environment.url + "/api/getLeaveListbyId&id=" + id, {
        id: id
      })
      .toPromise()
      .catch(this.handleError);
  }

  getLeaveList3() {
    return this.httpClient.get(environment.url + "api/getLeaveListbyId3");
  }

  updateleaveStatus(a: any, id: any): Promise<any> {
    return this.httpClient
      .post(environment.url + "/api/LeaveStatusApproveReject&id=" + id + "&status=" + a, {
        // id: id,
        // a: a
        observe: 'response'
      })
      .toPromise()
      .catch(this.handleError);
  }

  getFinancialYrMasterList() {
    return this.httpClient.get(environment.url + "api/getfinancialYrMasterList");
  }

  getLeaveInfo(employeeId: any, financialYearId: any): Promise<any> {
    return this.httpClient
      .post(environment.url + "/api/GetLeveInformation&employeeId=" + employeeId + "&financialYearId=" + financialYearId, {
        observe: 'response'
      })
      .toPromise()
      .catch(this.handleError);
  }

  getActiveDetailsListForCreate(loginId) {
    return this.httpClient.get(environment.url + "api/getLeavecreaditTypeList&loginId=" + loginId + "");
  }

}
