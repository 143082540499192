 
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RouterModule, Routes } from "@angular/router";

import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DataTablesModule } from 'angular-datatables';
import { ServicelistComponent } from './list/servicelist/servicelist.component';
import { ServicecreateComponent } from './create/servicecreate/servicecreate.component';

 
import { BrowserModule } from '@angular/platform-browser';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';


const routes: Routes = [
  
  {
    path: "servicelist",
    component: ServicelistComponent
  },
  {
    path: "servicecreate",
    component:  ServicecreateComponent
  }
];

@NgModule({
  declarations: [ServicelistComponent,ServicecreateComponent],
  imports: [
    RouterModule.forChild(routes),
    CommonModule,
    ReactiveFormsModule,
    DataTablesModule,
    FormsModule,NgbModule
  ],
  exports: [ ServicecreateComponent ],

  entryComponents: [
    ServicecreateComponent 
  ]
})
export class ServiceModule { }
