import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { MastersService } from 'src/app/services/masters.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';


@Component({
  selector: 'app-othersalarycomponentcreate',
  templateUrl: './othersalarycomponentcreate.component.html',
  styleUrls: ['./othersalarycomponentcreate.component.sass']
})
export class OthersalarycomponentcreateComponent implements OnInit {
  @Input() id;
  @Input() action;
  poupuTitle: string;
  submitted: boolean;
  otherSalaryComponentForm: FormGroup;
  allowanceApplicable: Boolean;
  userMasterId: any;

  constructor(
    private modalService: NgbModal,
    private _formBuilder: FormBuilder,
    private toastr: ToastrService,
    private router: Router,
    public MasterService: MastersService,
    public activeModal: NgbActiveModal,private spinnerService: Ng4LoadingSpinnerService
  ) { }

  ngOnInit() {

    this.userMasterId = localStorage.getItem("userMasterId");
    this.action = this.action;
    // const token_key = localStorage.getItem("token");
    if (this.action === "Edit") {
      this.poupuTitle = "Edit Other Salary Component";
      this.MasterService.getOthersalarycomponentbyId(this.id).then(data => {
        console.log("datapatch", data.data);
        this.otherSalaryComponentForm.patchValue(data.data);
        if (data.data.allowanceApplicable == 1) {
          this.allowanceApplicable = true;
          this.otherSalaryComponentForm.controls['allowanceApplicable'].setValue(1);
        } else {
          this.allowanceApplicable = false;
          this.otherSalaryComponentForm.controls['allowanceApplicable'].setValue(0);
        }

      });


    } else if (this.action === "Add") {

      this.allowanceApplicable = false;
      this.poupuTitle = "Add Other Salary Component";
    }

    this.otherSalaryComponentForm = this.createDecreateotherSalaryComponentForm();
    this.otherSalaryComponentForm.controls['allowanceApplicable'].setValue(0);
  }
  createDecreateotherSalaryComponentForm(): FormGroup {
    return (this.otherSalaryComponentForm = this._formBuilder.group({
      otherSalaryComponent: ["", Validators.required],
      allowanceApplicable: ["", Validators.required],
    }));

  }

  get f() {
    return this.otherSalaryComponentForm.controls;
  }

  onSubmit() {
    this.spinnerService.show();
    this.submitted = true;
    if (this.otherSalaryComponentForm.invalid) {
      this.toastr.error("Error !! Record not created");
      return;
    }
    this.MasterService.createOtherSalaryComponent(this.otherSalaryComponentForm.value, this.userMasterId).subscribe((response: any) => {

      if (response.code === 1) {
        this.activeModal.close();
        this.toastr.success("Other salary component added successfully");
        this.router.navigateByUrl("/app/refresh", { skipLocationChange: true }).then(() => {
          this.router.navigate(["/masters/othersalarycomponent/othersalarycomponentlist"]);
        });
        return true;
      } else if (response.code == 0) {
        this.toastr.error(response.msg, "Error!");
        return false;
      } else {
        this.toastr.error("Error !! Record not created");
        return false;
      }
    },
      error => {
        console.log("failure", error);
        return false;
      }
    );
  }

  allowanceApplicableCheck(e: any) {
    if (e.target.checked) {
      this.otherSalaryComponentForm.controls['allowanceApplicable'].setValue(1);
    } else {
      this.otherSalaryComponentForm.controls['allowanceApplicable'].setValue(0);
    }
  }
  UpdateOtherSalaryComponent() {
    this.spinnerService.show();
    this.submitted = true;
    //this.options.value.token = localStorage.getItem("token");
    // this.options.value.updated_by_id = localStorage.getItem("created_by_id");
    this.otherSalaryComponentForm.value.id = this.id;
    if (this.otherSalaryComponentForm.invalid) {
      this.toastr.error("Error !! Record not created");
      return;
    }
    this.MasterService.UpdateOtherSalaryComponent(this.id, this.otherSalaryComponentForm.value).subscribe((response: any) => {
      if (response.code == 1) {
        this.activeModal.close();
        this.toastr.success("Other salary component updated successfully");

        this.router
          .navigateByUrl("/app/refresh", { skipLocationChange: true })
          .then(() => {
            this.router.navigate(["/masters/othersalarycomponent/othersalarycomponentlist"]);
          });
        return true;
      } else if (response.code == 0) {
        this.toastr.error(response.msg, "Error!");
        return false;
      } else {
        this.toastr.error("Record not created!", "Error!");
        return false;
      }
    },
      error => {
        this.toastr.warning("Internal Server Error !!", "Error!");
        return false;
      }
    );

  }
}
