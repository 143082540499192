import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from "@angular/router";
import { LocationStrategy, HashLocationStrategy } from "@angular/common";
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DataTablesModule } from 'angular-datatables';
import {MarkAsteriskDirectiveModule} from '../../directives/mark-asterisk.directive';
import { StatelistComponent } from './statelist/statelist.component';
import { StatecreateComponent } from './statecreate/statecreate.component';
import { Ng4LoadingSpinnerModule } from 'ng4-loading-spinner';


const routes: Routes = [

  {
    path: "statelist",
    component: StatelistComponent
  },
  {
    path: "statecreate",
    component: StatecreateComponent
  }
];

@NgModule({
  declarations: [StatelistComponent,StatecreateComponent],
  imports: [
    RouterModule.forChild(routes),
    CommonModule,
    ReactiveFormsModule,
    DataTablesModule,
    MarkAsteriskDirectiveModule,
    Ng4LoadingSpinnerModule
  ],
  entryComponents: [
    StatecreateComponent
  ]
})
export class StateModule { }
