import { Component, OnInit } from '@angular/core';
import { Input } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from "ngx-toastr";
import { MastersService } from 'src/app/services/masters.service';
import { removeSpaces } from './projectScope.validator';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';

@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.sass']
})
export class CreateComponent implements OnInit {
  @Input() id;
  @Input() action;
  projectTypeList = [];
  tableName: string;
  areafrm: FormGroup;
  poupuTitle: string;
  submitted: boolean;

  constructor(
    private _formBuilder: FormBuilder,
    private toastr: ToastrService,
    private router: Router,
    public MasterService: MastersService,
    public activeModal: NgbActiveModal,private spinnerService: Ng4LoadingSpinnerService) { }

  ngOnInit() {
    console.log(this.id);
    this.action = this.action;

    const token_key = localStorage.getItem("token");


    if (this.action === "Edit") {
      this.poupuTitle = "Edit Project Comprehensive Scope";
      this.MasterService.getProjectScopeDetails(this.id).then(data => {
        // this.changeState(data.data[0]['stateId']);

        this.areafrm.patchValue(data.data[0]);
        this.tableName = "project_type";
        this.MasterService.getActiveDetailsListForUpdate(data.data[0].projectTypeId, this.tableName).subscribe((e: any) => {
          console.log(e.data);
          this.projectTypeList = e.data;
        });

      });

    } else if (this.action === "Add") {
      this.poupuTitle = "Add Project Comprehensive Scope";
      this.tableName = "project_type";
        this.MasterService.getActiveDetailsListForCreate(this.tableName).subscribe((e: any) => {
          console.log(e.data);
          this.projectTypeList = e.data;
        });
    }
    this.areafrm = this.createCountryForm();
  }

  createCountryForm(): FormGroup {
    return (this.areafrm = this._formBuilder.group({
      projectTypeId: ["", Validators.required],
      scopeDescription: ["", [Validators.required, Validators.pattern('[a-zA-Z ]*'), removeSpaces]],
    }));
  }

  get f() {
    return this.areafrm.controls;
  }

  onSubmit(regfrm: any) {
    this.spinnerService.show();
    this.submitted = true;
    if (this.areafrm.invalid) {
      this.toastr.error("Error !! Record not created");
      return;
    }
    this.MasterService.createProjectScope(
      this.f.projectTypeId.value,
      this.f.scopeDescription.value).subscribe((response: any) => {
        console.log("response", response.msg);

        if (response.code === 1) {
          this.activeModal.close();
          this.toastr.success("Success !! " + response.msg);

          this.router.navigateByUrl("/app/refresh", { skipLocationChange: true }).then(() => {
            this.router.navigate(["/masters/projectscope/projectscopelist"]);
          });
          return true;
        } else if (response.code == 0) {
          this.toastr.error(response.msg, "Error!");
          return false;
        } else {
          this.toastr.error("Error !! Record not created");
          return false;
        }
      },
        error => {
          console.log("failure", error);
          return false;
        }
      );

    // this.regfrm.reset();
  }

  UpdateCompany() {
    this.spinnerService.show();
    this.submitted = true;
    //this.options.value.token = localStorage.getItem("token");
    // this.options.value.updated_by_id = localStorage.getItem("created_by_id");
    this.areafrm.value.id = this.id;
    if (this.areafrm.invalid) {
      this.toastr.error("Error !! Record not created");
      return;
    }
    this.MasterService.updateProjectScope(this.id,
      this.f.projectTypeId.value,
      this.f.scopeDescription.value).subscribe(
        (response: any) => {
          if (response.code == 1) {
            this.activeModal.close();
            this.toastr.success("Success !", response.msg);

            this.router
              .navigateByUrl("/app/refresh", { skipLocationChange: true })
              .then(() => {
                this.router.navigate(["/masters/projectscope/projectscopelist"]);
              });
            return true;
          } else if (response.code == 0) {
            this.toastr.error(response.msg, "Error!");
            return false;
          } else {
            this.toastr.error("Record not created!", "Error!");
            return false;
          }
        },
        error => {
          this.toastr.warning("Internal Server Error !!", "Error!");
          return false;
        }
      );
  }

  alphabetOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if ((charCode > 64 && charCode < 91) || (charCode > 96 && charCode < 123) || charCode == 8 || (charCode == 32)){
     // this.onlyAlphabet =false;

      return true;
    }else{
      //this.onlyAlphabet =true;
      //alert("only alphabates are allowed")
      return false;
    }
  }

}
