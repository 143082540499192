import { Injectable } from '@angular/core';
import { HttpClient, HttpClientModule, HttpHeaders } from "@angular/common/http";
import { from, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "./../../environments/environment";


const httpOptions = {
  headers: new HttpHeaders({
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Method": "POST",
    "Access-Control-Allow-Headers":
      "Access-Control-Allow-Headers,Content-Type,Access-Control-Allow-Methods, Authorization, X-Requested-With",
    "Access-Control-Request-Headers":
      "access-control-allow-origin, content-type"
  })
};

@Injectable({
  providedIn: 'root'
})
export class TimesheetService {
  headers: HttpHeaders | { [header: string]: string | string[] };
  constructor(private httpClient: HttpClient) { }

  gettasklist() {
    return this.httpClient.get(environment.url + "api/GetDetails&table=task_creation");
  }

  private handleError(error: any): Promise<any> {
    return Promise.reject(error.message || error);
  }

  // Ram Garole
  markAttendance(userId: any) {
    return this.httpClient.post<boolean>(
      environment.url + "timeSheet/MarkAttendance&userId=" + userId,
      JSON.stringify(userId)
    );
  }

  signOutAttendance(userId: any) {
    return this.httpClient.post<boolean>(
      environment.url + "timeSheet/signOutAttendance&userId=" + userId,
      JSON.stringify(userId)
    );
  }

  getAttendanceDatails(userId: any) {
    return this.httpClient.get(environment.url + "timeSheet/getAttendanceDetails&userId=" + userId);
  }

  getProjectListForTimeSheet(loginId) {
    return this.httpClient.get(environment.url + "timeSheet/getProjectListForTimeSheet&loginId="+loginId);
  }

  getprojectTaskById(deliveryScheduleId, projectId, usedTaskArray, userMasterId) {
    return this.httpClient.get(environment.url + "timeSheet/getprojectTaskById&deliveryScheduleId=" + deliveryScheduleId + "&projectId=" + projectId + "&usedTaskArray=" + usedTaskArray + "&userMasterId=" + userMasterId);
  }

  getTaskListForTimesheet() {
    return this.httpClient.get(environment.url + "timeSheet/getTaskListForTimesheet");
  }

  getDeliveryScheduleById(projectId, loginId) {
    return this.httpClient.get(environment.url + "timeSheet/getDeliveryScheduleById&projectId=" + projectId + "&loginId=" + loginId);

  }
  getEstimatedEfforts(taskId, projectId, projectTaskId, userId) {
    return this.httpClient.get(environment.url + "timeSheet/getEstimatedEfforts&taskId=" + taskId + "&userId=" + userId + "&projectId=" + projectId + "&projectTaskId=" + projectTaskId);
  }

  createtimesheet(FormDetails: any, userId: any) {
    return this.httpClient.post<boolean>(
      environment.url + "timeSheet/createtimesheet&id=0&userId=" + userId,
      JSON.stringify(FormDetails)
    );
  }

  updatetimesheet(id: any, FormDetails: any, userId: any) {
    return this.httpClient.post<boolean>(
      environment.url + "timeSheet/createtimesheet&id=" + id + "&userId=" + userId,
      JSON.stringify(FormDetails)
    );
  }

  getTimesheetDetails(id: any): Promise<any> {
    return this.httpClient
      .post(environment.url + "timeSheet/getTimesheetDetailsForView&id=" + id, {
        id: id
      })
      .toPromise()
      .catch(this.handleError);
  }

  gettimesheetlist() {
    return this.httpClient.get(environment.url + "timeSheet/getdetailtimesheet");
  }

  gettimesheetdata(id: any): Promise<any> {
    return this.httpClient
      .post(environment.url + "timeSheet/gettimesheetdata&id=" + id, {
        id: id
      })
      .toPromise()
      .catch(this.handleError);
  }

  deletetimesheet(id: any) {
    return this.httpClient.post(
      environment.url + "timeSheet/deletetimesheet&id=" + id,
      {
        id: id,
      }
    );
  }

  getEmployeeList() {
    return this.httpClient.get(environment.url + "timeSheet/getEmployeeListForAttendance");
  }

  markAttendanceByHr(FormDetails: any, userId: any) {
    return this.httpClient.post<boolean>(
      environment.url + "timeSheet/markAttendanceByHr&id=0&userId=" + userId,
      JSON.stringify(FormDetails)
    );
  }
  getAttendanceAndLeaveDetails(employee_name: any, attendanceDate: any) {
    return this.httpClient.post(
      environment.url + "timeSheet/getAttendanceAndLeaveDetails&employee_name=" + employee_name + "&attendanceDate=" + attendanceDate,
      {
        employee_name: employee_name,
        attendanceDate: attendanceDate,
      }
    );
  }

}
