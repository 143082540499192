import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from "@angular/router";
import { LocationStrategy, HashLocationStrategy } from "@angular/common";
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DataTablesModule } from 'angular-datatables';
import {MarkAsteriskDirectiveModule} from '../../directives/mark-asterisk.directive';
import { LateinearlyoutmastercreateComponent } from './create/lateinearlyoutmastercreate/lateinearlyoutmastercreate.component';
import { LateinearlyoutmasterlistComponent } from './list/lateinearlyoutmasterlist/lateinearlyoutmasterlist.component';
import { MastersService } from 'src/app/services/masters.service';

const routes: Routes = [
  {
    path: "lateinearlyoutmasterlist",
    component: LateinearlyoutmasterlistComponent
  },
  {
    path: "lateinearlyoutmastercreate",
    component: LateinearlyoutmastercreateComponent
  }
];

@NgModule({
  declarations: [LateinearlyoutmastercreateComponent, LateinearlyoutmasterlistComponent],

  imports: [
    RouterModule.forChild(routes),
    CommonModule,
    ReactiveFormsModule,
    DataTablesModule,
    MarkAsteriskDirectiveModule
  ],
  entryComponents: [
    LateinearlyoutmastercreateComponent
  ],
  providers : [MastersService]
})
export class LateinearlyoutmasterModule { }
