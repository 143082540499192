import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from "@angular/router";
import { LocationStrategy, HashLocationStrategy } from "@angular/common";
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DataTablesModule } from 'angular-datatables';
import {MarkAsteriskDirectiveModule} from '../../directives/mark-asterisk.directive';
import { CreateComponent } from './create/create.component';
import { ListComponent } from './list/list.component';
const routes: Routes = [
  {
    path: "tasktypelist",
    component: ListComponent
  },
  {
    path: "tasktypecreate",
    component: CreateComponent
  }
];

@NgModule({
  declarations: [CreateComponent,ListComponent],
  imports: [
    RouterModule.forChild(routes),
    CommonModule,
    ReactiveFormsModule,
    DataTablesModule,
    MarkAsteriskDirectiveModule
  ],
  entryComponents: [
    CreateComponent  
  ],
})

export class TaskTypeModule { }
