import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from "@angular/router";
import { OpportunityListComponent } from './list/opportunity-list/opportunity-list.component';
import { OpportunityCreateComponent } from './create/opportunity-create/opportunity-create.component';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DataTablesModule } from 'angular-datatables';
import { OpportunityViewComponent } from './view/opportunity-view/opportunity-view.component';
import { ClientCreateComponent } from './ncreate/client-create/client-create.component';
import { NgbdDatepickerPopup } from './create/opportunity-create/datepicker-popup';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {MarkAsteriskDirectiveModule} from '../../directives/mark-asterisk.directive';



const routes: Routes = [
  
  {
    path: "opportunityList",
    component: OpportunityListComponent
  },
  {
    path: 'opportunityCreate/:id/:action', 
    component: OpportunityCreateComponent 
  },
  {
    path: 'opportunityCreate/:action', 
    component: OpportunityCreateComponent 
  },
  {
    path: "opportunityView/:id",
    component: OpportunityViewComponent
  },
  {
    path: "clientcreate",
    component: ClientCreateComponent
  },

];

@NgModule({
  declarations: [OpportunityListComponent,OpportunityCreateComponent, OpportunityViewComponent, ClientCreateComponent,NgbdDatepickerPopup],
  imports: [
    RouterModule.forChild(routes),
    CommonModule,
    ReactiveFormsModule,
    DataTablesModule,
    FormsModule,
    NgbModule,
    MarkAsteriskDirectiveModule
    
  ],
  
  entryComponents: [
    OpportunityCreateComponent  ],
  exports: [NgbdDatepickerPopup],
  bootstrap: [NgbdDatepickerPopup],
})
export class OpportunityModule { }