import { Injectable } from '@angular/core';
import { HttpClient, HttpClientModule, HttpHeaders } from "@angular/common/http";
import { from, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "./../../environments/environment";

const httpOptions = {
  headers: new HttpHeaders({
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Method": "POST",
    "Access-Control-Allow-Headers":
      "Access-Control-Allow-Headers,Content-Type,Access-Control-Allow-Methods, Authorization, X-Requested-With",
    "Access-Control-Request-Headers":
      "access-control-allow-origin, content-type"
  })
};
@Injectable({
  providedIn: 'root'
})
export class ProposalModuleService {

  headers: HttpHeaders | { [header: string]: string | string[] };
  constructor(private httpClient: HttpClient) { }
  private handleError(error: any): Promise<any> {
    return Promise.reject(error.message || error);
  }

  /**
    *Discription : Proposal api's.
    *@author: Ram Garole
    * Date: 07-06-21
  */

  getProposalList() {
    return this.httpClient.get(environment.url + "api/GetProposalList&table=proposal");
  }

  getOpportunity(opportunityId) {
    return this.httpClient.get(environment.url + "api/getOpportunity&opportunityId=" + opportunityId);
  }

  getCutomerList() {
    return this.httpClient.get(environment.url + "api/getCutomerList");
  }

  getScopeByProjectTypeId(ProjectTypeId) {
    return this.httpClient.get(environment.url + "api/getScopeByProjectTypeId&ProjectTypeId=" + ProjectTypeId);
  }

  getDeliveryScheduleByProjectTypeId(ProjectTypeId) {
    return this.httpClient.get(environment.url + "api/getDeliveryScheduleByProjectTypeId&ProjectTypeId=" + ProjectTypeId);
  }

  getOpportunityDetailsByOpportunityId(opportunityId) {
    return this.httpClient.get(environment.url + "api/getOpportunityDetailsByOpportunityId&opportunityId=" + opportunityId);
  }
  getAreaWiseRateByAreaId(areaId) {
    return this.httpClient.get(environment.url + "api/getAreaWiseRateByAreaId&areaId=" + areaId);
  }

  createPraposal(FormDetails: any, loginId: any) {
    return this.httpClient.post<boolean>(
      environment.url + "/api/praposalCreateUpdate&loginId=" + loginId,
      JSON.stringify(FormDetails)
    );
  }

  getProposalbyId(id: any): Promise<any> {
    return this.httpClient.post(environment.url + "api/getProposalbyId&proposalId=" + id, {
      proposalId: id
    }).toPromise().catch(this.handleError);
  }

  viewProposalbyId(id: any): Promise<any> {
    return this.httpClient.post(environment.url + "api/viewProposalbyId&proposalId=" + id, {
      proposalId: id
    }).toPromise().catch(this.handleError);
  }

  updatePraposal(id: any, FormDetails: any) {
    return this.httpClient.post<boolean>(
      environment.url + "/api/praposalCreateUpdate&id=" + id,
      JSON.stringify(FormDetails)
    );
  }

  createProposalRevision(id: any, loginId: any, FormDetails: any) {
    return this.httpClient.post<boolean>(
      environment.url + "/api/createProposalRevision&id=" + id + "&loginId=" + loginId,
      JSON.stringify(FormDetails)
    );
  }

  UpdateApproveReject(id: any, FormDetails: any, status: any) {
    return this.httpClient.post<boolean>(
      environment.url + "/api/praposalCreateUpdate&id=" + id + "&status=" + status,
      JSON.stringify(FormDetails)
    );
  }

  deletePaymentStageDetails(id: any) {
    return this.httpClient.post(
      environment.url + "api/deletePaymentStageDetails&id=" + id,
      {
        id: id,
      }
    );
  }

  deleteScopeOfWork(id: any) {
    return this.httpClient.post(
      environment.url + "api/deleteScopeOfWork&id=" + id,
      {
        id: id,
      }
    );
  }

  deleteProjectSchedule(id: any) {
    return this.httpClient.post(
      environment.url + "api/deleteProjectSchedule&id=" + id,
      {
        id: id,
      }
    );
  }
  deleteProposalServiceDetails(id: any) {
    return this.httpClient.post(
      environment.url + "api/deleteProposalServiceDetails&id=" + id,
      {
        id: id,
      }
    );
  }
  getDescriptionByDeliveryScheduleId(deliveryScheduleId: any) {
    return this.httpClient.get(environment.url + "api/getDescriptionByDeliveryScheduleId&deliveryScheduleId=" + deliveryScheduleId);
  }
  getTaskWeitageUsingDeliveryScheduleId(deliveryScheduleId: any) {
    return this.httpClient.get(environment.url + "api/getTaskWeitageUsingDeliveryScheduleId&deliveryScheduleId=" + deliveryScheduleId);
  }
  getServiceList() {
    return this.httpClient.get(environment.url + "api/getServiceList");
  }
  /**
    END
  */

  /**
    *Discription : Proposal Not Interested List api's.
    *@author: Ram Garole
    * Date: 07-06-21
  */
  getProsalFolloupNotInterestedList() {
    return this.httpClient.get(environment.url + "api/getProsalFolloupNotInterestedList");
  }
  GetProposalFollowupDetailsList(id: any): Promise<any> {
    return this.httpClient
      .post(environment.url + "api/getProsalFolloupDetailsList&id=" + id, {
        id: id
      })
      .toPromise()
      .catch(this.handleError);
  }

  createProposalFollowup(id, FormDetails: any) {
    return this.httpClient.post<boolean>(
      environment.url + "/api/createProposalFollowup&id=" + id,
      JSON.stringify(FormDetails)
    );
  }
  getProposalFolloupList() {
    return this.httpClient.get(environment.url + "api/getProposalFolloupList");
  }

  getProposalFolloupListForDashboard(userMasterId: any) {
    return this.httpClient.get(environment.url + "api/getProposalFolloupList&loginId=" + userMasterId);
  }

  proposalAppraovalList() {
    return this.httpClient.get(environment.url + "api/getProposalAppraovalList");
  }

  proposalRevisionApprovalList() {
    return this.httpClient.get(environment.url + "api/proposalRevisionApprovalList");
  }
  /**
    END
  */

  getProposalRevisionbyId(id: any): Promise<any> {
    return this.httpClient.post(environment.url + "api/getProposalRevisionbyId&proposalId=" + id, {
      proposalId: id
    }).toPromise().catch(this.handleError);
  }

  getOpportunityFroProposalRevision(opportunityId) {
    return this.httpClient.get(environment.url + "api/getOpportunityFroProposalRevision&opportunityId=" + opportunityId);
  }


  proposalRevisionApproveReject(userMasterId: any, id: any, FormDetails: any, status: any) {
    return this.httpClient.post<boolean>(
      environment.url + "/api/proposalRevisionApproveReject&userMasterId=" + userMasterId + "&id=" + id + "&status=" + status,
      JSON.stringify(FormDetails)
    );
  }

  getProposalRevisionDetailsById(id) {
    return this.httpClient.get(environment.url + "api/getProposalRevisionDetailsById&id=" + id);
  }

  deleteProposalRevisionScopeOfWork(id: any) {
    return this.httpClient.post(
      environment.url + "api/deleteProposalRevisionScopeOfWork&id=" + id,
      {
        id: id,
      }
    );
  }

  deleteProposalRevisionProjectSchedule(id: any) {
    return this.httpClient.post(
      environment.url + "api/deleteProposalRevisionProjectSchedule&id=" + id,
      {
        id: id,
      }
    );
  }
  deleteProposalRevisionServiceDetails(id: any) {
    return this.httpClient.post(
      environment.url + "api/deleteProposalRevisionServiceDetails&id=" + id,
      {
        id: id,
      }
    );
  }

  deleteProposalRevisionPaymentStageDetails(id: any) {
    return this.httpClient.post(
      environment.url + "api/deleteProposalRevisionPaymentStageDetails&id=" + id,
      {
        id: id,
      }
    );
  }

}
