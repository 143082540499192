import { NgModule } from '@angular/core';
import { RouterModule, Routes } from "@angular/router";
import { CommonModule } from '@angular/common';
import { LocationStrategy, HashLocationStrategy } from "@angular/common";
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DataTablesModule } from 'angular-datatables';

import { TimesheetlistComponent } from './timesheetlist/timesheetlist.component';

const routes: Routes = [

  {
    path: "timesheetlist",
    component: TimesheetlistComponent
  },
];

@NgModule({
  declarations: [TimesheetlistComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    CommonModule,
    ReactiveFormsModule,
    DataTablesModule
  ],
  entryComponents: [
  ]
})
export class ListModule { }
