import { Component, OnInit,ViewChild } from '@angular/core';
import { OpportunityModuleService } from 'src/app/services/opportunity-module.service';
import { DataTableDirective, DataTablesModule } from 'angular-datatables';
import { Subject } from 'rxjs';
import { ToastrService } from "ngx-toastr";
import { Router,ActivatedRoute } from '@angular/router';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
@Component({
  selector: 'app-opportunity-followup-list',
  templateUrl: './opportunity-followup-list.component.html',
  styleUrls: ['./opportunity-followup-list.component.sass']
})
export class OpportunityFollowupListComponent implements OnInit {
  dtTrigger: Subject<any> = new Subject();
  @ViewChild(DataTableDirective)
  datatableElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  opportunityFollowupList:any[];

  constructor(
    public OpportunityService: OpportunityModuleService,
    private toastr: ToastrService,
    private router: Router,private spinnerService: Ng4LoadingSpinnerService
  ) { }

  ngOnInit() {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 8,
      "columns": [
        { "orderable": false },
        { "orderable": true },
        { "orderable": true },
        { "orderable": true },
        { "orderable": true },
        { "orderable": true },
        { "orderable": true },
        { "orderable": true },
      ]
    };
    this.OpportunityService.getOpportunityFolloupList().subscribe((e: any) => {
      this.opportunityFollowupList = e.data;
      this.dtTrigger.next();
    });
  }

  ngAfterViewInit(): void {

    this.dtTrigger.subscribe(() => {

      this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.columns().every(function (i) {
          const that = this;

          $('input', this.header()).on('click', function (e) {
            e.stopPropagation();
          });


          $('input', this.header()).on('keyup change', function () {
            if (that.search() !== this['value']) {
              if (i == 0) {
                var val = this['value'];
                that
                  .search(val ? '^' + this['value'] + '$' : val, true, false)
                  .draw();
              } else {
                that
                  .search(this['value'])
                  .draw();
              }
            }
          });
        });
      });
    });
  }

  onCreate(id:any){
    this.spinnerService.show();
    let action="Add";
    this.router.navigate(["opportinityModule/opportunityFollowup/opportunityFollowupCreate",id,action]);
  }

  getstatus(status:any){
    if(status == 1){
      return "Pending";
    }else if(status == 2){
      return "Partial Interested";
    }else if(status == 3){
      return "Interested";
    }else if(status == 4){
      return "Not Interested";
    }
    return "NA";
  }
}
