import { Component, OnInit } from '@angular/core';
import { Input } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MastersService } from 'src/app/services/masters.service';

@Component({
  selector: 'app-salarygradeview',
  templateUrl: './salarygradeview.component.html',
  styleUrls: ['./salarygradeview.component.sass']
})
export class SalarygradeviewComponent implements OnInit {
  poupuTitle: string;
  salaryDetails:any[];
  salaryGradeDetails:any[];
  @Input() id;

  constructor(
    private router: Router,
    public MasterService: MastersService,
    public activeModal: NgbActiveModal
  ) { }

  ngOnInit() {
    this.poupuTitle = "Salary Grade Details";
    this.MasterService.GetSalaryById(this.id).then(data => {
      console.log("datapatch", data.data);
      this.salaryDetails = data.data;
    });
    this.MasterService.GetSalaryGradeDetails(this.id).then(data => {
      this.salaryGradeDetails = data.data;
    });
  }

}
