import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from "@angular/router";
import { LocationStrategy, HashLocationStrategy } from "@angular/common";
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DataTablesModule } from 'angular-datatables';
import { TaxMasterListComponent } from './tax-master-list/tax-master-list.component';
import { TaxMasterCreateComponent } from './tax-master-create/tax-master-create.component';
// import { AuthGuard } from './authguard.guard';

const routes: Routes = [
  {
    path: "taxMasterList",
    // canActivate: [AuthGuard],
    component: TaxMasterListComponent
  },
  {
    path: "taxMasterCreate",
    component:TaxMasterCreateComponent
  }
];

@NgModule({
  declarations: [TaxMasterListComponent,TaxMasterCreateComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    ReactiveFormsModule,
    DataTablesModule
  ],
  entryComponents: [TaxMasterCreateComponent]
})
export class TaxMasterModule { }
