import { Component, OnInit } from '@angular/core';
import { Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from "ngx-toastr";
import { MastersService } from 'src/app/services/masters.service';
import { removeSpaces } from './deliveryschedule.validator';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';

@Component({
  selector: 'app-deliveryschedulecreate',
  templateUrl: './deliveryschedulecreate.component.html',
  styleUrls: ['./deliveryschedulecreate.component.sass']
})
export class DeliveryschedulecreateComponent implements OnInit {
  @Input() id;
  @Input() action;
  deliveryScheduleForm: FormGroup;
  poupuTitle: string;
  submitted: boolean;
  projectTypeList=[];
  tableName: string;

  constructor(
    private _formBuilder: FormBuilder,
    private toastr: ToastrService,
    private router: Router,
    public MasterService: MastersService,
    public activeModal: NgbActiveModal,private spinnerService: Ng4LoadingSpinnerService
  ) { }

  ngOnInit() {
    this.action = this.action;
    const token_key = localStorage.getItem("token");
    this.deliveryScheduleForm = this.createDeliveryScheduleForm();

    if (this.action === "Edit") {
      this.poupuTitle = "Edit Delievery Schedule";

      this.MasterService.GetDeliverySchedulebyId(this.id).then(data => {
        console.log("datapatch", data.data);
        this.deliveryScheduleForm.patchValue(data.data);

        this.tableName = "project_type";
        this.MasterService.getActiveDetailsListForUpdate(data.data.projectTypeId, this.tableName).subscribe((e: any) => {
          console.log(e.data);
          this.projectTypeList = e.data;
        });
      });

    } else if (this.action === "Add") {
      this.poupuTitle = "Add Delievery Schedule";
      this.tableName = "project_type";
        this.MasterService.getActiveDetailsListForCreate(this.tableName).subscribe((e: any) => {
          console.log(e.data);
          this.projectTypeList = e.data;
        });
        //this.deliveryScheduleForm.controls['paymentAgainst'].setValue(1);
      }
  }

  createDeliveryScheduleForm(): FormGroup {
    return (this.deliveryScheduleForm = this._formBuilder.group({
      projectTypeId: ["", Validators.required],
      scheduleTitle: ["", [Validators.required,Validators.pattern('[a-zA-Z ]*'),removeSpaces]],
      scheduleDescription: ["", Validators.required],
      //paymentAgainst: ["", Validators.required],
    }));
  }

  get f() {
    return this.deliveryScheduleForm.controls;
  }

  onSubmit(regfrm: any) {
    this.spinnerService.show();
    this.submitted = true;
    if (this.deliveryScheduleForm.invalid) {
      this.toastr.error("Error !! Record not created");
      return;
    }
    this.MasterService.createDeliverySchedule(this.deliveryScheduleForm.value).subscribe((response: any) => {

      if (response.code === 1) {
        this.activeModal.close();
        this.toastr.success("Success !! " + response.msg);

        this.router.navigateByUrl("/app/refresh", { skipLocationChange: true }).then(() => {
          this.router.navigate(["/masters/deliveryschedule/deliveryschedulelist"]);
        });
        return true;
      } else if (response.code == 0) {
        this.toastr.error(response.msg, "Error!");
        return false;
      } else {
        this.toastr.error("Error !! Record not created");
        return false;
      }
    },
      error => {
        console.log("failure", error);
        return false;
      }
    );
  }

  UpdateDeliverySchedule() {
    this.spinnerService.show();
    this.submitted = true;
    //this.options.value.token = localStorage.getItem("token");
    // this.options.value.updated_by_id = localStorage.getItem("created_by_id");
    this.deliveryScheduleForm.value.id = this.id;
    if (this.deliveryScheduleForm.invalid) {
      this.toastr.error("Error !! Record not updated");
      return;
    }
    this.MasterService.updateDeliverySchedule(this.id, this.deliveryScheduleForm.value).subscribe((response: any) => {
        if (response.code == 1) {
          this.activeModal.close();
          this.toastr.success("Success !", response.msg);

          this.router
            .navigateByUrl("/app/refresh", { skipLocationChange: true })
            .then(() => {
              this.router.navigate(["/masters/deliveryschedule/deliveryschedulelist"]);
            });
          return true;
        } else if (response.code == 0) {
          this.toastr.error(response.msg, "Error!");
          return false;
        } else {
          this.toastr.error("Record not created!", "Error!");
          return false;
        }
      },
      error => {
        this.toastr.warning("Internal Server Error !!", "Error!");
        return false;
      }
    );
  }


  alphabetOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if ((charCode > 64 && charCode < 91) || (charCode > 96 && charCode < 123) || charCode == 8 || (charCode == 32)){
     // this.onlyAlphabet =false;

      return true;
    }else{
      //this.onlyAlphabet =true;
      //alert("only alphabates are allowed")
      return false;
    }
  }

  public findInvalidControls() {
    const invalid = [];
    const controls = this.deliveryScheduleForm.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    alert(invalid);
  }
}
