import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgReduxModule } from '@angular-redux/store';
import { NgRedux, DevToolsExtension } from '@angular-redux/store';
import { rootReducer, ArchitectUIState } from './ThemeOptions/store';
import { ConfigActions } from './ThemeOptions/store/config.actions';
import { AppRoutingModule } from './app-routing.module';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { Ng4LoadingSpinnerModule } from 'ng4-loading-spinner';

import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';

// BOOTSTRAP COMPONENTS

import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { ChartsModule } from 'ng2-charts';
// import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
// LAYOUT

import { BaseLayoutComponent } from './Layout/base-layout/base-layout.component';
import { PagesLayoutComponent } from './Layout/pages-layout/pages-layout.component';
import { PageTitleComponent } from './Layout/Components/page-title/page-title.component';

// HEADER

import { HeaderComponent } from './Layout/Components/header/header.component';
import { SearchBoxComponent } from './Layout/Components/header/elements/search-box/search-box.component';
import { UserBoxComponent } from './Layout/Components/header/elements/user-box/user-box.component';

// SIDEBAR

import { SidebarComponent } from './Layout/Components/sidebar/sidebar.component';
import { LogoComponent } from './Layout/Components/sidebar/elements/logo/logo.component';

// FOOTER

import { FooterComponent } from './Layout/Components/footer/footer.component';

// DEMO PAGES

// Dashboards

import { AnalyticsComponent } from './DemoPages/Dashboards/analytics/analytics.component';

// Pages

import { ForgotPasswordBoxedComponent } from './DemoPages/UserPages/forgot-password-boxed/forgot-password-boxed.component';
import { LoginBoxedComponent } from './DemoPages/UserPages/login-boxed/login-boxed.component';
import { RegisterBoxedComponent } from './DemoPages/UserPages/register-boxed/register-boxed.component';

// Elements

import { StandardComponent } from './DemoPages/Elements/Buttons/standard/standard.component';
import { DropdownsComponent } from './DemoPages/Elements/dropdowns/dropdowns.component';
import { CardsComponent } from './DemoPages/Elements/cards/cards.component';
import { ListGroupsComponent } from './DemoPages/Elements/list-groups/list-groups.component';
import { TimelineComponent } from './DemoPages/Elements/timeline/timeline.component';
import { IconsComponent } from './DemoPages/Elements/icons/icons.component';

// Components

import { AccordionsComponent } from './DemoPages/Components/accordions/accordions.component';
import { TabsComponent } from './DemoPages/Components/tabs/tabs.component';
import { CarouselComponent } from './DemoPages/Components/carousel/carousel.component';
import { ModalsComponent } from './DemoPages/Components/modals/modals.component';
import { ProgressBarComponent } from './DemoPages/Components/progress-bar/progress-bar.component';
import { PaginationComponent } from './DemoPages/Components/pagination/pagination.component';
import { TooltipsPopoversComponent } from './DemoPages/Components/tooltips-popovers/tooltips-popovers.component';

// Tables

import { RegularComponent } from './DemoPages/Tables/regular/regular.component';
import { TablesMainComponent } from './DemoPages/Tables/tables-main/tables-main.component';

// Widgets

import { ChartBoxes3Component } from './DemoPages/Widgets/chart-boxes3/chart-boxes3.component';

// Forms Elements

import { ControlsComponent } from './DemoPages/Forms/Elements/controls/controls.component';
import { LayoutComponent } from './DemoPages/Forms/Elements/layout/layout.component';

// Charts

import { ChartjsComponent } from './DemoPages/Charts/chartjs/chartjs.component';

// Chart.js Examples

import { LineChartComponent } from './DemoPages/Charts/chartjs/examples/line-chart/line-chart.component';
import { BarChartComponent } from './DemoPages/Charts/chartjs/examples/bar-chart/bar-chart.component';
import { ScatterChartComponent } from './DemoPages/Charts/chartjs/examples/scatter-chart/scatter-chart.component';
import { RadarChartComponent } from './DemoPages/Charts/chartjs/examples/radar-chart/radar-chart.component';
import { PolarAreaChartComponent } from './DemoPages/Charts/chartjs/examples/polar-area-chart/polar-area-chart.component';
import { BubbleChartComponent } from './DemoPages/Charts/chartjs/examples/bubble-chart/bubble-chart.component';
import { DynamicChartComponent } from './DemoPages/Charts/chartjs/examples/dynamic-chart/dynamic-chart.component';
import { DoughnutChartComponent } from './DemoPages/Charts/chartjs/examples/doughnut-chart/doughnut-chart.component';
import { PieChartComponent } from './DemoPages/Charts/chartjs/examples/pie-chart/pie-chart.component';
import { DataTablesModule } from 'angular-datatables';
import { ToastrModule } from 'ngx-toastr';

import { ProjectTypeModule } from './masters/project-type/project-type.module';
import { StateModule } from './masters/state/state.module';
import { AreaModule } from './masters/area/area.module';
import { FirmTypeModule } from './masters/firm-type/firm-type.module';
import { TermsAndConditionCategoryModule } from './masters/terms-and-condition-category/terms-and-condition-category.module';

import { DepartmentModule } from './masters/department/department.module';
import { OccupationModule } from './masters/occupation/occupation.module';
import { DesignationModule } from './masters/designation/designation.module';
import { CompanyModule } from './masters/company/company.module';
import { ServicesModule } from './masters/services/services.module';
import { ProjectScopeModule } from './masters/project-scope/project-scope.module';
import { LeaveTypeModule } from './masters/leave-type/leave-type.module';
import { HolidayCalenderModule } from './masters/holiday-calender/holiday-calender.module';
import { TaskTypeModule } from './masters/task-type/task-type.module';
import { TaskCreationModule } from './masters/task-creation/task-creation.module';
import { TermsConditionModule } from './masters/terms-condition/terms-condition.module';

import { CustomerModule } from './masters/customer/customer.module';
import { DeliveryscheduleModule } from './masters/deliveryschedule/deliveryschedule.module';
// import { ProjectTaskGroupModule } from './masters/projectTaskGroupMaster/projectTaskGroupMaster.module';
import { ProjectTaskModule } from './masters/projectTaskMaster/projectTaskMaster.module';
import { ProjectMappingModule } from './masters/projectMapping/projectMapping.module';
import { ProposalTemplatesTypeModule } from './masters/proposal-templates-type/proposal-templates-type.module';
import { ProposalTemplatesCreationModule } from './masters/proposal-templates-creation/proposal-templates-creation.module';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { ServiceModule } from './masters/service/service.module';
import { RateConfiguratorModule } from './masters/rate-configurator/rate-configurator.module';

import { FeasibiltityCriteriaModule } from './masters/feasibility_criteria/feasibiltity-criteria.module';
import { TopologyMasterModule } from './masters/topology_master/topology-master.module';
import { UomMasterModule } from './masters/uom_master/uom-master.module';
import { CityModule} from './masters/city/city.module';

import { ProposalModule } from './proposalMain/proposal/proposal.module';

import { OpportunityModule } from './opportunity-main/opportunity/opprtunity.module';
import { OpportunityFollowupModule } from './opportunity-main/opportunityFollowup/opportunityFollowup.module';
import { OpportunityNotInterestedModule } from './opportunity-main/opporunityNotInterested/opportunityNotInterested.module';

import { DashboardComponent } from './dashboard/dashboard.component';

import { EmployeeMasterModule } from './masters/employee_master/employee-master.module';
import { LeaveCreditModule } from './masters/leaveCredit/leave-credit.module';
import { ListModule } from './timesheet/list/list.module';
import { LoginComponent } from './login/login/login.component';
import { AuthGuard } from './authguard.guard';

import { AmazingTimePickerModule } from 'amazing-time-picker';

import { ProjectCreationMainModule } from './projectCreationMain/project-creation-main.module';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';

import { SalaryProcessModule } from './salayProcessMain/salaryProcess/salary-process.module';
import { FinancialyearyasterModule } from './masters/financialyearyaster/financialyearyaster.module';
import { SalaryslabmasterModule } from './masters/salaryslabmaster/salaryslabmaster.module';
import { SalarygrademasterModule } from './masters/salarygrademaster/salarygrademaster.module';
import { SalarycomponentModule } from './masters/salarycomponent/salarycomponent.module';
import { LateinearlyoutmasterModule } from './masters/lateinearlyoutmaster/lateinearlyoutmaster.module';
import { SalarygradeModule } from './masters/salarygrade/salarygrade.module';

import { DashboardmasterModule } from './masters/dashboardmaster/dashboardMaster.module';
import { DashboardmenumanagementModule } from './masters/dashboardmenumanagement/dashboardmenumanagement.module';
import { OthersalarycomponentModule } from './masters/othersalarycomponent/othersalarycomponent.module';
import { ResourcemovementModule } from './projectCreationMain/resourcemovement/resourcemovement.module';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MomentDateFormatter } from './momentdate';
import { NgbDateParserFormatter, NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import {DayPilotModule} from "daypilot-pro-angular";
import { DragScrollModule } from "cdk-drag-scroll";
import { TaxMasterModule } from './masters/tax_master/tax-master.module';
import { CompanyBankDetailsModule } from './masters/Company_Bank_Details/company-bank-details.module';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

@NgModule({
  declarations: [

    // LAYOUT

    AppComponent,
    BaseLayoutComponent,
    PagesLayoutComponent,
    PageTitleComponent,

    // HEADER

    HeaderComponent,
    SearchBoxComponent,
    UserBoxComponent,

    // SIDEBAR

    SidebarComponent,
    LogoComponent,

    // FOOTER

    FooterComponent,

    // DEMO PAGES

    // Dashboards

    AnalyticsComponent,

    // User Pages

    ForgotPasswordBoxedComponent,
    LoginBoxedComponent,
    RegisterBoxedComponent,

    // Elements

    StandardComponent,
    IconsComponent,
    DropdownsComponent,
    CardsComponent,
    ListGroupsComponent,
    TimelineComponent,

    // Components

    AccordionsComponent,
    TabsComponent,
    CarouselComponent,
    ModalsComponent,
    ProgressBarComponent,
    PaginationComponent,
    TooltipsPopoversComponent,

    // Tables

    RegularComponent,
    TablesMainComponent,

    // Dashboard Boxes

    ChartBoxes3Component,

    // Form Elements

    ControlsComponent,
    LayoutComponent,

    // CHARTS

    ChartjsComponent,

    // Chart.js Examples

    LineChartComponent,
    BarChartComponent,
    DoughnutChartComponent,
    RadarChartComponent,
    PieChartComponent,
    PolarAreaChartComponent,
    DynamicChartComponent,
    BubbleChartComponent,
    ScatterChartComponent,

    //New COompnet by DIPL
    LoginComponent,
    DashboardComponent,
    // CreateComponent,
    // ListComponent

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NgReduxModule,
    CommonModule,
    LoadingBarRouterModule,
    Ng4LoadingSpinnerModule.forRoot() ,
       // Angular Bootstrap Components
    TaxMasterModule,CompanyBankDetailsModule,
    PerfectScrollbarModule,
    NgbModule,
    AngularFontAwesomeModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    DataTablesModule,
    // Charts
    ChartsModule,
    ProjectTypeModule,
    ToastrModule,
    ToastrModule.forRoot(), // ToastrModule added
    ToastrModule.forRoot({ closeButton: false }),
    StateModule,
    AreaModule,
    FirmTypeModule,
    TermsAndConditionCategoryModule,
    OccupationModule,
    DepartmentModule,
    DesignationModule,
    CompanyModule,
    ServicesModule,
    ProjectScopeModule,
    LeaveTypeModule,
    HolidayCalenderModule,
    TaskTypeModule,
    TaskCreationModule,
    TermsConditionModule,
    CustomerModule,
    DeliveryscheduleModule,
    // ProjectTaskGroupModule,
    ProjectTaskModule,
    ProjectMappingModule,
    ProposalTemplatesTypeModule,
    ProposalTemplatesCreationModule,
    ServiceModule,
    RateConfiguratorModule,
    CityModule,

    FeasibiltityCriteriaModule,
    TopologyMasterModule,
    UomMasterModule,

    ProposalModule,

    OpportunityModule,
    OpportunityFollowupModule,
    OpportunityNotInterestedModule,

    SalaryProcessModule,

    EmployeeMasterModule,

    ListModule,
    LeaveCreditModule,

    AmazingTimePickerModule,

    ProjectCreationMainModule,
    FinancialyearyasterModule,
    SalarygrademasterModule,
    SalaryslabmasterModule,
    SalarygradeModule,
    SalarycomponentModule,
    LateinearlyoutmasterModule,
    DashboardmasterModule,
    DashboardmenumanagementModule,
    OthersalarycomponentModule,
    ResourcemovementModule,
    DragDropModule,
    BsDatepickerModule.forRoot(),
    DayPilotModule,DragScrollModule
  ],
  providers: [
    {
      provide:
        PERFECT_SCROLLBAR_CONFIG,
      // DROPZONE_CONFIG,
      useValue:
        DEFAULT_PERFECT_SCROLLBAR_CONFIG,
      // DEFAULT_DROPZONE_CONFIG,
    },
    { provide: NgbDateParserFormatter, useClass: MomentDateFormatter },

    AuthGuard,
    ConfigActions,
  ],
  bootstrap: [AppComponent],
  entryComponents: [

  ]
})

export class AppModule {
  constructor(private ngRedux: NgRedux<ArchitectUIState>,
    private devTool: DevToolsExtension) {

    this.ngRedux.configureStore(
      rootReducer,
      {} as ArchitectUIState,
      [],
      [devTool.isEnabled() ? devTool.enhancer() : f => f]
    );

  }
}
