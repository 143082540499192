import { Component, OnInit,ViewChild } from '@angular/core';
import { ProposalModuleService } from 'src/app/services/proposal.service';
import { DataTableDirective, DataTablesModule } from 'angular-datatables';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { ToastrService } from "ngx-toastr";
import { Router,ActivatedRoute } from '@angular/router';
import { CreateComponent } from '../create/create.component';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.sass']
})
export class ListComponent implements OnInit {
  dtTrigger: Subject<any> = new Subject();
  @ViewChild(DataTableDirective)
  datatableElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  proposalList :any[];

  constructor(
    private modalService: NgbModal,
    public ProposalModuleService : ProposalModuleService,
    private toastr: ToastrService,
    private router: Router,private spinnerService: Ng4LoadingSpinnerService
    ) { }


  ngOnInit() {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      "columns": [
        { "orderable": false },
        { "orderable": true },
        { "orderable": true },
        { "orderable": true },
        { "orderable": true },
        { "orderable": true },
        { "orderable": true },
        { "orderable": true },
      ]
    };

    this.ProposalModuleService.getProposalList().subscribe((e: any) => {
      this.proposalList = e.data;
      this.dtTrigger.next();
    });
  }

  ngAfterViewInit(): void {
    this.dtTrigger.subscribe(() => {
      this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.columns().every(function (i) {
          const that = this;

          $('input', this.header()).on('click', function (e) {
            e.stopPropagation();
          });


          $('input', this.header()).on('keyup change', function () {
            if (that.search() !== this['value']) {
              if (i == 0) {
                var val = this['value'];
                that
                  .search(val ? '^' + this['value'] + '$' : val, true, false)
                  .draw();
              } else {
                that
                  .search(this['value'])
                  .draw();
              }
            }
          });
        });
      });
    });
  }

  onAdd(){
    this.spinnerService.show();
    let action="Add";
    this.router.navigate(["proposal/proposal/proposalcreate",action]);
  }

  onEdit(id) {
    this.spinnerService.show();
    let getid = id;
    let action = "Edit";
    this.router.navigate(["proposal/proposal/proposalcreate",getid,action]);
  }

  onView(id){
    this.spinnerService.show();
    let getid = id;
    this.router.navigate(["/proposal/proposal/proposalView",getid]);

  }
}
