import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from "@angular/router";
import { LocationStrategy, HashLocationStrategy } from "@angular/common";
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DataTablesModule } from 'angular-datatables';
import {MarkAsteriskDirectiveModule} from '../../directives/mark-asterisk.directive';
import { SalarygrademastercreateComponent } from './create/salarygrademastercreate/salarygrademastercreate.component';
import { SalarygrademasterlistComponent } from './list/salarygrademasterlist/salarygrademasterlist.component';
import { MastersService } from 'src/app/services/masters.service';

const routes: Routes = [
  {
    path: "salarygrademasterlist",
    component: SalarygrademasterlistComponent
  },
  {
    path: "salarygrademastercreate",
    component: SalarygrademastercreateComponent
  }
];

@NgModule({
  declarations: [SalarygrademastercreateComponent, SalarygrademasterlistComponent],

  imports: [
    RouterModule.forChild(routes),
    CommonModule,
    ReactiveFormsModule,
    DataTablesModule,
    MarkAsteriskDirectiveModule
  ],
  entryComponents: [
    SalarygrademastercreateComponent
  ],
 // providers : [MastersService]
})
export class SalarygrademasterModule { }
