import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RouterModule, Routes } from "@angular/router";
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DataTablesModule } from 'angular-datatables';
import {MarkAsteriskDirectiveModule} from '../../directives/mark-asterisk.directive';
import { UomCreateComponent } from './uom-create/uom-create.component';
import { UomListComponent } from './uom-list/uom-list.component';

const routes: Routes = [
  
  {
    path: "uommasterlist",
    component: UomListComponent 
  },
  {
    path: "uommastercreate",
    component: UomCreateComponent
  }
];


@NgModule({
  declarations: [UomListComponent,UomCreateComponent],
  imports: [
    RouterModule.forChild(routes),
    CommonModule,
    ReactiveFormsModule,
    DataTablesModule,
    MarkAsteriskDirectiveModule
  ],
  entryComponents: [
    UomCreateComponent  
  ],
})

export class UomMasterModule { }
