import { Component, OnInit } from '@angular/core';
import { Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from "ngx-toastr";
import { MastersService } from 'src/app/services/masters.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';

@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.sass']
})
export class CreateComponent implements OnInit {

  @Input() id;
  @Input() action;
  projectTypeList = [];
  stateList = [];
  tableName: string;

  taskCreationFrm: FormGroup;
  poupuTitle: string;
  submitted: boolean;

  constructor(
    private _formBuilder: FormBuilder,
    private toastr: ToastrService,
    private router: Router,
    public MasterService: MastersService,
    public activeModal: NgbActiveModal,private spinnerService: Ng4LoadingSpinnerService) { }

  ngOnInit() {
    //console.log(this.id);
    this.action = this.action;

    const token_key = localStorage.getItem("token");

    if (this.action === "Edit") {
      this.poupuTitle = "Edit Task Type Master";
      this.MasterService.getTaskCreationbyId(this.id).then(data => {
        this.taskCreationFrm.patchValue(data.data);

        this.tableName = "task_type_master";
        this.MasterService.getActiveDetailsListForUpdate(data.data.taskTypeId, this.tableName).subscribe((e: any) => {
          this.projectTypeList = e.data;
        });

      });
    } else if (this.action === "Add") {
      this.poupuTitle = "Add Task Type Master";
      this.tableName = "task_type_master";
      this.MasterService.getActiveDetailsListForCreate(this.tableName).subscribe((e: any) => {
        this.projectTypeList = e.data;
      });
    }
    this.taskCreationFrm = this.createCountryForm();
  }

  createCountryForm(): FormGroup {
    return (this.taskCreationFrm = this._formBuilder.group({
      //taskTypeId: ["", Validators.required],
      taskDescription: ["", Validators.required],
    }));
  }

  get f() {
    return this.taskCreationFrm.controls;
  }

  onSubmit(regfrm: any) {
    this.spinnerService.show();
    this.submitted = true;
    if (this.taskCreationFrm.invalid) {
      this.toastr.error("Error !! Record not created");
      return;
    }

    this.MasterService.createTaskCreation(0, this.f.taskDescription.value).subscribe((response: any) => {
      console.log("response", response.msg);
      if (response.code === 1) {
        this.activeModal.close();
        this.toastr.success("Success !! " + response.msg);

        this.router.navigateByUrl("/app/refresh", { skipLocationChange: true }).then(() => {
          this.router.navigate(["/masters/taskcreation/taskcreationlist"]);
        });
        return true;
      } else if (response.code == 0) {
        this.toastr.error(response.msg, "Error!");
        return false;
      } else {
        this.toastr.error("Error !! Record not created");
        return false;
      }
    },
      error => {
        console.log("failure", error);
        return false;
      }
    );

    // this.regfrm.reset();
  }

  UpdateTaskType() {
    this.spinnerService.show();
    this.submitted = true;
    if (this.taskCreationFrm.invalid) {
      this.toastr.error("Error !! Record not created");
      return;
    }
    //this.options.value.token = localStorage.getItem("token");
    // this.options.value.updated_by_id = localStorage.getItem("created_by_id");
    this.taskCreationFrm.value.id = this.id;
    //alert('update funtion');
    this.MasterService.updateTaskCreation(this.id,'0',this.f.taskDescription.value).subscribe((response: any) => {
      if (response.code == 1) {
        //alert("update")
        this.activeModal.close();
        this.toastr.success("Success !", response.msg);
        this.router
          .navigateByUrl("/app/refresh", { skipLocationChange: true })
          .then(() => {
            this.router.navigate(["/masters/taskcreation/taskcreationlist"]);
          });
        return true;
      } else if (response.code == 0) {
        this.toastr.error(response.msg, "Error!");
        return false;
      } else {
        this.toastr.error("Record not created!", "Error!");
        return false;
      }
    },
      error => {
        this.toastr.warning("Internal Server Error !!", "Error!");
        return false;
      }
    );
  }

}
