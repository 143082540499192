import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from "@angular/router";
import { LocationStrategy, HashLocationStrategy } from "@angular/common";
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DataTablesModule } from 'angular-datatables';
import {MarkAsteriskDirectiveModule} from '../../directives/mark-asterisk.directive';
import { OthersalarycomponentlistComponent } from './list/othersalarycomponentlist/othersalarycomponentlist.component';
import { OthersalarycomponentcreateComponent } from './create/othersalarycomponentcreate/othersalarycomponentcreate.component';
import { MastersService } from 'src/app/services/masters.service';

const routes: Routes = [
  {
    path: "othersalarycomponentlist",
    component: OthersalarycomponentlistComponent
  },
  {
    path: "othersalarycomponentcreate",
    component: OthersalarycomponentcreateComponent
  }
];

@NgModule({
  declarations: [OthersalarycomponentcreateComponent, OthersalarycomponentlistComponent],

  imports: [
    RouterModule.forChild(routes),
    CommonModule,
    ReactiveFormsModule,
    DataTablesModule,
    MarkAsteriskDirectiveModule
  ],
  entryComponents: [
    OthersalarycomponentcreateComponent
  ],
  providers : [MastersService]
})
export class OthersalarycomponentModule { }
