import { NgModule } from '@angular/core';
import { RouterModule, Routes } from "@angular/router";
import { CommonModule } from '@angular/common';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DataTablesModule } from 'angular-datatables';
import { ListComponent } from './list/list.component';
import { CreateComponent } from './create/create.component';
import { ViewComponent } from './view/view.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {MarkAsteriskDirectiveModule} from '../../directives/mark-asterisk.directive';
import { ViewRevisionComponent } from './view-revision/view-revision.component';

const routes: Routes = [

  {
    path: "proposallist",
    component: ListComponent
  },
  {
    path: 'proposalcreate/:id/:action',
    component: CreateComponent
  },
  {
    path: 'proposalcreate/:action',
    component: CreateComponent
  },
  {
    path: "proposalView/:id",
    component: ViewComponent
  },
  {
    path: "proposalRevisionView/:id",
    component: ViewRevisionComponent
  },
];

@NgModule({
  declarations: [CreateComponent, ListComponent,ViewComponent, ViewRevisionComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    CommonModule,
    ReactiveFormsModule,
    DataTablesModule,
    FormsModule,
    NgbModule,
    MarkAsteriskDirectiveModule
  ],
  entryComponents: [
    CreateComponent
  ]
})
export class ProposalModule { }
