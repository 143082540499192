import { Component, OnInit, HostListener } from '@angular/core';
import { OpportunityModuleService } from 'src/app/services/opportunity-module.service';
import { ProposalModuleService } from 'src/app/services/proposal.service';
import { Router, ActivatedRoute } from '@angular/router';
import { projectScheduleService } from 'src/app/services/projectSchedule.service';
import { LeaveApplyMainServiceService } from '../services/leave-apply-main-service.service';
import { MastersService } from '../services/masters.service';
import { NgbProgressbarConfig, NgbPopoverConfig } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { roLocale } from 'ngx-bootstrap';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.sass']
})
export class DashboardComponent implements OnInit {
  userMasterId: any;
  projectProgressBarStatus: any[];
  opportunityFollowupList: any[];
  opportunityFollowupNotInterestedList: any[];
  proposalFollowupList: any[];
  proposalAppraovalList: any[];
  proposalRevisionApprovalList: any[];
  proposalFollowupNotInterestedList: any[];

  projectTaskAssignmentList: any[];
  pendingForApprovelProjectList: any[];

  leaveApprovalList: any[];
  burningProjectList: any[];
  loginId: any;

  opportunityFollowups: boolean;
  notInterestedOpportunities: boolean;
  proposalFollowups: boolean;
  notInterestedProposal: boolean;
  proposalApproval: boolean;
  proposalRevisionApproval: boolean;
  projectApproval: boolean;
  projectTaskAssignment: boolean;
  leaveApproval: boolean;
  burningProject: boolean;
  projectIds: string;
  progressBarForm: FormGroup;
  isCalanderShow: boolean;

  constructor(
    public OpportunityService: OpportunityModuleService,
    public ProposalModuleService: ProposalModuleService,
    public projectScheduleService: projectScheduleService,
    private router: Router,
    public leaveapplyService: LeaveApplyMainServiceService,
    public MastersService: MastersService,
    config: NgbProgressbarConfig,
    private _formBuilder: FormBuilder,
  ) {

    // customize default values of progress bars used by this component tree
    config.max = 100;
    config.striped = true;
    config.animated = true;
    config.type = 'success';
    config.height = '20px';
  }

  ngOnInit() {
    this.opportunityFollowups = false;
    this.notInterestedOpportunities = false;
    this.proposalFollowups = false;
    this.notInterestedProposal = false;
    this.proposalApproval = false;
    this.proposalRevisionApproval = false;
    this.projectApproval = false;
    this.projectTaskAssignment = false;
    this.leaveApproval = false;
    this.burningProject = false;

    this.loginId = localStorage.getItem("userMasterId");
    this.userMasterId = localStorage.getItem("userMasterId");


    this.MastersService.dashboardMenuAccessDetails(this.userMasterId).subscribe((e: any) => {
      if (e.code == 1) {
        e.data.forEach((x, index) => {
          //alert(x.className);
          if (this.opportunityFollowups == x.className) {
            this.opportunityFollowups == true;

          } else if (x.className == 'notInterestedOpportunities') {
            this.notInterestedOpportunities = true;
            this.OpportunityService.getOpportunityFolloupNotInterestedList().subscribe((e: any) => {
              this.opportunityFollowupNotInterestedList = e.data;
              if (this.opportunityFollowupNotInterestedList.length != 0) {
                this.notInterestedOpportunities = true;
              } else {
                // this.notInterestedOpportunities == false;
              }
            });

          } else if ('proposalFollowups' == x.className) {
            this.proposalFollowups == true;

          } else if ('notInterestedProposal' == x.className) {
            this.notInterestedProposal = true;
            this.ProposalModuleService.getProsalFolloupNotInterestedList().subscribe((e: any) => {
              this.proposalFollowupNotInterestedList = e.data;
              if (this.proposalFollowupNotInterestedList.length != 0) {
                this.notInterestedProposal = true;
              } else {
                // this.notInterestedProposal == false;
              }
            });

          } else if ('proposalApproval' == x.className) {
            this.proposalApproval = true;
            this.ProposalModuleService.proposalAppraovalList().subscribe((e: any) => {
              this.proposalAppraovalList = e.data;
              if (this.proposalAppraovalList.length != 0) {
                this.proposalApproval = true;
              } else {
                //this.proposalApproval == false;
              }
            });

          } else if ('proposalRevisionApproval' == x.className) {
            this.proposalRevisionApproval = true;
            this.ProposalModuleService.proposalRevisionApprovalList().subscribe((e: any) => {
              this.proposalRevisionApprovalList = e.data;
              if (this.proposalRevisionApprovalList.length != 0) {
                this.proposalRevisionApproval = true;
              } else {
                // this.proposalRevisionApproval == false;
              }
            });

          } else if ('projectApproval' == x.className) {
            this.projectApproval = true;
            this.projectScheduleService.getPendingForApprovelProjectList().subscribe((e: any) => {
              this.pendingForApprovelProjectList = e.data;
              if (this.pendingForApprovelProjectList.length != 0) {
                this.projectApproval = true;
              } else {
                // this.projectApproval == false;
              }
            });
          } else if ('projectTaskAssignment' == x.className) {
            this.projectTaskAssignment = true;
            this.projectScheduleService.getProjectListForSchedule().subscribe((e: any) => {
              this.projectTaskAssignmentList = e.data;
              if (this.projectTaskAssignmentList.length != 0) {
                this.projectTaskAssignment = true;
              } else {
                // this.projectTaskAssignment == false;
              }
            });

          } else if ('burningProject' == x.className) {
            this.burningProject = true;
            this.projectScheduleService.burningProjectList().subscribe((e: any) => {
              this.burningProjectList = e.data;
              if (this.burningProjectList.length != 0) {
                this.burningProject = true;
              } else {
                // this.burningProject == false;
              }
            });
          }

        });
      }
    });

    this.OpportunityService.getProjectProgressBarStatusForDashboard().subscribe((e: any) => {
      this.projectProgressBarStatus = e.data;
    });

    this.opportunityFollowups = true;
    this.OpportunityService.getOpportunityFolloupListForDashboard(this.userMasterId).subscribe((e: any) => {
      this.opportunityFollowupList = e.data;
      if (this.opportunityFollowupList.length != 0) {
        this.opportunityFollowups = true;
      } else {
        // this.opportunityFollowups == false;
      }
    });

    this.proposalFollowups = true;
    this.ProposalModuleService.getProposalFolloupListForDashboard(this.userMasterId).subscribe((e: any) => {
      this.proposalFollowupList = e.data;
      if (this.proposalFollowupList.length != 0) {
        this.proposalFollowups = true;
      } else {
        // this.proposalFollowups == false;
      }
    });

    this.leaveApproval = true;
    this.leaveapplyService.getLeaveList(this.loginId).subscribe((e: any) => {
      this.leaveApprovalList = e.data;
      if (this.leaveApprovalList.length != 0) {
        this.leaveApproval = true;
      } else {
        // this.leaveApproval == false;
      }
    });

  }

  onCreate(id: any) {
    let action = "Add";
    this.router.navigate(["opportinityModule/opportunityFollowup/opportunityFollowupCreate", id, action]);
  }

  proposalCreate(id: any) {
    let action = "Add";
    this.router.navigate(["proposal/proposalFollowup/proposalFollowupCreate", id, action]);
  }

  proposalApprovalAction(id: any) {
    let action = "Approval";
    this.router.navigate(["proposal/proposal/proposalcreate", id, action]);
  }

  proposalRevisionApprovalAction(id: any) {
    let action = "Approval";
    this.router.navigate(["proposal/proposalRevisionApproval/ProposalRevisionApproval", id, action]);
  }



  getstatus(status: any) {
    if (status == 1) {
      return "Pending";
    } else if (status == 2) {
      return "Partial Interested";
    } else if (status == 3) {
      return "Interested";
    } else if (status == 4) {
      return "Not Interested";
    }
    return "NA";
  }

  getProjectStatus(status: any) {
    if (status == 1) {
      return "Pending For Approval";
    } else if (status == 2) {
      return "Pending For Task Assignment";
    }
    return "NA";
  }

  projectSchedule(id: any) {
    let action = "Add";
    this.router.navigate(["project/projectSchedule/projectScheduleCreate", id, action]);
  }

  projectApproveal(id: any) {
    let getid = id;
    let action = "Approve";
    this.router.navigate(["project/project/projectCreate", getid, action]);
  }

  onClick(id: any) {
    let action = "Edit";
    this.router.navigate(["leaveapplymain/leaveapproval/leaveapprovalCreate", id, action]);
  }
  onBurningProjectClick(id: any) {
    let action = "View";
    this.router.navigate(["project/burningProject/burningProjectCreate", id, action]);
  }
  /////////////////////////////////////////////////////////////////////////////
  progressBar(event: any, projectId) {
    // this.projectScheduleService.getProjectMilestoneCalander(projectId).subscribe((response: any) => {     
    //     this.router.navigate(["/project/resourcemovement/ResourcemovementcreateComponent", projectId]);
    //     this.isCalanderShow = true;      
    // })
    this.router.navigateByUrl("/app/refresh", { skipLocationChange: true }).then(() => {
      this.router.navigate(["/project/resourcemovement/ResourcemovementcreateComponent", projectId]);
    });

  }

  // @HostListener('document:mouseover', ['$event'])
  // mouseover(event) {
  //     if(event.target.matches('.editor-div')) {
  //         alert('hover to editor div')
  //     }
  // }
}
