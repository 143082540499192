import {Component} from '@angular/core';
import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'ngbd-datepicker-popup',
  templateUrl: './opportunity-create.component.html',
})
export class NgbdDatepickerPopup {
  model: NgbDateStruct;
}
