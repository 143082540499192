import { Component, OnInit, ViewChild } from '@angular/core';
import { Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbDateStruct, NgbCalendar, NgbDatepicker } from '@ng-bootstrap/ng-bootstrap';

import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from "ngx-toastr";
import { MastersService } from 'src/app/services/masters.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';

export class ProjectTypeC {
  ptype: any;
  status: any;
}

@Component({
  selector: 'app-servicecreate',
  templateUrl: './servicecreate.component.html',
  styleUrls: ['./servicecreate.component.sass']
})
export class ServicecreateComponent implements OnInit {
  model1 = {
    left: true,
    middle: false,
  };

  model: NgbDateStruct;
  date: { year: number, month: number };
  @ViewChild('dp') dp: NgbDatepicker;
  time = { hour: 13, minute: 30 };
  time2 = { hour: 13, minute: 30 };


  isActive = 1;
  @Input() id;
  @Input() action;
  submitted: boolean;
  regfrm: FormGroup;
  projectTypeC: ProjectTypeC;
  projecttype: string;
  name: String = "";
  poupuTitle: string;
  constructor(
    private _formBuilder: FormBuilder,
    private toastr: ToastrService,
    private router: Router, private calendar: NgbCalendar,
    public ProjectTypeService: MastersService,
    public activeModal: NgbActiveModal,private spinnerService: Ng4LoadingSpinnerService) { }

  ngOnInit() {
    console.log(this.id)
    this.action = this.action;
    let CountryId = this.id;
    const token_key = localStorage.getItem("token");


    if (this.action === "Edit") {
      this.poupuTitle = "Edit Service";
      this.ProjectTypeService.GetProjectTypebyID(this.id).then(data => {
        console.log("datapatch", data.data)
        this.regfrm.patchValue(data.data);
      });
    } else if (this.action === "Add") {
      this.poupuTitle = "Add Service";
    }
    this.regfrm = this.createCountryForm();
  }

  createCountryForm(): FormGroup {
    return (this.regfrm = this._formBuilder.group({
      dateservi: ["", Validators.required],
      sTime: ["", Validators.required],
      eTime: ["", Validators.required],
      repeatType: ["", Validators.required],
      route2: ["", Validators.required],
      route1: ["", Validators.required],
      latitute: ["", Validators.required],
      longitute: ["", Validators.required],
      pincode: ["", Validators.required],
      city: ["", Validators.required],
      country: ["", Validators.required],
      billingadd2: ["", Validators.required],
      billingadd1: ["", Validators.required],
      note: ["", Validators.required],
    }));
  }
  selectToday() {
    this.model = this.calendar.getToday();
  }

  setCurrent() {
    //Current Date
    this.dp.navigateTo()
  }
  setDate() {
    //Set specific date
    this.dp.navigateTo({ year: 2013, month: 2 });
  }

  navigateEvent(event) {
    this.date = event.next;
  }
  get f() {
    return this.regfrm.controls;
  }
  onSubmit(regfrm: any) {
    this.spinnerService.show();
    console.log("this.f.projectType.value", this.regfrm.value)
    this.submitted = true;
    if (this.regfrm.invalid) {
      this.toastr.error("Error !! Record not created");
      return;
    }
    // this.ProjectTypeService.CreateProjectType(this.f.projectType.value).subscribe(
    //   (response: any) => {
    //     console.log("response", response.msg);
    //     this.activeModal.close();
    //     if (response.msg === "Record saved") {
    //       alert("Saved")
    //       this.activeModal.close();
    //       this.toastr.success("Success !!  Record created");
    //       this.router
    //         .navigateByUrl("/app/refresh", { skipLocationChange: true })
    //         .then(() => {
    //           this.router.navigate(["/masters/projecttype/projectlist"]);
    //         });
    //       return true;
    //     } else if (response.msg === "Record already exist") {
    //       this.toastr.error("Record already exist!", "Error!");
    //       return false;
    //     } else response.msg === "Record not saved";
    //     {
    //       this.toastr.error("Error !! Record not created");
    //       return false;
    //     }
    //   },
    //   error => {
    //     console.log("failure", error);
    //     return false;
    //   }
    // );

    // this.regfrm.reset();
  }
  UpdateProjectType() {
    this.spinnerService.show();
    this.submitted = true;
    //this.options.value.token = localStorage.getItem("token");
    // this.options.value.updated_by_id = localStorage.getItem("created_by_id");
    this.regfrm.value.id = this.id;
    this.ProjectTypeService.UpdateProjectType(this.id, this.f.projectType.value).subscribe(
      (response: any) => {
        if (response.msg === "Record updated") {
          alert("update")
          this.activeModal.close();
          this.toastr.success("Record Updated!", "Success!");
          this.router
            .navigateByUrl("/app/refresh", { skipLocationChange: true })
            .then(() => {
              this.router.navigate(["/masters/projecttype/projectlist"]);
            });
          return true;
        } else response.msg === "Record not saved";
        {
          this.toastr.error("Record not created!", "Error!");
          return false;
        }
      },
      error => {
        this.toastr.warning("Internal Server Error !!", "Error!");
        return false;
      }
    );
  }

}
