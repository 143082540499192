import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from "@angular/router";
//import { LocationStrategy, HashLocationStrategy } from "@angular/common";
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DataTablesModule } from 'angular-datatables';
import { CreateComponent } from './create/create.component';
import { ListComponent } from './list/list.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbdDatepicker } from './create/datepicker';
import { MastersService } from 'src/app/services/masters.service';
import { HttpClientModule} from '@angular/common/http';

const routes: Routes = [
  {
    path: "financialyearyastercreate",
    component: CreateComponent
  },
  {
    path: "financialyearyasterlist",
    component: ListComponent
  }
];

@NgModule({
  declarations: [CreateComponent, ListComponent, NgbdDatepicker],

  imports: [
    RouterModule.forChild(routes),
    CommonModule,
    ReactiveFormsModule,
    DataTablesModule,
    NgbModule,
    HttpClientModule
  ],
  entryComponents: [
    CreateComponent
  ],
  providers : [MastersService],
  exports: [NgbdDatepicker],
  bootstrap: [NgbdDatepicker]
})
export class FinancialyearyasterModule { }
