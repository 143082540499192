import { Component, OnInit } from '@angular/core';
import { Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from "ngx-toastr";
import { MastersService } from 'src/app/services/masters.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';

@Component({
  selector: 'app-area-create',
  templateUrl: './area-create.component.html',
  styleUrls: ['./area-create.component.sass']
})
export class AreaCreateComponent implements OnInit {
  @Input() id;
  @Input() action;
  stateList = [];
  cityList = [];
  areafrm: FormGroup;
  poupuTitle: string;
  submitted: boolean;
  tableName: string;

  constructor(
    private _formBuilder: FormBuilder,
    private toastr: ToastrService,
    private router: Router,
    public MasterService: MastersService,
    public activeModal: NgbActiveModal,
    private spinnerService: Ng4LoadingSpinnerService
    ) { }

  ngOnInit() {
    this.action = this.action;
    const token_key = localStorage.getItem("token");

    if (this.action === "Edit") {

      this.poupuTitle = "Edit Area";
      this.MasterService.getAreaDetails(this.id).then(data => {
        //this.changeState(data.data[0]['stateId']);


        this.tableName = "city_master";
        this.MasterService.getActiveDetailsListForUpdate(data.data[0].stateId, this.tableName).subscribe((e: any) => {
          this.cityList = e.data;
        });
        this.tableName = "state_master";
        this.MasterService.getActiveDetailsListForUpdate(data.data[0].stateId, this.tableName).subscribe((e: any) => {
          this.stateList = e.data;
        });

        this.areafrm.patchValue(data.data[0]);
      });

    } else if (this.action === "Add") {
      this.poupuTitle = "Add Area";
      this.tableName = "state_master";
      this.MasterService.getActiveDetailsListForCreate(this.tableName).subscribe((e: any) => {
        this.stateList = e.data;
      });
    }
    this.areafrm = this.createCountryForm();
  }

  createCountryForm(): FormGroup {
    return (this.areafrm = this._formBuilder.group({
      stateId: ["", Validators.required],
      areaName: ["", Validators.required],
      pincode: ["", Validators.required],
      cityId: ["", Validators.required]
    }));
  }

  get f() {
    return this.areafrm.controls;
  }

  onSubmit(regfrm: any) {
    this.spinnerService.show();
    this.submitted = true;
    if (this.areafrm.invalid) {
      this.toastr.error("Error !! Record not created");
      return;
    }
    this.MasterService.createArea(this.areafrm.value).subscribe((response: any) => {
      console.log("response", response.msg);

      if (response.code === 1) {
        this.activeModal.close();
        this.toastr.success("Success !! " + response.msg);

        this.router.navigateByUrl("/app/refresh", { skipLocationChange: true }).then(() => {
          this.router.navigate(["/masters/area/arealist"]);
        });
        return true;
      } else if (response.code == 0) {
        this.toastr.error(response.msg, "Error!");
        return false;
      } else {
        this.toastr.error("Error !! Record not created");
        return false;
      }
    },
      error => {
        console.log("failure", error);
        return false;
      }
    );

    // this.regfrm.reset();
  }

  UpdateProjectType() {
    this.spinnerService.show();
    this.submitted = true;
    this.areafrm.value.id = this.id;

    if (this.areafrm.invalid) {
      this.toastr.error("Error !! Record not created");
      return;
    }
    this.MasterService.updateArea(this.id, this.areafrm.value).subscribe(
      (response: any) => {
        if (response.code == 1) {
          this.activeModal.close();
          this.toastr.success("Success !", response.msg);
          this.router
            .navigateByUrl("/app/refresh", { skipLocationChange: true })
            .then(() => {
              this.router.navigate(["/masters/area/arealist"]);
            });
          return true;
        } else if (response.code == 0) {
          this.toastr.error(response.msg, "Error!");
          return false;
        } else {
          this.toastr.error("Record not created!", "Error!");
          return false;
        }
      },
      error => {
        this.toastr.warning("Internal Server Error !!", "Error!");
        return false;
      }
    );
  }

  changeState(stateId: any) {
    // alert('changeState');
    this.MasterService.getCityByStateId(stateId).subscribe((e: any) => {
      console.log(e.data);
      this.cityList = e.data;
    });
  }

  numberOnly(event): boolean {
    var txtId = event.target.value;
    const charCode = (event.which) ? event.which : event.keyCode;
    console.log(charCode);
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

}
