import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MarkAsteriskDirectiveModule} from '../../directives/mark-asterisk.directive';
import { RouterModule, Routes } from "@angular/router";
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DataTablesModule } from 'angular-datatables';
import { ListComponent } from './list/list.component';
import { CreateComponent } from './create/create.component';


const routes: Routes = [
  
  {
    path: "proposaltemplatescreationlist",
    component: ListComponent
  },
  {
    path: "proposaltemplatescreationcreate",
    component:CreateComponent
  }
];

@NgModule({
  declarations: [ListComponent,CreateComponent],
  imports: [
    RouterModule.forChild(routes),
    CommonModule,
    ReactiveFormsModule,
    DataTablesModule,
    MarkAsteriskDirectiveModule
    
  ],
  
  entryComponents: [
    CreateComponent  ]
})

export class ProposalTemplatesCreationModule { }
