import { Component, OnInit } from '@angular/core';
import { Input } from '@angular/core';

import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MastersService } from 'src/app/services/masters.service';

@Component({
  selector: 'app-customerview',
  templateUrl: './customerview.component.html',
  styleUrls: ['./customerview.component.sass']
})
export class CustomerviewComponent implements OnInit {
  poupuTitle: string;
  cusomerDetails:any[];
  contactDetails:any[];
  @Input() id;
  constructor(
    private router: Router,
    public MasterService: MastersService,
    public activeModal: NgbActiveModal
  ) { }

  ngOnInit() {
    this.poupuTitle = "Client Details";
    this.MasterService.GetCustomerById(this.id).then(data => {
      console.log("datapatch", data.data);
      this.cusomerDetails = data.data;
    });
    this.MasterService.GetCustomerContactDetails(this.id).then(data => {
      this.contactDetails = data.data;
    });
  }
  

}
