import { Component, OnInit, ViewChild } from '@angular/core';
import { Input } from '@angular/core';
import { FormArray,FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from "ngx-toastr";
import { MastersService } from 'src/app/services/masters.service';
import { NgbDateStruct, NgbCalendar, NgbDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';

@Component({
  selector: 'app-customercreate',
  templateUrl: './customercreate.component.html',
  styleUrls: ['./customercreate.component.sass']
})
export class CustomercreateComponent implements OnInit {
  @Input() id;
  @Input() action;
  myDateValue: Date;
  model: NgbDateStruct;
  companyList = [];
  designationList = [];
  departmentList = [];
  stateList = [];
  contactDetails = [];
  cityList = [];
  areaList = [];
  customerForm: FormGroup;
  poupuTitle: string;
  submitted: boolean;
  CustomerContactDetailsId:string;
  date: { year: number, month: number };
  @ViewChild('dp') dp: NgbDatepicker;
  time = { hour: 13, minute: 30 };
  time2 = { hour: 13, minute: 30 };
  tableName: string;
  newClient:string;
  firmList = [];

  constructor(
    private modalService: NgbModal,
    private _formBuilder: FormBuilder,
    private toastr: ToastrService,
    private router: Router,
    public MasterService: MastersService,
    public activeModal: NgbActiveModal,
    private calendar: NgbCalendar,private spinnerService: Ng4LoadingSpinnerService
  ) { }

  ngOnInit() {
    this.action = this.action;
    this.myDateValue = new Date();
    const token_key = localStorage.getItem("token");


    this.MasterService.getCompanyList().subscribe((e: any) => {
      console.log(e.data);
      this.companyList = e.data;
    });

    this.MasterService.getDesignation().subscribe((e: any) => {
      console.log(e.data);
      this.designationList = e.data;
    });

    this.MasterService.geDepartmentList().subscribe((e: any) => {
      console.log(e.data);
      this.departmentList = e.data;
    });

    this.MasterService.geFirmList().subscribe((e: any) => {
      this.firmList = e.data;
    });

    if (this.action === "Edit") {
      this.poupuTitle = "Edit Customer Details";
      this.MasterService.GetCustomerById(this.id).then(data => {
        this.changeState(data.data['stateId']);
        this.changeCity(data.data['cityId']);
        this.customerForm.patchValue(data.data);
        const dob = new Date(data.data.dob);
        this.customerForm.controls['dob'].setValue({ year: dob.getFullYear(), month: dob.getMonth() + 1, day: dob.getDate() });

        this.tableName = "state_master";
        this.MasterService.getActiveDetailsListForUpdate(data.data.stateId, this.tableName).subscribe((e: any) => {
          console.log(e.data);
          this.stateList = e.data;
        });
        this.CustomerContactDetailsId =  this.id;
        this.MasterService.GetCustomerContactDetails(this.CustomerContactDetailsId).then(data => {
          data.data.forEach((x,index )=> {
            const documentArray = this.customerForm.controls.contactDetails as FormArray;

            documentArray.push(
              this._formBuilder.group({
                id: x.id,
                name : x.name,
                number	: [x.number ,[Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
                designation: x.designation,
                emailId: [x.emailId, [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
              })
            );
            //this.getopportunityDetails(x.machine_type_id,index);
          });

        });

      });

    } else if (this.action === "Add") {
      this.tableName = "state_master";
      this.MasterService.getActiveDetailsListForCreate(this.tableName).subscribe((e: any) => {
        console.log(e.data);
        this.stateList = e.data;
      });
      this.MasterService.getNewClientCode().subscribe((e: any) => {
        this.newClient = e.data;
        this.customerForm.controls['clientCode'].setValue(this.newClient);
      });
      this.poupuTitle = "Add Customer";
    }
    this.customerForm = this.createCustomerForm();
  }

  selectToday() {
    this.model = this.calendar.getToday();
  }

  setCurrent() {
    //Current Date
    this.dp.navigateTo()
  }
  setDate() {
    //Set specific date
    this.dp.navigateTo({ year: 2013, month: 2 });
  }

  navigateEvent(event) {
    this.date = event.next;
  }

  createCustomerForm(): FormGroup {
    return (this.customerForm = this._formBuilder.group({
      firstName: ["", Validators.required],
      firmName: ["", Validators.required],
      // middleName: ["", Validators.required],
      // lastName: ["", Validators.required],
      dob: ["", Validators.required],
      clientCode :["", Validators.required],
      // designationName: ["", Validators.required],
      mobileNumber: ["", [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
      officialEmailId: ["", [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
      personalEmailId: ["", [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
      // companyName: ["", Validators.required],
      stateId: ["", Validators.required],
      cityId: ["", Validators.required],
      areaId: ["", Validators.required],
      officeAddress: ["", Validators.required],
      residentialAddress: ["", Validators.required],
      panNumber: ["", [Validators.pattern("[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}")]],
      gstinNumber: ["", [Validators.pattern("^([0][1-9]|[1-2][0-9]|[3][0-7])([a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9a-zA-Z]{1}[zZ]{1}[0-9a-zA-Z]{1})+$")]],
      contactDetails: this._formBuilder.array([])
    }));
  }

  addContactDetails(){
    const documentArray = this.customerForm.controls.contactDetails as FormArray;
    documentArray.push(
      this._formBuilder.group({
        id:[""],
        name : ["", Validators.required],
        number : ["", [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
        designation: ["", Validators.required],
        emailId: ["", [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
      })
    );
  }

  get f() {
    return this.customerForm.controls;
  }

  getControls() {
    return (this.customerForm.get("contactDetails") as FormArray).controls;
  }

  changeState(stateId: any) {
    this.MasterService.getCityByStateId(stateId).subscribe((e: any) => {
      console.log(e.data);
      this.cityList = e.data;
    });
  }
  changeCity(areaId) {
    this.MasterService.getareaByCityId(areaId).subscribe((e: any) => {
      console.log(e.data);
      this.areaList = e.data;
    });
  }
  onSubmit(regfrm: any) {
    this.spinnerService.show();
    this.submitted = true;
    if (this.customerForm.invalid) {
      this.toastr.error("Error !! Record not created");
      return;
    }
    this.MasterService.CreateCustomer(this.customerForm.value).subscribe((response: any) => {
      console.log("response", response.msg);
      this.activeModal.close();
      if (response.code === 1) {
        this.activeModal.close();
        this.toastr.success("Success !! " + response.msg);

        this.router.navigateByUrl("/app/refresh", { skipLocationChange: true }).then(() => {
          this.router.navigate(["/masters/customer/customerlist"]);
        });
        return true;
      } else if (response.code == 0) {
        this.toastr.error(response.msg, "Error!");
        return false;
      } else {
        this.toastr.error("Error !! Record not created");
        return false;
      }
    },
      error => {
        console.log("failure", error);
        return false;
      }
    );
  }

  UpdateCustomer() {
    this.spinnerService.show();
    this.submitted = true;
    //this.options.value.token = localStorage.getItem("token");
    // this.options.value.updated_by_id = localStorage.getItem("created_by_id");
    this.customerForm.value.id = this.id;
    if (this.customerForm.invalid) {
      this.toastr.error("Error !! Record not updated");
      return;
    }
    this.MasterService.updateCustomer(this.id, this.customerForm.value).subscribe((response: any) => {
      if (response.code == 1) {
        this.activeModal.close();
        this.toastr.success("Success !", response.msg);

        this.router
          .navigateByUrl("/app/refresh", { skipLocationChange: true })
          .then(() => {
            this.router.navigate(["/masters/customer/customerlist"]);
          });
        return true;
      } else if (response.code == 0) {
        this.toastr.error(response.msg, "Error!");
        return false;
      } else {
        this.toastr.error("Record not created!", "Error!");
        return false;
      }
    },
      error => {
        this.toastr.warning("Internal Server Error !!", "Error!");
        return false;
      }
    );
  }

  deleteContactDetails(index){
    const contactDetails = this.customerForm.controls.contactDetails as FormArray;
    let contactDetails_id = contactDetails.value[index].id;
    if(this.action === "Add"){
      (this.customerForm.get('contactDetails') as FormArray).removeAt(index);
      this.toastr.success("Success !! Row deleted successfully");
    }else{
      if(!(contactDetails_id)){
        (this.customerForm.get('contactDetails') as FormArray).removeAt(index);
        this.toastr.success("Success !! Row deleted successfully");
      }else{
        this.MasterService.deleteCustomerContactDetails(contactDetails_id).subscribe((response: any) => {
          if (response.code == 1) {
            this.activeModal.close();
            this.toastr.success("Success !", response.msg);

            this.router
            .navigateByUrl("/app/refresh", { skipLocationChange: true })
            .then(() => {
              this.router.navigate(["/masters/customer/customerlist"]);
            });
          return true;
            } else if (response.code == 0) {
              this.toastr.error(response.msg, "Error!");
              return false;
            } else {
              this.toastr.error("Record not created!", "Error!");
              return false;
            }
          },
          error => {
            this.toastr.warning("Internal Server Error !!", "Error!");
            return false;
          }
        );
      }
    }
  }
}
