import { Component, OnInit } from '@angular/core';
import { Input } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
//import { Router } from '@angular/router';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

//import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from "ngx-toastr";
import { ProposalModuleService } from 'src/app/services/proposal.service';
import { ViewRevisionComponent } from '../view-revision/view-revision.component';
import { environment } from "../../../../environments/environment";


@Component({
  selector: 'app-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.sass']
})
export class ViewComponent implements OnInit {

  @Input() id;
  ProposalData = [];
  scopeOfWorkDetailsData = [];
  projectScheduleDetailsData = [];
  followupDetailsData = [];
  paymentStageDetailsData = [];
  serviceDetailsData = [];
  contactDetailsData = [];
  proposalRevisionDetailsData = [];
  url:any;

  constructor( public ProposalModuleService: ProposalModuleService,
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private router: Router,
    ) { }

  ngOnInit() {
    this.id = this.route.snapshot.paramMap.get("id");
    this.url = environment.url+"invoice/printProposal&proposalId="+this.id;
    this.ProposalModuleService.viewProposalbyId(this.id).then(data => {
      //console.log("datapatch", data.data);
      this.ProposalData = data.data;
      this.scopeOfWorkDetailsData = data.data.proposalScopeDefine;
      this.projectScheduleDetailsData = data.data.proposalProjectSchedule;
      this.followupDetailsData = data.data.followup;
      this.paymentStageDetailsData = data.data.proposalPaymentSchedule;
      this.serviceDetailsData = data.data.serviceResult;
      this.contactDetailsData =  data.data.contactDetails;
      this.proposalRevisionDetailsData =  data.data.revisionResult;

    });

  }

  onView(id) {
    const modalRef = this.modalService.open(ViewRevisionComponent,{
      size: 'lg'
    });
    // const modalRef = this.modalService.open(ViewRevisionComponent);
    let getid = id;
    let action = "View";
    modalRef.componentInstance.action = action;
    modalRef.componentInstance.id = getid;
  }

  getPaymentAgainst(status:any){
    if(status == 1){
      return "Advance";
    }else if(status == 2){
      return "Regular";
    }else if(status == 3){
      return "Completion";
    }
    return "NA";
  }

  goBack() {
    this.router.navigateByUrl("/app/refresh", { skipLocationChange: true }).then(() => {
      this.router.navigate(["/proposal/proposal/proposallist"]);
    });
  }
}
