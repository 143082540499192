import { Component, OnInit } from '@angular/core';
import { Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from "ngx-toastr";
import { MastersService } from 'src/app/services/masters.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';

@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.sass']
})
export class CreateComponent implements OnInit {

  @Input() id;
  @Input() action;
  stateList = [];
  cityList = [];
  areaList = [];
  tableName: string;
  rateConfigFrm: FormGroup;
  poupuTitle: string;
  submitted: boolean;

  constructor(
    private _formBuilder: FormBuilder,
    private toastr: ToastrService,
    private router: Router,
    public MasterService: MastersService,
    public activeModal: NgbActiveModal,
    private spinnerService: Ng4LoadingSpinnerService
    ) { }

  ngOnInit() {
    //console.log(this.id);
    this.action = this.action;

    const token_key = localStorage.getItem("token");
    this.MasterService.getState().subscribe((e: any) => {
      console.log(e.data);
      this.stateList = e.data;
    });


    if (this.action === "Edit") {
      this.poupuTitle = "Edit Rate Configurator";
      this.MasterService.getRateConfiguratorDetails(this.id).then(data => {

        this.tableName = "state_master";
        this.MasterService.getActiveDetailsListForUpdate(data.data['stateId'], this.tableName).subscribe((e: any) => {
          this.stateList = e.data;
        });
        // this.tableName = "city_master";
        // this.MasterService.getActiveDetailsListForUpdate(data.data['cityId'], this.tableName).subscribe((e: any) => {
        //   this.cityList = e.data;
        // });
        this.changeState(data.data['stateId']);
        this.changeCity(data.data['cityId']);
        this.rateConfigFrm.patchValue(data.data);
      });
    } else if (this.action === "Add") {
      this.poupuTitle = "Add Rate Configurator";
      this.tableName = "state_master";
      this.MasterService.getActiveDetailsListForCreate(this.tableName).subscribe((e: any) => {
        console.log(e.data);
        this.stateList = e.data;
      });

    }
    this.rateConfigFrm = this.createCountryForm();
  }

  createCountryForm(): FormGroup {
    return (this.rateConfigFrm = this._formBuilder.group({
      stateId: ["", Validators.required],
      cityId: ["", Validators.required],
      areaId: ["", Validators.required],
      ratePerSqFt: ["", Validators.required],
      ratePerSqMtr: ["", Validators.required],
    }));
  }

  get f() {
    return this.rateConfigFrm.controls;
  }

  onSubmit(regfrm: any) {
    this.spinnerService.show();
    this.submitted = true;
    if (this.rateConfigFrm.invalid) {
      this.toastr.error("Error !! Record not created");
      return;
    }

    this.MasterService.createRateConfigurator(this.rateConfigFrm.value).subscribe((response: any) => {
      console.log("response", response.msg);
      if (response.code === 1) {
        this.activeModal.close();
        this.toastr.success("Success !! " + response.msg);

        this.router.navigateByUrl("/app/refresh", { skipLocationChange: true }).then(() => {
          this.router.navigate(["/masters/rateconfigurator/rateconfiguratorlist"]);
        });
        return true;
      } else if (response.code == 0) {
        this.toastr.error(response.msg, "Error!");
        return false;
      } else {
        this.toastr.error("Error !! Record not created");
        return false;
      }
    },
      error => {
        console.log("failure", error);
        return false;
      }
    );

    // this.regfrm.reset();
  }

  changeState(stateId: any) {
    //alert('stateId--'+stateId);
    this.MasterService.getCityByStateId(stateId).subscribe((e: any) => {
      console.log(e.data);
      this.cityList = e.data;
    });
  }

  changeCity(cityId: any) {
    //alert('changeState'+cityId);
    this.MasterService.getAreaByCityId(cityId).subscribe((e: any) => {
      console.log(e.data);
      this.areaList = e.data;
    });
  }

  numberOnly(event): boolean {
    var txtId = event.target.value;
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode != 46) {
      return false;
    }
    return true;
  }

  UpdateRateConfig() {
    this.spinnerService.show();
    this.submitted = true;
    //this.options.value.token = localStorage.getItem("token");
    // this.options.value.updated_by_id = localStorage.getItem("created_by_id");
    this.rateConfigFrm.value.id = this.id;
    if (this.rateConfigFrm.invalid) {
      this.toastr.error("Error !! Record not updated");
      return;
    }
    this.MasterService.updateRateConfigTask(this.id, this.rateConfigFrm.value).subscribe((response: any) => {
      if (response.code == 1) {
        this.activeModal.close();
        this.toastr.success("Success !", response.msg);

        this.router
          .navigateByUrl("/app/refresh", { skipLocationChange: true })
          .then(() => {
            this.router.navigate(["/masters/rateconfigurator/rateconfiguratorlist"]);
          });
        return true;
      } else if (response.code == 0) {
        this.toastr.error(response.msg, "Error!");
        return false;
      } else {
        this.toastr.error("Record not created!", "Error!");
        return false;
      }
    },
      error => {
        this.toastr.warning("Internal Server Error !!", "Error!");
        return false;
      }
    );
  }

}
