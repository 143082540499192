import { Component, OnInit } from '@angular/core';
import { Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from "ngx-toastr";
import { MastersService } from 'src/app/services/masters.service';

@Component({
  selector: 'app-terms-condition-create',
  templateUrl: './terms-condition-create.component.html',
  styleUrls: ['./terms-condition-create.component.sass']
})
export class TermsConditionCreateComponent implements OnInit {
  @Input() id;
  @Input() action;
  categoryList=[];
  data = [];
  termsfrm: FormGroup;
  poupuTitle: string;
  submitted: boolean;
  constructor(private _formBuilder: FormBuilder,
    private toastr: ToastrService,
    private router: Router,
    public MasterService: MastersService,
    public activeModal: NgbActiveModal) { }

  ngOnInit() {
    console.log(this.id);
    this.action = this.action;

    const token_key = localStorage.getItem("token");
      this.MasterService.getcategory().subscribe((e: any) => {
        this.categoryList = e.data;
      });
      if (this.action === "Edit") {
        //alert('Edit');
        this.poupuTitle = "Edit Terms and Conditions";
        this.MasterService.gettermsdetails(this.id).then(data => {
          this.termsfrm.patchValue(data.data);
        });
  
      } else if (this.action === "Add") {
        //alert('Add');
        this.poupuTitle = "Add Terms and  Conditions";
      }
      this.termsfrm = this.createTermsform();
  }
  createTermsform(): FormGroup {
    return (this.termsfrm = this._formBuilder.group({
      categoryName: ["", Validators.required],
      condition: ["", Validators.required],
    }));
  }

  get f() {
    return this.termsfrm.controls;
  }
  onSubmit(regfrm: any) {
    this.submitted = true;
    if (this.termsfrm.invalid) {
      this.toastr.error("Error !! Record not created");
      return;
    }
    this.MasterService.createCondition(this.f.categoryName.value,this.f.condition.value).subscribe((response: any) => {
      console.log("response", response.msg);
      
      if (response.code === 1) {
        this.activeModal.close();
        this.toastr.success("Success !! " + response.msg);

        this.router.navigateByUrl("/app/refresh", { skipLocationChange: true }).then(() => {
          this.router.navigate(["/masters/termsandconditions/termsandconditionslist"]);
        });
        return true;
      } else if (response.code == 0) {
        this.toastr.error(response.msg, "Error!");
        return false;
      } else {
        this.toastr.error("Error !! Record not created");
        return false;
      }
    },
      error => {
        console.log("failure", error);
        return false;
      }
    );

    // this.regfrm.reset();
  }
  UpdateProjectType() {
    this.submitted = true;
    //this.options.value.token = localStorage.getItem("token");
    // this.options.value.updated_by_id = localStorage.getItem("created_by_id");
    this.termsfrm.value.id = this.id;
    this.MasterService.updateterms(this.id, this.f.categoryName.value,this.f.condition.value).subscribe(
      (response: any) => {
        if (response.code == 1) {
          //alert("update")
          this.activeModal.close();
          this.toastr.success("Success !", response.msg);

          this.router
            .navigateByUrl("/app/refresh", { skipLocationChange: true })
            .then(() => {
              this.router.navigate(["/masters/termsandconditions/termsandconditionslist"]);
            });
          return true;
        } else if (response.code == 0) {
          this.toastr.error(response.msg, "Error!");
          return false;
        } else {
          this.toastr.error("Record not created!", "Error!");
          return false;
        }
      },
      error => {
        this.toastr.warning("Internal Server Error !!", "Error!");
        return false;
      }
    );
  }
}
