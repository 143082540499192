import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RouterModule, Routes } from "@angular/router";
import { ProejctlistComponent } from './list/proejctlist/proejctlist.component';
import { ProejctcreateComponent } from './create/proejctcreate/proejctcreate.component';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DataTablesModule } from 'angular-datatables';



const routes: Routes = [
  
  {
    path: "projectlist",
    component: ProejctlistComponent
  },
  {
    path: "projectcreate",
    component: ProejctcreateComponent
  }
];

@NgModule({
  declarations: [ProejctlistComponent,ProejctcreateComponent],
  imports: [
    RouterModule.forChild(routes),
    CommonModule,
    ReactiveFormsModule,
    DataTablesModule
    
  ],
  
  entryComponents: [
    ProejctcreateComponent  ]
})
export class ProjectTypeModule { }
