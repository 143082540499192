import { Component, OnInit, ViewChild } from '@angular/core';
import { Input } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from "ngx-toastr";
import { OpportunityModuleService } from 'src/app/services/opportunity-module.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbDateStruct, NgbCalendar, NgbDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { AmazingTimePickerService } from 'amazing-time-picker';
import { environment } from "../../../../environments/environment";
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';

@Component({
  selector: 'app-generate',
  templateUrl: './generate.component.html',
  styleUrls: ['./generate.component.sass']
})
export class GenerateComponent implements OnInit {
  SalaryProcessForm: FormGroup;
  myDateValue: Date;
  loginId: any;
  url:any;
  employeeId = 0;

  constructor(
    private modalService: NgbModal,
    private _formBuilder: FormBuilder,
    private toastr: ToastrService,
    private router: Router,
    private route: ActivatedRoute,
    public OpportunityModuleService: OpportunityModuleService,
    private calendar: NgbCalendar,
    private atp: AmazingTimePickerService,
    private spinnerService: Ng4LoadingSpinnerService
  ) { }

  ngOnInit() {
    this.loginId = localStorage.getItem("userMasterId");
    this.url = environment.url+"invoice/generateSalarySlip&employeeId="+this.employeeId;
    const token_key = localStorage.getItem("token");
    this.myDateValue = new Date();
    this.SalaryProcessForm = this.createOppertunityForm();
  }

  createOppertunityForm(): FormGroup {

    return (this.SalaryProcessForm = this._formBuilder.group({
      month: ["", Validators.required],
      employeeList: this._formBuilder.array([]),
    }));
  }

  onOpenCalendar(container) {
    container.monthSelectHandler = (event: any): void => {
      container._store.dispatch(container._actions.select(event.date));
    };
    container.setViewMode('month');
  }

  onGo() {
    this.spinnerService.show();
    //let month = this.SalaryProcessForm.value.month;
    //alert(month);
    if (this.SalaryProcessForm.invalid) {
      this.toastr.error("Error !! Please Select Month");
      return;
    }
    this.OpportunityModuleService.getEmployeeListForSalaryProcess(this.SalaryProcessForm.value).subscribe((response: any) => {
      let contactDetails = ((this.SalaryProcessForm.get('employeeList') as FormArray).length + 1);
      for (let i = 0; i < contactDetails; i++) {
        (this.SalaryProcessForm.get('employeeList') as FormArray).removeAt(0);
      }
      response.data.forEach((x, index) => {
        const documentArray = this.SalaryProcessForm.controls.employeeList as FormArray;
        //alert(x.firstName);
        documentArray.push(
          this._formBuilder.group({
            employeeId: x.id,
            isCheck: '',
            employeeName: x.firstName + " " + x.lastName,
            workDays: x.employeeWorkDays,
            LOP: x.employeeLOP,
            Days: response.days,
            salaryStatus: x.salaryStatus,
            salaryMonth: response.salaryMonth,
          })
        );
      });
    });
  }

  salaryProcess(){
    this.spinnerService.show();
    this.OpportunityModuleService.generateEmployeeSalaryProcess(this.SalaryProcessForm.value, this.loginId).subscribe((response: any) => {
      if (response.code == 1) {
        this.toastr.success("Success !", response.msg);
        this.router
          .navigateByUrl("/app/refresh", { skipLocationChange: true })
          .then(() => {
            this.router.navigate(["/salaryProcessMain/salaryProcess/generate"]);
          });
        return true;
      } else if (response.code == 0) {
        this.toastr.error(response.msg, "Error!");
        return false;
      } else {
        this.toastr.error("Record not created!", "Error!");
        return false;
      }
    },
      error => {
        this.toastr.warning("Internal Server Error !!", "Error!");
        return false;
      }
    );
  }

  getEmployeeList() {
    return (this.SalaryProcessForm.get("employeeList") as FormArray).controls;
  }

  addEmployeeList() {
    const documentArray = this.SalaryProcessForm.controls.employeeList as FormArray;
    documentArray.push(
      this._formBuilder.group({
        employeeId: [""],
        isCheck: [""],//, Validators.required
        employeeName: [""],//, Validators.required
        workDays: [""],//, Validators.required
        LOP: [""],//, Validators.required
        Days: [""],//, Validators.required
        salaryStatus: [""],//, Validators.required
        salaryMonth: [""],//, Validators.required
      })
    );
  }

}
