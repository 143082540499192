import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from "@angular/router";
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DataTablesModule } from 'angular-datatables';
import { NgbdDatepickerPopup } from './create/opportunity-followup-create/datepicker-popup';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { OpportunityFollowupListComponent } from './list/opportunity-followup-list/opportunity-followup-list.component';
import { OpportunityFollowupCreateComponent } from './create/opportunity-followup-create/opportunity-followup-create.component';
import { OpportunityFollowupViewComponent } from './view/opportunity-followup-view/opportunity-followup-view.component';
import {MarkAsteriskDirectiveModule} from '../../directives/mark-asterisk.directive';


const routes: Routes = [
  {
    path: "opportunityFollowupList",
    component: OpportunityFollowupListComponent
  },
  {
    path: "opportunityFollowupCreate/:id/:action",
    component: OpportunityFollowupCreateComponent
  },
  {
    path: "opportunityFollowupView",
    component: OpportunityFollowupViewComponent
  }  
  
];

@NgModule({
  declarations: [OpportunityFollowupCreateComponent,OpportunityFollowupListComponent, OpportunityFollowupViewComponent,NgbdDatepickerPopup],
  imports: [
    RouterModule.forChild(routes),
    CommonModule,
    ReactiveFormsModule,
    DataTablesModule,
    FormsModule,
    NgbModule,
    MarkAsteriskDirectiveModule
    
  ],
  
  entryComponents: [
    OpportunityFollowupCreateComponent  ],
    exports: [NgbdDatepickerPopup],
    bootstrap: [NgbdDatepickerPopup],
})
export class OpportunityFollowupModule { }