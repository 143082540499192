import { NgModule } from '@angular/core';
import { RouterModule, Routes } from "@angular/router";
import { CommonModule } from '@angular/common';
import {MarkAsteriskDirectiveModule} from '../../directives/mark-asterisk.directive';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DataTablesModule } from 'angular-datatables';
import { ListComponent } from './list/list.component';
import { CreateComponent } from './create/create.component';

import { BrowserModule } from '@angular/platform-browser';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

const routes: Routes = [

  {
    path: "holidaycalenderlist",
    component: ListComponent
  },
  {
    path: "holidaycalendercreate",
    component: CreateComponent
  }
];

@NgModule({
  declarations: [ListComponent, CreateComponent],

  imports: [
    RouterModule.forChild(routes),
    CommonModule,
    ReactiveFormsModule,
    DataTablesModule,
    FormsModule,
    NgbModule,
    MarkAsteriskDirectiveModule
  ],
  exports: [CreateComponent],
  entryComponents: [
    CreateComponent
  ],


})
export class HolidayCalenderModule { }
