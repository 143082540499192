import { Component, OnInit } from '@angular/core';
import { Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from "ngx-toastr";
import { MastersService } from 'src/app/services/masters.service';
import { removeSpaces } from '../../state/statecreate/stateName.validator';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.sass']
})
export class CreateComponent implements OnInit {
  @Input() id;
  @Input() action;
  stateList = [];
  TopologyMasterForm: FormGroup;
  poupuTitle: string;
  submitted: boolean;

  constructor(private _formBuilder: FormBuilder,
    private toastr: ToastrService,
    private router: Router,
    public MasterService: MastersService,
    public activeModal: NgbActiveModal,private spinnerService: Ng4LoadingSpinnerService) { }

  ngOnInit() {
    console.log(this.id);
    this.action = this.action;

    const token_key = localStorage.getItem("token");

    if (this.action === "Edit") {

      this.poupuTitle = "Edit Typology";
      this.MasterService.getTopologyMasterbyId(this.id).then(data => {
        console.log("datapatch", data.data);
        this.TopologyMasterForm.patchValue(data.data);
      });

    } else if (this.action === "Add") {

      this.poupuTitle = "Add Typology";
    }
    this.TopologyMasterForm = this.createTopologyMasterForm();
  }

  createTopologyMasterForm(): FormGroup {
    return (this.TopologyMasterForm = this._formBuilder.group({
      //topology: ["", Validators.required],
      topology: ["", [Validators.required, Validators.pattern('[a-zA-Z ]*'), removeSpaces]]
    }));
  }

  get f() {
    return this.TopologyMasterForm.controls;
  }

  onSubmit(regfrm: any) {
    this.spinnerService.show();
    this.submitted = true;
    if (this.TopologyMasterForm.invalid) {
      this.toastr.error("Error !! Record not created");
      return;
    }
    this.MasterService.createTopologyMaster(this.TopologyMasterForm.value).subscribe((response: any) => {
      console.log("response", response.msg);
      this.activeModal.close();
      if (response.code === 1) {
        this.activeModal.close();
        this.toastr.success("Success !! " + response.msg);

        this.router.navigateByUrl("/app/refresh", { skipLocationChange: true }).then(() => {
          this.router.navigate(["/masters/topologymaster/topologymasterlist"]);
        });
        return true;
      } else if (response.code == 0) {
        this.toastr.error(response.msg, "Error!");
        return false;
      } else {
        this.toastr.error("Error !! Record not created");
        return false;
      }
    },
      error => {
        console.log("failure", error);
        return false;
      }
    );


  }

  UpdateProjectType() {
    this.spinnerService.show();
    this.submitted = true;
    this.TopologyMasterForm.value.id = this.id;

    if (this.TopologyMasterForm.invalid) {
      this.toastr.error("Error !! Record not updated");
      return;
    }

    this.MasterService.updateTopologyMaster(this.id, this.TopologyMasterForm.value).subscribe((response: any) => {

      console.log("response");
      if (response.code == 1) {
        // alert("update")
        this.activeModal.close();
        this.toastr.success("Success !", response.msg);

        this.router
          .navigateByUrl("/app/refresh", { skipLocationChange: true })
          .then(() => {
            this.router.navigate(["/masters/topologymaster/topologymasterlist"]);
          });
        return true;
      } else if (response.code == 0) {
        this.toastr.error(response.msg, "Error!");
        return false;
      } else {
        this.toastr.error("Record not created!", "Error!");
        return false;
      }
    },
      error => {
        this.toastr.warning("Internal Server Error !!", "Error!");
        return false;
      }
    );
  }

  alphabetOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if ((charCode > 64 && charCode < 91) || (charCode > 96 && charCode < 123) || charCode == 8 || (charCode == 32)) {
      // this.onlyAlphabet =false;

      return true;
    } else {
      //this.onlyAlphabet =true;
      //alert("only alphabates are allowed")
      return false;
    }
  }

}
