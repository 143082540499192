import { Component, OnInit } from '@angular/core';
import { Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';

import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from "ngx-toastr";
import { MastersService } from '../../services/masters.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.sass']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  poupuTitle: string;
  submitted: boolean;
  loading: boolean;

  constructor(private router: Router, private service: MastersService,
    private _formBuilder: FormBuilder,
    private toastr: ToastrService,
    private spinnerService: Ng4LoadingSpinnerService
  ) { }
  msg = "";
  ngOnInit() {
    this.loginForm = this.createloginForm();
  }
  createloginForm(): FormGroup {
    return (this.loginForm = this._formBuilder.group({
      email: ["", Validators.required],
      password: ["", Validators.required],
    }));
  }

  get f() {
    return this.loginForm.controls;
  }
  check(uname: string, p: string) {
    var output = this.service.checkusernameandpassword(uname, p);
    console.log("output", output)
    if (output == true) {
      this.router.navigateByUrl("/app/refresh", { skipLocationChange: true }).then(() => {
        this.router.navigate(["/dashboard"]);
      });
      console.log("trueeedd");
    }
    else {
      this.msg = 'Invalid username or password';
    }
  }

  onSubmit(regfrm: any) {
    this.submitted = true;
    if (this.loginForm.invalid) {
      this.toastr.error("Please Enter All Required Filled");
      return;
    }
    this.service.login(this.loginForm.value).subscribe((response: any) => {
      this.spinnerService.show();
      console.log("response", response.msg);
      //  this.spinnerService.hide();
      if (response.code === 1) {


        this.toastr.success("Success !! ");
        localStorage.setItem("userMasterId", response.userMasterId);
        localStorage.setItem("name", response.name);
        localStorage.setItem("loginName", response.name);
        localStorage.setItem("email", response.email);
        localStorage.setItem("token", response.token);
        this.router.navigateByUrl("/app/refresh", { skipLocationChange: true }).then(() => {
          this.router.navigate(["/dashboard"]);
        });
        return true;
      } else if (response.responseCode == 200) {
        this.toastr.error("Username or password is incorrect");
        return false;
      } else if (response.code == 0) {
        this.toastr.error("Username or password is incorrect");
        return false;
      } else {
        this.toastr.error("Error !! Record not created");
        return false;
      }
    },
      error => {
        console.log("failure", error);
        return false;
      }
    );

    // this.regfrm.reset();
  }
}
