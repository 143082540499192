import { Injectable } from '@angular/core';
import { HttpClient, HttpClientModule, HttpHeaders } from "@angular/common/http";
import { from, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "./../../environments/environment";

const httpOptions = {
  headers: new HttpHeaders({
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Method": "POST",
    "Access-Control-Allow-Headers":
      "Access-Control-Allow-Headers,Content-Type,Access-Control-Allow-Methods, Authorization, X-Requested-With",
    "Access-Control-Request-Headers":
      "access-control-allow-origin, content-type"
  })
};

@Injectable({
  providedIn: 'root'
})
export class OpportunityModuleService {
  headers: HttpHeaders | { [header: string]: string | string[] };

  constructor(private httpClient: HttpClient) { }

  private handleError(error: any): Promise<any> {
    return Promise.reject(error.message || error);
  }

  /**
    *Discription : Opportunity master api's.
    *@author: Ram Garole
    * Date: 31-05-21
  */

  getOpportunityList() {
    return this.httpClient.get(environment.url + "api/getOpportunityList");
  }

  updateOpportunityStatus(id: any, status: any) {
    return this.httpClient.post(
      environment.url + "api/updateOpportunityStatus&status=" + status + "&id=" + id,
      {
        id: id,
        status: status
      }
    );
  }

  GetOpportunityDetailsById(id: any): Promise<any> {
    return this.httpClient
      .post(environment.url + "api/GetOpportunityDetails&id=" + id, {
        id: id
      })
      .toPromise()
      .catch(this.handleError);
  }
  GetOpportunityDetailsByIdForView(id: any): Promise<any> {
    return this.httpClient
      .post(environment.url + "api/GetOpportunityDetailsForView&id=" + id, {
        id: id
      })
      .toPromise()
      .catch(this.handleError);
  }

  createOpportunity(FormDetails: any) {
    return this.httpClient.post<boolean>(
      environment.url + "/api/createUpdateOpportunity",
      JSON.stringify(FormDetails)
    );
  }

  updateOpportunity(id: any, FormDetails: any) {
    return this.httpClient.post<boolean>(
      environment.url + "/api/createUpdateOpportunity&id=" + id,
      JSON.stringify(FormDetails)
    );
  }

  getCustomerList() {
    return this.httpClient.get(environment.url + "api/getCustomerList");
  }

  getTopologyList() {
    return this.httpClient.get(environment.url + "api/getTopologyList");
  }

  getUOMList() {
    return this.httpClient.get(environment.url + "api/getUOMList");
  }
  getProjectTypeList() {
    return this.httpClient.get(environment.url + "api/getDetails&table=project_type");
  }
  getEmployeeList() {
    return this.httpClient.get(environment.url + "api/getEmployeeList&table=project_type");
  }
  deleteOpportunityDetails(id: any) {
    return this.httpClient.post(
      environment.url + "api/deleteOpportunityDetails&id=" + id,
      {
        id: id,
      }
    );
  }
  getProjectCode() {
    return this.httpClient.get(environment.url + "api/getNewProjectCode");
  }

  /**
    END
  */

  /**
    *Discription : Opportunity master api's.
    *@author: Ram Garole
    * Date: 03-06-21
  */

  getOpportunityFolloupList() {
    return this.httpClient.get(environment.url + "api/getOpportunityFollowupList");
  }

  getOpportunityFolloupListForDashboard(userMasterId: any) {
    return this.httpClient.get(environment.url + "api/getOpportunityFollowupList&loginId=" + userMasterId);
  }

  GetOpportunityFollowupDetailsById(id: any): Promise<any> {
    return this.httpClient
      .post(environment.url + "api/getOpportunityFollowupDetails&id=" + id, {
        id: id
      })
      .toPromise()
      .catch(this.handleError);
  }
  createOpportunityFollowup(id, FormDetails: any) {
    return this.httpClient.post<boolean>(
      environment.url + "/api/createOpportunityFollowup&id=" + id,
      JSON.stringify(FormDetails)
    );
  }
  GetOpportunityFollowupDetailsList(id: any): Promise<any> {
    return this.httpClient
      .post(environment.url + "api/getOpportunityFollowupDetailsList&id=" + id, {
        id: id
      })
      .toPromise()
      .catch(this.handleError);
  }
  getOpportunityFolloupNotInterestedList() {
    return this.httpClient.get(environment.url + "api/getOpportunityFollowupNotInterestedList");
  }
  getCustomerContactDetails(customerId: any): Promise<any> {
    return this.httpClient
      .post(environment.url + "api/getCustomerContactDetails&id=" + customerId, {
        id: customerId
      })
      .toPromise()
      .catch(this.handleError);
  }
  getAreaList() {
    return this.httpClient.get(environment.url + "api/getAreaList");
  }
  /**
    END
  */

  getDeliveryScheduleList() {
    return this.httpClient.get(environment.url + "api/GetDeliveryScheduleList");
  }

  /////////////////////////////////
  getEmployeeListForSalaryProcess(form: any) {
    return this.httpClient.post<boolean>(
      environment.url + "salary/getEmployeeListForSalaryProcess",
      JSON.stringify(form)
    );
  }

  generateEmployeeSalaryProcess(FormDetails: any, loginId: any) {
    return this.httpClient.post<boolean>(
      environment.url + "salary/generateEmployeeSalaryProcess&loginId=" + loginId,
      JSON.stringify(FormDetails)
    );
  }

  getEmployeeSalarySlip(form: any) {
    return this.httpClient.post<boolean>(
      environment.url + "salary/getEmployeeSalarySlip",
      JSON.stringify(form)
    );
  }

  getEmployeeListFroViewSalarySlip() {
    return this.httpClient.get(environment.url + "salary/getEmployeeListFroViewSalarySlip");
  }
  ////////////////////////////////////////////////////////////////////////////////////
  getProjectProgressBarStatusForDashboard() {
    return this.httpClient.get(environment.url + "projectCreation/getprojectProgressBarStatus");
  }
}
