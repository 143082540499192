import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from "@angular/router";
import { LocationStrategy, HashLocationStrategy } from "@angular/common";
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown-angular7';

const routes: Routes = [
  {
    path: "project",
    loadChildren: () => import("./projectCreation/project-creation.module").then(mod => mod.ProjectCreationModule)
  },
  {
    path: "projectSchedule",
    loadChildren: () => import("./projectSchedule/projectSchedule.module").then(mod => mod.ProjectScheduleModule)
  },
  {
    path: "burningProject",
    loadChildren: () => import("./burningProject/create.module").then(mod => mod.CreateModule)
  },
  {
    path: "resourcemovement",
    loadChildren: () => import("./resourcemovement/resourcemovement.module").then(mod => mod.ResourcemovementModule)
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  providers: [{ provide: LocationStrategy, useClass: HashLocationStrategy }],
  exports: [RouterModule,
    // ProjectTypeModule,
    // StateModule
  ],
  declarations: [],
})
export class ProjectCreationMainModule { }
