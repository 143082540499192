import { Component, OnInit } from '@angular/core';
import { Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from "ngx-toastr";
import { MastersService } from 'src/app/services/masters.service';


@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.sass']
})
export class CreateComponent implements OnInit {

 
  @Input() id;
  @Input() action;
  stateList = [];
  feasibilityCriteriaForm: FormGroup;
  poupuTitle: string;
  submitted: boolean;

  constructor(
    private _formBuilder: FormBuilder,
    private toastr: ToastrService,
    private router: Router,
    public MasterService: MastersService,
    public activeModal: NgbActiveModal) { }

  ngOnInit() {
    
    console.log(this.id);
    this.action = this.action;

    const token_key = localStorage.getItem("token");

    if (this.action === "Edit") {
      
      this.poupuTitle = "Edit Feasibility Criteria";
      this.MasterService.getFeasibilityCriteriabyId(this.id).then(data => {
        console.log("datapatch", data.data);
        this.feasibilityCriteriaForm.patchValue(data.data);
      });

    } else if (this.action === "Add") {
      
      this.poupuTitle = "Add Feasibility Criteria";
    }
    this.feasibilityCriteriaForm = this.createFeasibilityCriteriaForm();
  }

  createFeasibilityCriteriaForm(): FormGroup {
    return (this.feasibilityCriteriaForm = this._formBuilder.group({
      criteriaTitle: ["", Validators.required],
      criteriaDescription: ["", Validators.required],
      value: ["", Validators.required],
    }));
  }

  get f() {
    return this.feasibilityCriteriaForm.controls;
  }

  onSubmit(regfrm: any) {
    this.submitted = true;
    if (this.feasibilityCriteriaForm.invalid) {
      this.toastr.error("Error !! Record not created");
      return;
    }
    this.MasterService.createFeasibilityCriteria(this.feasibilityCriteriaForm.value).subscribe((response: any) => {
      console.log("response", response.msg);
      this.activeModal.close();
      if (response.code === 1) {
        this.activeModal.close();
        this.toastr.success("Success !! " + response.msg);

        this.router.navigateByUrl("/app/refresh", { skipLocationChange: true }).then(() => {
          this.router.navigate(["/masters/feasiblitycriteria/feasiblitycriterialist"]);
        });
        return true;
      } else if (response.code == 0) {
        this.toastr.error(response.msg, "Error!");
        return false;
      } else {
        this.toastr.error("Error !! Record not created");
        return false;
      }
    },
      error => {
        console.log("failure", error);
        return false;
      }
    );

    
  }

  UpdateProjectType() {
    this.submitted = true;
    this.feasibilityCriteriaForm.value.id = this.id;
    
    if (this.feasibilityCriteriaForm.invalid) {
      this.toastr.error("Error !! Record not updated");
      return;
    }
    
    this.MasterService.updateFeasibilityCriteria(this.id,this.feasibilityCriteriaForm.value).subscribe((response: any) => {
     
      console.log("response");
        if (response.code == 1) {
          // alert("update")
          this.activeModal.close();
          this.toastr.success("Success !", response.msg);

          this.router
            .navigateByUrl("/app/refresh", { skipLocationChange: true })
            .then(() => {
              this.router.navigate(["/masters/feasiblitycriteria/feasiblitycriterialist"]);
            });
          return true;
        } else if (response.code == 0) {
          this.toastr.error(response.msg, "Error!");
          return false;
        } else {
          this.toastr.error("Record not created!", "Error!");
          return false;
        }
      },
      error => {
        this.toastr.warning("Internal Server Error !!", "Error!");
        return false;
      }
    );
  }

  numberOnly(event): boolean {
    var txtId = event.target.value;
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode != 46) {
      return false;
    }
    return true;
  }

}
