import { Component, OnInit } from '@angular/core';
import { Input } from '@angular/core';
import {
  FormControl,
  FormBuilder,
  FormArray,
  ValidatorFn,
  FormGroup,
  Validators,
  FormGroupDirective,
  AbstractControl
} from "@angular/forms";
import { Router } from '@angular/router';

import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from "ngx-toastr";
import { MastersService } from 'src/app/services/masters.service';

import { ValidateEmptySpace } from '../../../Validators/Custom-Validator/custom.validator';


@Component({
  selector: 'app-terms-and-condition-category-create',
  templateUrl: './terms-and-condition-category-create.component.html',
  styleUrls: ['./terms-and-condition-category-create.component.sass']
})
export class TermsAndConditionCategoryCreateComponent implements OnInit {

  @Input() id;
  @Input() action;

  termsAndConditionCategoryForm: FormGroup;
  poupuTitle: string;
  submitted: boolean;

  constructor(
    private _formBuilder: FormBuilder,
    private toastr: ToastrService,
    private router: Router,
    public MasterService: MastersService,
    public activeModal: NgbActiveModal) { }

  ngOnInit() {
    console.log(this.id);
    this.action = this.action;

    const token_key = localStorage.getItem("token");

    if (this.action === "Edit") {
      //alert('Edit');
      this.poupuTitle = "Edit Terms And Condition Category";
      this.MasterService.GetTermsAndConditionCategoryDetails(this.id).then(data => {
        console.log("datapatch", data.data);
        this.termsAndConditionCategoryForm.patchValue(data.data);
      });

    } else if (this.action === "Add") {
      //alert('Add');
      this.poupuTitle = "Add Terms And Condition Category";
    }
    this.termsAndConditionCategoryForm = this.createCountryForm();
  }

  createCountryForm(): FormGroup {
    return (this.termsAndConditionCategoryForm = this._formBuilder.group({
      // categoryName: new FormControl('', [Validators.required, Validators.pattern("^[a-zA-Z ]*$"), ValidateEmptySpace],),
      categoryName: ["", Validators.required],
    }));
  }

  get f() {
    return this.termsAndConditionCategoryForm.controls;
  }

  onSubmit(regfrm: any) {
    this.submitted = true;
    if (this.termsAndConditionCategoryForm.invalid) {
      this.toastr.error("Error !! Record not created");
      return;
    }
    this.MasterService.createTermsAndConditionCategory(this.f.categoryName.value).subscribe((response: any) => {
      console.log("response", response.msg);

      if (response.code === 1) {
        this.activeModal.close();
        this.toastr.success("Success !! " + response.msg);

        this.router.navigateByUrl("/app/refresh", { skipLocationChange: true }).then(() => {
          this.router.navigate(["/masters/termsandconditioncategory/termsandconditioncategorylist"]);
        });
        return true;
      } else if (response.code == 0) {
        this.toastr.error(response.msg, "Error!");
        return false;
      } else {
        this.toastr.error("Error !! Record not created");
        return false;
      }
    },
      error => {
        console.log("failure", error);
        return false;
      }
    );

    // this.regfrm.reset();
  }

  UpdateProjectType() {
    this.submitted = true;
    //this.options.value.token = localStorage.getItem("token");
    // this.options.value.updated_by_id = localStorage.getItem("created_by_id");
    this.termsAndConditionCategoryForm.value.id = this.id;
    //alert('update funtion');
    this.MasterService.updateTermsAndConditionCategory(this.id, this.f.categoryName.value).subscribe((response: any) => {
      if (response.code == 1) {
        //alert("update")
        this.activeModal.close();
        this.toastr.success("Success !", response.msg);

        this.router
          .navigateByUrl("/app/refresh", { skipLocationChange: true })
          .then(() => {
            this.router.navigate(["/masters/termsandconditioncategory/termsandconditioncategorylist"]);
          });
        return true;
      } else if (response.code == 0) {
        this.toastr.error(response.msg, "Error!");
        return false;
      } else {
        this.toastr.error("Record not created!", "Error!");
        return false;
      }
    },
      error => {
        this.toastr.warning("Internal Server Error !!", "Error!");
        return false;
      }
    );
  }

}
