import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from "@angular/router";
import { LocationStrategy, HashLocationStrategy } from "@angular/common";
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DataTablesModule } from 'angular-datatables';
import {MarkAsteriskDirectiveModule} from '../../directives/mark-asterisk.directive';
import { AreaCreateComponent } from './area-create/area-create.component';
import { AreaListComponent } from './area-list/area-list.component';

const routes: Routes = [
  {
    path: "arealist",
    component: AreaListComponent
  },
  {
    path: "areacreate",
    component: AreaCreateComponent
  }
];

@NgModule({
  declarations: [AreaListComponent,AreaCreateComponent],
  imports: [
    RouterModule.forChild(routes),
    CommonModule,
    ReactiveFormsModule,
    DataTablesModule,
    MarkAsteriskDirectiveModule
  ],
  entryComponents: [
    AreaCreateComponent  
  ],
})
export class AreaModule { }
