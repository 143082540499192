import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MarkAsteriskDirectiveModule} from '../../directives/mark-asterisk.directive';
import { RouterModule, Routes } from "@angular/router";
import { DepartmentlistComponent } from './list/departmentlist/departmentlist.component';
import { DepartmentcreateComponent } from './create/departmentcreate/departmentcreate.component';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DataTablesModule } from 'angular-datatables';



const routes: Routes = [
  
  {
    path: "departmentlist",
    component: DepartmentlistComponent
  },
  {
    path: "departmentcreate",
    component: DepartmentcreateComponent
  }
];

@NgModule({
  declarations: [DepartmentlistComponent,DepartmentcreateComponent],
  imports: [
    RouterModule.forChild(routes),
    CommonModule,
    ReactiveFormsModule,
    DataTablesModule,
    MarkAsteriskDirectiveModule
    
  ],
  
  entryComponents: [
    DepartmentcreateComponent  ]
})
export class DepartmentModule { }