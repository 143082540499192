import { Component, OnInit } from '@angular/core';
import { Input } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from "ngx-toastr";
import { MastersService } from 'src/app/services/masters.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
@Component({
  selector: 'app-dashboardmenumanagementcreate',
  templateUrl: './dashboardmenumanagementcreate.component.html',
  styleUrls: ['./dashboardmenumanagementcreate.component.sass']
})
export class DashboardmenumanagementcreateComponent implements OnInit {
  @Input() id;
  @Input() action;
  stateList = [];
  loginId: string;
  dashboardMenuForm: FormGroup;
  TopologyMasterForm: FormGroup;
  designationIdList: any;
  AddMoreDisabled = false;
  empList: any;
  submitted: boolean;

  constructor(
    private _formBuilder: FormBuilder,
    private toastr: ToastrService,
    private router: Router,
    public dashboardMenuManagementServie: MastersService,private spinnerService: Ng4LoadingSpinnerService
  ) { }

  ngOnInit() {
    this.loginId = localStorage.getItem("userMasterId");

    this.dashboardMenuManagementServie.getDashboardDesignationMasterList().subscribe((e: any) => {
      this.designationIdList = e.data;
    });
    this.dashboardMenuForm = this.createMenuForm();
  }
  createMenuForm(): FormGroup {
    return (this.dashboardMenuForm = this._formBuilder.group({
      designationId: ["", Validators.required],
      employeeId: ["", Validators.required],
      menuAccessDetails: this._formBuilder.array([]),
    }));
  }
  addMenuAccessDetails() {
    const documentArray = this.dashboardMenuForm.controls.menuAccessDetails as FormArray;
    documentArray.push(
      this._formBuilder.group({
        id: [""],
        title: ["", Validators.required],
        subTitle: ["", Validators.required],
        isActive: [""],
        checkedTextField: [""]
        // action: ["", Validators.required],
      })
    );
    this.AddMoreDisabled = true;
  }
  getControls() {
    return (this.dashboardMenuForm.get("menuAccessDetails") as FormArray).controls;
  }
  onOptionsSelected(value) {

    let desigId = value;

    this.dashboardMenuManagementServie.getEmpListByDesignation(desigId).then((e: any) => {
      this.empList = e.data;
      console.log(this.empList);
    },
      error => {
        return false;
      }
    );
  }

  menuAccessDetails() {
    return (this.dashboardMenuForm.get("menuAccessDetails") as FormArray).controls;
  }
  onClickMenuAccessDetails() {
    // this.spinnerService.show();
    let userMasterId = this.dashboardMenuForm.controls['employeeId'].value;
    this.dashboardMenuManagementServie.getMenuDetails(userMasterId).subscribe((e: any) => {
      let contactDetails = ((this.dashboardMenuForm.get('menuAccessDetails') as FormArray).length + 1);
      for (let i = 0; i < contactDetails; i++) {
        (this.dashboardMenuForm.get('menuAccessDetails') as FormArray).removeAt(0);
      }

      this.menuAccessDetails = e.data;
      console.log("multimodel data", this.menuAccessDetails);
      e.data.forEach((x, index) => {
        const documentArray = this.dashboardMenuForm.controls.menuAccessDetails as FormArray;
        documentArray.push(
          this._formBuilder.group({
            id: x.id,
            title: x.title,
            subTitle: x.subTitle,
            checkedTextField: x.checkedTextField,
            isActive: x.isActive
          })
        );

      });

    });
  }
  onCheckBoxSelected(e: any, i) {
    if (e.target.checked) {
      this.dashboardMenuForm.controls['menuAccessDetails']['controls'][i]['controls']['checkedTextField'].setValue(1);
    } else {
      this.dashboardMenuForm.controls['menuAccessDetails']['controls'][i]['controls']['checkedTextField'].setValue(0);
    }
  }

  UpdateDashboardMenuAccessMaster() {
    this.spinnerService.show();
    this.submitted = true;
    this.dashboardMenuForm.value.id = this.id;
    // if (this.dashboardMenuForm.invalid) {
    //   this.toastr.error("Error !! Record not updated");
    //   return;
    // }
    let userMasterId = this.dashboardMenuForm.controls['employeeId'].value;
    this.dashboardMenuManagementServie.UpdateDashboardMenuAccessMaster(userMasterId, this.dashboardMenuForm.value, this.loginId).subscribe((response: any) => {
      if (response.code == 1) {
        this.toastr.success("Success !", response.msg);
        this.router
          .navigateByUrl("/app/refresh", { skipLocationChange: true })
          .then(() => {
            this.router.navigate(["/masters/dashboardmenumanagement/DashboardMenuManagementCreate"]);
          });
        return true;
      } else if (response.code == 0) {
        this.toastr.error(response.msg, "Error!");
        return false;
      } else {
        this.toastr.error("Record not created!", "Error!");
        return false;
      }
    },
      error => {
        this.toastr.warning("Internal Server Error !!", "Error!");
        return false;
      }
    );
  }

}
