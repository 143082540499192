import { Component, OnInit, ViewChild } from '@angular/core';
import { Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbDateStruct, NgbCalendar, NgbDatepicker } from '@ng-bootstrap/ng-bootstrap';

import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from "ngx-toastr";
import { MastersService } from 'src/app/services/masters.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';

@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.sass']
})
export class CreateComponent implements OnInit {
  model1 = {
    left: true,
    middle: false,
  };

  model: NgbDateStruct;
  date: { year: number, month: number };
  @ViewChild('dp') dp: NgbDatepicker;
  time = { hour: 13, minute: 30 };
  time2 = { hour: 13, minute: 30 };


  @Input() id;
  @Input() action;
  holidayCalenderList = [];
  holidayCalenderForm: FormGroup;
  poupuTitle: string;
  submitted: boolean;
  myDateValue: Date;

  isActive = 1;
  projecttype: string;
  name: String = "";

  constructor(
    private _formBuilder: FormBuilder,
    private toastr: ToastrService,
    private router: Router, private calendar: NgbCalendar,
    public MasterService: MastersService,
    public activeModal: NgbActiveModal,
    private spinnerService: Ng4LoadingSpinnerService) { }

  ngOnInit() {
    this.myDateValue = new Date();
    console.log(this.id);
    this.action = this.action;

    const token_key = localStorage.getItem("token");

    if (this.action === "Edit") {
      //alert('Edit');
      this.poupuTitle = "Edit Holiday";
      this.MasterService.GetHolidaybyId(this.id).then(data => {
        console.log("date", data.data.date);

        this.holidayCalenderForm.patchValue(data.data);

        const expectedSigningDate = new Date(data.data.date);
        this.holidayCalenderForm.controls['date'].setValue({year: expectedSigningDate.getFullYear(), month: expectedSigningDate.getMonth() + 1, day: expectedSigningDate.getDate()});

      });

    } else if (this.action === "Add") {
      //alert('Add');
      this.poupuTitle = "Add Holiday";
    }
    this.holidayCalenderForm = this.createCountryForm();
  }

  createCountryForm(): FormGroup {
    return (this.holidayCalenderForm = this._formBuilder.group({
      //dateservi: ["", Validators.required],
      date: ["", Validators.required],
      description: ["", Validators.required],
    }));
  }

  selectToday() {
    this.model = this.calendar.getToday();
  }

  setCurrent() {
    //Current Date
    this.dp.navigateTo()
  }
  setDate() {
    //Set specific date
    this.dp.navigateTo({ year: 2013, month: 2 });
  }

  navigateEvent(event) {
    this.date = event.next;
  }
  get f() {
    return this.holidayCalenderForm.controls;
  }

  onSubmit(holidayCalenderForm: any) {
    this.spinnerService.show();
    this.submitted = true;
    if (this.holidayCalenderForm.invalid) {
      this.toastr.error("Error !! Record not created");
      return;
    }
    this.MasterService.createHoliday(this.holidayCalenderForm.value).subscribe((response: any) => {

      if (response.code === 1) {
        this.activeModal.close();
        this.toastr.success("Success !! " + response.msg);

        this.router.navigateByUrl("/app/refresh", { skipLocationChange: true }).then(() => {
          this.router.navigate(["/masters/holidaycalender/holidaycalenderlist"]);
        });
        return true;
      } else if (response.code == 0) {
        this.toastr.error(response.msg, "Error!");
        return false;
      } else {
        this.toastr.error("Error !! Record not created");
        return false;
      }
    },
      error => {
        console.log("failure", error);
        return false;
      }
    );

    // this.holidayCalenderForm.reset();
  }

  UpdateProjectType() {
    this.spinnerService.show();
    this.submitted = true;
    //this.options.value.token = localStorage.getItem("token");
    // this.options.value.updated_by_id = localStorage.getItem("created_by_id");
    this.holidayCalenderForm.value.id = this.id;
    if (this.holidayCalenderForm.invalid) {
      this.toastr.error("Error !! Record not created");
      return;
    }
    //alert('update funtion');
    this.MasterService.updateHoliday(this.id, this.holidayCalenderForm.value).subscribe((response: any) => {
      if (response.code == 1) {
        //alert("update")
        this.activeModal.close();
        this.toastr.success("Success !", response.msg);

        this.router
          .navigateByUrl("/app/refresh", { skipLocationChange: true })
          .then(() => {
            this.router.navigate(["/masters/holidaycalender/holidaycalenderlist"]);
          });
        return true;
      } else if (response.code == 0) {
        this.toastr.error(response.msg, "Error!");
        return false;
      } else {
        this.toastr.error("Record not created!", "Error!");
        return false;
      }
    },
      error => {
        this.toastr.warning("Internal Server Error !!", "Error!");
        return false;
      }
    );
  }

  onDateChange(newDate: Date) {
    console.log(newDate);
  }

}
