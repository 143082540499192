import { Injectable } from '@angular/core';
import { HttpClient, HttpClientModule, HttpHeaders } from "@angular/common/http";
import { from, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "./../../environments/environment";
const httpOptions = {
  headers: new HttpHeaders({
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Method": "POST",
    "Access-Control-Allow-Headers":
      "Access-Control-Allow-Headers,Content-Type,Access-Control-Allow-Methods, Authorization, X-Requested-With",
    "Access-Control-Request-Headers":
      "access-control-allow-origin, content-type"
  })
};
@Injectable({
  providedIn: 'root'
})
export class MenuMasterMainServService {



  headers: HttpHeaders | { [header: string]: string | string[] };
  constructor(private httpClient: HttpClient) { }


  private handleError(error: any): Promise<any> {
    return Promise.reject(error.message || error);
  }

  getActiveDetailsListForUpdate(id, table) {
    return this.httpClient.get(environment.url + "api/getActiveDetailsListForUpdate&table=" + table + "&id=" + id + "");
  }
  getEmpList() {
    return this.httpClient.get(environment.url + "api/getEmployeeLeaveList");
  }
  getDesignationMasterList() {
    return this.httpClient.get(environment.url + "api/getDesignationMasterList");
  }
  getMenuDetails(userMasterId: any) {
    return this.httpClient.get(environment.url + "api/getMenuMasterList&userMasterId=" + userMasterId);
  }
  getEmpListByDesignation(designationId: any): Promise<any> {
    return this.httpClient
      .post(environment.url + "api/GetEmaployeeByDesignationId&designationId=" + designationId, {
        value: designationId
      })
      .toPromise()
      .catch(this.handleError);
  }

  updateMenuStatus(FormDetails: any) {
    return this.httpClient.post<boolean>(
      environment.url + "/api/updateMenuAccessStatus",
      JSON.stringify(FormDetails)
    );
  }


  UpdateEmpMenuAccessMaster(id: any, FormDetails: any, loginId: any) {
    return this.httpClient.post<boolean>(
      environment.url + "/api/UpdateEmpMenuAccessMaster&userMasterId=" + id + "&loginId=" + loginId,
      JSON.stringify(FormDetails)
    );
  }

  getMenuList(loginId:any){
    return this.httpClient.get(environment.url + "api/getAccessWiseMenu&loginId="+loginId);
  }
}