import { Component, OnInit, Input } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MastersService } from 'src/app/services/masters.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { ToastrService } from "ngx-toastr";

@Component({
  selector: 'app-statuschange',
  templateUrl: './statuschange.component.html',
  styleUrls: ['./statuschange.component.sass']
})
export class StatuschangeComponent implements OnInit {
  EmpForm: FormGroup;
  empDetails:any;
  @Input() id;
  action: string;
  submitted: boolean;

  constructor(
    private _formBuilder: FormBuilder,
    public MasterService: MastersService,
    public route: ActivatedRoute,
    private spinnerService: Ng4LoadingSpinnerService,
    private toastr: ToastrService,
    private router: Router,

  ) { }

  ngOnInit() {
    this.id = this.route.snapshot.paramMap.get("id");
    this.MasterService.getEmpbyId(this.id).then(data => {
      // this.empDetails = data.data;   
      const resignationDate = new Date(data.data.resignationDate); 
      const leavingDate = new Date(data.data.leavingDate);

      this.EmpForm.controls['resignationDate'].setValue({ year: resignationDate.getFullYear(), month: resignationDate.getMonth() + 1, day: resignationDate.getDate() });
      this.EmpForm.controls['leavingDate'].setValue({ year: leavingDate.getFullYear(), month: leavingDate.getMonth() + 1, day: leavingDate.getDate() });
      this.EmpForm.controls['leavingReason'].setValue(data.data.leavingReason);

    });

    this.EmpForm = this.createEmpForm();
  }
  createEmpForm(): FormGroup {
    return (this.EmpForm = this._formBuilder.group({
      resignationDate: [""],
      leavingDate: [""],
      leavingReason: [""],
    }));
  }

  onSubmit(regfrm: any) {
    this.spinnerService.show();
    this.submitted = true;
    if (this.EmpForm.invalid) {
      this.toastr.error("Error !! Record not created");
      return;
    }
    this.MasterService.createLeavingDetails(this.EmpForm.value,this.id).subscribe((response: any) => {
      console.log("response", response.msg);
      if (response.code === 1) {
        this.toastr.success("Employee saved successfully..!!");

        this.router.navigateByUrl("/app/refresh", { skipLocationChange: true }).then(() => {
          this.router.navigate(["/masters/empmaster/empmasterlist"]);
        });
        return true;
      } else if (response.code == 0) {
        this.toastr.error(response.msg, "Error!");
        return false;
      } else {
        this.toastr.error("Error !! Record not created");
        return false;
      }
    },
      error => {
        console.log("failure", error);
        return false;
      }
    );
  }

  onCancel() {
    this.router.navigateByUrl("/app/refresh", { skipLocationChange: true }).then(() => {
      this.router.navigate(["/masters/empmaster/empmasterlist"]);
    });

  }
}
