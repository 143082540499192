import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MastersService } from 'src/app/services/masters.service';
import { DataTableDirective, DataTablesModule } from 'angular-datatables';
import { Subject } from 'rxjs';
import { TermsAndConditionCategoryCreateComponent } from '../../terms-and-condition-category/terms-and-condition-category-create/terms-and-condition-category-create.component';
import { ToastrService } from "ngx-toastr";
import { Router } from '@angular/router';

@Component({
  selector: 'app-terms-and-condition-category-list',
  templateUrl: './terms-and-condition-category-list.component.html',
  styleUrls: ['./terms-and-condition-category-list.component.sass']
})
export class TermsAndConditionCategoryListComponent implements OnInit {

  dtTrigger: Subject<any> = new Subject();

  dtOptions: DataTables.Settings = {};    
  termsAndConditionCategoryList = []

  datatableElement: DataTableDirective;
  constructor(private modalService: NgbModal, public ProjectTypeService: MastersService, private toastr: ToastrService,
    private router: Router) { }

  ngOnInit() {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      "columns": [
        { "orderable": false },
        { "orderable": true },
        { "orderable": true },
      ]
    };
    
    this.ProjectTypeService.getTermsAndConditionCategoryList().subscribe((e: any) => {
      this.termsAndConditionCategoryList = e.data;
      this.dtTrigger.next();      
    });
  }

  ngAfterViewInit(): void {

    this.dtTrigger.subscribe(() => {

      this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.columns().every(function (i) {
          const that = this;

          $('input', this.header()).on('click', function (e) {
            e.stopPropagation();
          });


          $('input', this.header()).on('keyup change', function () {
            if (that.search() !== this['value']) {
              if (i == 0) {
                var val = this['value'];
                that
                  .search(val ? '^' + this['value'] + '$' : val, true, false)
                  .draw();
              } else {
                that
                  .search(this['value'])
                  .draw();
              }
            }
          });
        });
      });
    });
  }

  
  onAdd() {
    // alert("add");
     const modalRef = this.modalService.open(TermsAndConditionCategoryCreateComponent);
     let action = "Add";
     modalRef.componentInstance.action = action;
   }
 
   onEdit(id) {
    // alert("edit");
     const modalRef = this.modalService.open(TermsAndConditionCategoryCreateComponent);
     let getid = id;
     let action = "Edit";
     modalRef.componentInstance.action = action;
     modalRef.componentInstance.id = getid;
   }
   
   StatusChanged(activeStatus: any, id: any) {

    if (activeStatus == 1) {
      activeStatus = 0;
    } else {
      activeStatus = 1;
    }

    this.ProjectTypeService.updatetermscategory(id, activeStatus).subscribe((response: any) => {
      if (response.code == 1) {
        this.toastr.success("Success !", response.msg);

        this.router
          .navigateByUrl("/app/refresh", { skipLocationChange: true })
          .then(() => {
            this.router.navigate(["/masters/termsandconditioncategory/termsandconditioncategorylist"]);
          });
        return true;
      } else if (response.code == 0) {
        this.toastr.error(response.msg, "Error!");
        return false;
      } else {
        this.toastr.error("Record not created!", "Error!");
        return false;
      }
    },
      error => {
        this.toastr.warning("Internal Server Error !!", "Error!");
        return false;
      }
    );
  }

 }
 


