import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from "@angular/router";
import { CustomerlistComponent } from './list/customerlist/customerlist.component';
import { CustomercreateComponent } from './create/customercreate/customercreate.component';
import { CustomerviewComponent } from './view/customerview/customerview.component';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DataTablesModule } from 'angular-datatables';
import { NgbdDatepickerPopup } from './create/customercreate/datepicker-popup';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {MarkAsteriskDirectiveModule} from '../../directives/mark-asterisk.directive';



const routes: Routes = [
  
  {
    path: "customerlist",
    component: CustomerlistComponent
  },
  {
    path: "customercreate",
    component: CustomercreateComponent
  },
  {
    path: "customerview",
    component: CustomerviewComponent
  }
];

@NgModule({
  declarations: [CustomerlistComponent,CustomercreateComponent, CustomerviewComponent,NgbdDatepickerPopup],
  imports: [
    RouterModule.forChild(routes),
    CommonModule,
    ReactiveFormsModule,
    DataTablesModule,
    NgbModule,
    MarkAsteriskDirectiveModule
    
  ],
  
  entryComponents: [
    CustomercreateComponent,
    CustomerviewComponent 
  ],
  exports: [NgbdDatepickerPopup],
  bootstrap: [NgbdDatepickerPopup],
})
export class CustomerModule { }