import { Injectable } from '@angular/core';
import { HttpClient, HttpClientModule, HttpHeaders } from "@angular/common/http";
import { from, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "./../../environments/environment";


const httpOptions = {
  headers: new HttpHeaders({
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Method": "POST",
    "Access-Control-Allow-Headers":
      "Access-Control-Allow-Headers,Content-Type,Access-Control-Allow-Methods, Authorization, X-Requested-With",
    "Access-Control-Request-Headers":
      "access-control-allow-origin, content-type"
  })
};

@Injectable({
  providedIn: 'root'
})
export class SalaryModuleService {
  headers: HttpHeaders | { [header: string]: string | string[] };
  constructor(private httpClient: HttpClient) { }

  private handleError(error: any): Promise<any> {
    return Promise.reject(error.message || error);
  }

  // Ram Garole
 

  getOtherSalaryComponentList(){
    return this.httpClient.get(environment.url + "salary/getOtherSalaryComponentList");
  }
  
  SaveSalaryComponent(FormDetails:any,loginId:any) {
    return this.httpClient.post<boolean>(
      environment.url + "salary/saveSalaryComonentDetails&loginId=" + loginId,
      JSON.stringify(FormDetails)
    );
  }

 
  
}
