import { Component, OnInit } from '@angular/core';
import { DataTableDirective, DataTablesModule } from 'angular-datatables';
import { Subject } from 'rxjs';
import { ToastrService } from "ngx-toastr";
import { Router } from '@angular/router';
import { TimesheetService } from 'src/app/services/timesheet.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
@Component({
  selector: 'app-timesheetlist',
  templateUrl: './timesheetlist.component.html',
  styleUrls: ['./timesheetlist.component.sass']
})
export class TimesheetlistComponent implements OnInit {
  dtTrigger: Subject<any> = new Subject();
  dtOptions: DataTables.Settings = {};
  timesheetList = [];
  userMasterId: any;
  isMarkedAttendance : boolean;
  isTimesheetFilled : boolean;
  isSignedOut : boolean;
  attendanceIn :string;
  attendanceOut :string;
  todayDate:string;


  datatableElement: DataTableDirective;
  constructor(
    public TimesheetService: TimesheetService,
    private toastr: ToastrService,
    private router: Router,private spinnerService: Ng4LoadingSpinnerService
  ) { }


  ngOnInit() {
    this.userMasterId = localStorage.getItem("userMasterId");
    const today = new Date();
    this.todayDate = today.getFullYear()+"-"+ ("0" + (today.getMonth() + 1)).slice(-2) +"-"+ ("0" + today.getDate()).slice(-2);

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      "columns": [
        { "orderable": false },
        { "orderable": true },
        { "orderable": true },
        { "orderable": true },
      ]
    };

    this.TimesheetService.gettimesheetlist().subscribe((e: any) => {
      this.timesheetList = e.data;
      this.dtTrigger.next();
    });

    this.TimesheetService.getAttendanceDatails(this.userMasterId).subscribe((e: any) => {
      if(e.data.attendanceDetails != ""){
        this.isMarkedAttendance = true;
        this.attendanceIn = e.data.attendanceDetails.in_time;
        this.attendanceOut =  e.data.attendanceDetails.out_time;
        if(this.attendanceOut != "00:00:00"){
          this.isSignedOut = true;
        }else{
          this.isSignedOut = false;
        }
      }else{
        this.isMarkedAttendance = false;
      }

      if(e.data.timeDetails != ""){
        this.isTimesheetFilled = true;
      }else{
        this.isTimesheetFilled = false;
      }

    });
  }

  ngAfterViewInit(): void {

    this.dtTrigger.subscribe(() => {

      this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.columns().every(function (i) {
          const that = this;

          $('input', this.header()).on('click', function (e) {
            e.stopPropagation();
          });


          $('input', this.header()).on('keyup change', function () {
            if (that.search() !== this['value']) {
              if (i == 0) {
                var val = this['value'];
                that
                  .search(val ? '^' + this['value'] + '$' : val, true, false)
                  .draw();
              } else {
                that
                  .search(this['value'])
                  .draw();
              }
            }
          });
        });
      });
    });
  }

  onAdd(){
    this.spinnerService.show();
    if(this.isMarkedAttendance == true){
      let action="Add";
      this.router.navigate(["timesheet/timesheet/timesheetcreate",action]);
    }else{
      this.toastr.error("Error! Please mark attendance first");
    }
  }

  onEdit(id) {
    this.spinnerService.show();
    let getid = id;
    let action = "Edit";
    this.router.navigate(["timesheet/timesheet/timesheetcreate",getid,action]);
  }

  onView(id){
    this.spinnerService.show();
    let getid = id;
    this.router.navigate(["timesheet/timesheet/timesheetview",getid]);
  }

  signOut(){
    this.spinnerService.show();
    if(this.isTimesheetFilled == true){
      this.TimesheetService.signOutAttendance(this.userMasterId).subscribe((response: any) => {
        if (response.code == 1) {
          this.toastr.success("Signed out Successfully !", response.msg);
          this.router
            .navigateByUrl("/app/refresh", { skipLocationChange: true })
            .then(() => {
              this.router.navigate(["/timesheet/timesheet/timesheetlist"]);
            });
          return true;
        } else if (response.code == 0) {
          this.toastr.error(response.msg, "Error!");
          return false;
        } else {
          this.toastr.error("Attendance not created!", "Error!");
          return false;
        }
      },
        error => {
          this.toastr.warning("Internal Server Error !!", "Error!");
          return false;
        }
      );
    }else{
      this.toastr.error("Error! Please fill timesheet first");
      return false;
    }
  }

  markAttendanceForToday(){
    this.spinnerService.show();
    this.TimesheetService.markAttendance(this.userMasterId).subscribe((response: any) => {
      if (response.code == 1) {
        this.toastr.success("Attendance marked Successfully !", response.msg);
        this.router
          .navigateByUrl("/app/refresh", { skipLocationChange: true })
          .then(() => {
            this.router.navigate(["/timesheet/timesheet/timesheetlist"]);
          });
        return true;
      } else if (response.code == 0) {
        this.toastr.error(response.msg, "Error!");
        return false;
      } else {
        this.toastr.error("Attendance not created!", "Error!");
        return false;
      }
    },
      error => {
        this.toastr.warning("Internal Server Error !!", "Error!");
        return false;
      }
    );
  }

}
