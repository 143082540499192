import { Component, OnInit } from '@angular/core';
import { Input } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from "ngx-toastr";
import { MastersService } from 'src/app/services/masters.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';

@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.sass']
})
export class CreateComponent implements OnInit {

  @Input() id;
  @Input() action;

  proposalCreateFrom: FormGroup;
  poupuTitle: string;
  submitted: boolean;
  proposalTypeList: [];
  constructor(
    private _formBuilder: FormBuilder,
    private toastr: ToastrService,
    private router: Router,
    public MasterService: MastersService,
    public activeModal: NgbActiveModal,private spinnerService: Ng4LoadingSpinnerService) { }

  ngOnInit() {
    this.action = this.action;

    const token_key = localStorage.getItem("token");
    this.MasterService.getproposalTemplatesTypeList().subscribe((e: any) => {
      //console.log(e.data);
      this.proposalTypeList = e.data;
    });
    if (this.action === "Edit") {
      this.poupuTitle = "Edit Proposal Templates Master";
      this.MasterService.getProposalDetails(this.id).then(data => {
        this.proposalCreateFrom.patchValue(data.data[0]);
      });

    } else if (this.action === "Add") {
      this.poupuTitle = "Add Proposal Templates Master";
    }
    this.proposalCreateFrom = this.createCountryForm();
  }

  createCountryForm(): FormGroup {
    return (this.proposalCreateFrom = this._formBuilder.group({
      proposalTypeId: ["", Validators.required],
      template: ["", Validators.required],
    }));
  }

  get f() {
    return this.proposalCreateFrom.controls;
  }

  onSubmit(regfrm: any) {
    this.spinnerService.show();
    this.submitted = true;
    if (this.proposalCreateFrom.invalid) {
      this.toastr.error("Error !! Record not created");
      return;
    }
    this.MasterService.createProposalTemplates(this.f.proposalTypeId.value, this.f.template.value).subscribe((response: any) => {

      if (response.code === 1) {
        this.activeModal.close();
        this.toastr.success("Success !! " + response.msg);

        this.router.navigateByUrl("/app/refresh", { skipLocationChange: true }).then(() => {
          this.router.navigate(["/masters/proposaltemplatescreation/proposaltemplatescreationlist"]);
        });
        return true;
      } else if (response.code == 0) {
        this.toastr.error(response.msg, "Error!");
        return false;
      } else {
        this.toastr.error("Error !! Record not created");
        return false;
      }
    },
      error => {
        console.log("failure", error);
        return false;
      }
    );

    // this.regfrm.reset();
  }

  UpdateCompany() {
    this.spinnerService.show();
    this.submitted = true;
    //this.options.value.token = localStorage.getItem("token");
    // this.options.value.updated_by_id = localStorage.getItem("created_by_id");
    this.proposalCreateFrom.value.id = this.id;
    if (this.proposalCreateFrom.invalid) {
      this.toastr.error("Error !! Record not created");
      return;
    }
    this.MasterService.updateProposalTemplates(this.id, this.f.proposalTypeId.value, this.f.template.value).subscribe(
      (response: any) => {
        if (response.code == 1) {
          this.activeModal.close();
          this.toastr.success("Success !", response.msg);

          this.router
            .navigateByUrl("/app/refresh", { skipLocationChange: true })
            .then(() => {
              this.router.navigate(["/masters/proposaltemplatescreation/proposaltemplatescreationlist"]);
            });
          return true;
        } else if (response.code == 0) {
          this.toastr.error(response.msg, "Error!");
          return false;
        } else {
          this.toastr.error("Record not created!", "Error!");
          return false;
        }
      },
      error => {
        this.toastr.warning("Internal Server Error !!", "Error!");
        return false;
      }
    );
  }
}
