import { Component, OnInit, Input } from '@angular/core';
import { NgModule } from '@angular/core';
import { NgbDateStruct, NgbCalendar, NgbDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from "ngx-toastr";
import { MastersService } from 'src/app/services/masters.service';
import { data } from 'jquery';
import { FormGroup, FormBuilder, FormArray, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { removeSpaces } from '../../project-type/create/proejctcreate/projectType.validator';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';

@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.sass'],
})
export class CreateComponent implements OnInit {

  @Input() id;
  @Input() action;
  customerForm: FormGroup;
  poupuTitle: string;
  submitted: boolean;

  model: NgbDateStruct;
  //myDateValue: Date;
  userMasterId: any;
  date: { date: number, month: number, year: number };
  @ViewChild('dp') dp: NgbDatepicker;
  endminDate: any;


  constructor(
    private modalService: NgbModal,
    private calendar: NgbCalendar,
    private _formBuilder: FormBuilder,
    private toastr: ToastrService,
    private router: Router,
    public MasterService: MastersService,
    public activeModal: NgbActiveModal,
    private spinnerService: Ng4LoadingSpinnerService
  ) { }


  ngOnInit() {
    this.userMasterId = localStorage.getItem("userMasterId");
    this.action = this.action;
    const token_key = localStorage.getItem("token");
    //alert(this.id);
    //alert(this.action);
    if (this.action === "Edit") {
      this.poupuTitle = "Edit Financial Year";
      this.MasterService.GetFinancialYearMasterbyId(this.id).then(data => {
        // console.log("datapatch", data.data);
        // this.customerForm.patchValue(data.data);
        const start_date = new Date(data.data.start_date);
        this.customerForm.controls['start_date'].setValue({ day: start_date.getDate(), month: start_date.getMonth() + 1, year: start_date.getFullYear(), });

        const end_date = new Date(data.data.end_date);
        this.customerForm.controls['end_date'].setValue({ day: end_date.getDate(), month: end_date.getMonth() + 1, year: end_date.getFullYear() });

      });

    } else if (this.action === "Add") {
      this.poupuTitle = "Add Financial Year";
    }
    this.customerForm = this.createFinancialYearMasterForm();
  }

  createFinancialYearMasterForm(): FormGroup {
    return (this.customerForm = this._formBuilder.group({
      start_date: ['', Validators.required],
      end_date: ['', Validators.required],
    }));
  }




  selectToday() {
    this.model = this.calendar.getToday();
  }
  setCurrent() {
    //Current Date
    this.dp.navigateTo()
  }
  setDate() {
    //Set specific date
    this.dp.navigateTo({ year: 2013, month: 2 });
  }

  navigateEvent(event) {
    this.date = event.next;
  }

  get f() {
    return this.customerForm.controls;
  }

  UpdateFinancialYearMaster() {
    this.spinnerService.show();
    this.submitted = true;
    //this.options.value.token = localStorage.getItem("token");
    // this.options.value.updated_by_id = localStorage.getItem("created_by_id");
    this.customerForm.value.id = this.id;
    if (this.customerForm.invalid) {
      this.toastr.error("Error !! Record not created");
      return;
    }
    this.MasterService.UpdateFinancialYearMaster(this.id, this.customerForm.value, this.userMasterId).subscribe((response: any) => {
      if (response.code == 1) {
        this.activeModal.close();
        this.toastr.success("Success !", response.msg);

        this.router
          .navigateByUrl("/app/refresh", { skipLocationChange: true })
          .then(() => {
            this.router.navigate(["/masters/financialyearyaster/financialyearyasterlist"]);
          });
        return true;
      } else if (response.code == 0) {
        this.toastr.error(response.msg, "Error!");
        return false;
      } else {
        this.toastr.error("Record not created!", "Error!");
        return false;
      }
    },
      error => {
        this.toastr.warning("Internal Server Error !!", "Error!");
        return false;
      }
    );

  }

  onSubmit() {
    this.spinnerService.show();
    this.submitted = true;
    if (this.customerForm.invalid) {
      this.toastr.error("Error !! Record not created");
      return;
    }

    this.MasterService.creatUpdateFinancialYearMaster(this.customerForm.value, this.userMasterId).subscribe((response: any) => {
      console.log("response", response.msg);
      if (response.code === 1) {
        this.activeModal.close();
        this.toastr.success("Success !! " + response.msg);
        this.router.navigateByUrl("/app/refresh", { skipLocationChange: true }).then(() => {
          this.router.navigate(["/masters/financialyearyaster/financialyearyasterlist"]);
        });
        return true;
      } else if (response.code == 0) {
        this.toastr.error(response.msg, "Error!");
        return false;
      } else {
        this.toastr.error("Error !! Record not created");
        return false;
      }
    },
      error => {
        console.log("failure", error);
        return false;
      }
    );
  }

  onCancel() {
    console.log("Closed");
    this.activeModal.close();
  }

  alphabetOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if ((charCode > 64 && charCode < 91) || (charCode > 96 && charCode < 123) || charCode == 8 || (charCode == 32)) {
      // this.onlyAlphabet =false;

      return true;
    } else {
      //this.onlyAlphabet =true;
      //alert("only alphabates are allowed")
      return false;
    }
  }

  getdate(event){
    this.customerForm.controls['end_date'].setValue('');
    let year = event.year;
    let month = event.month <= 9 ? '0' + event.month : event.month;;
    let day = event.day <= 9 ? '0' + event.day : event.day;;
    let finalDate = year + "-" + month + "-" + day;
    this.endminDate = finalDate;
    const current = new Date(finalDate);
    this.endminDate = {
      year: current.getFullYear(),
      month: current.getMonth() + 1,
      day: current.getDate()
    };
  }

}
