import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RouterModule, Routes } from "@angular/router";
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DataTablesModule } from 'angular-datatables';

import { TermsConditionListComponent} from './terms-condition-list/terms-condition-list.component';
import { TermsConditionCreateComponent } from './terms-condition-create/terms-condition-create.component';


const routes: Routes = [
  
  {
    path: "termsandconditionslist",
    component: TermsConditionListComponent
  },
  {
    path: "termsandconditionscreate",
    component: TermsConditionCreateComponent
  }
];


@NgModule({
  declarations: [TermsConditionCreateComponent,TermsConditionListComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    CommonModule,
    ReactiveFormsModule,
    DataTablesModule
  ],
  entryComponents: [
    TermsConditionCreateComponent
  ]
})
export class TermsConditionModule { }
