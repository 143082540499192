import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MarkAsteriskDirectiveModule} from '../../directives/mark-asterisk.directive';
import { RouterModule, Routes } from "@angular/router";
import { OccupationlistComponent } from './list/occupationlist/occupationlist.component';
import { OccupationcreateComponent } from './create/occupationcreate/occupationcreate.component';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DataTablesModule } from 'angular-datatables';



const routes: Routes = [
  
  {
    path: "occupationlist",
    component: OccupationlistComponent
  },
  {
    path: "occupationcreate",
    component: OccupationcreateComponent
  }
];

@NgModule({
  declarations: [OccupationlistComponent,OccupationcreateComponent],
  imports: [
    RouterModule.forChild(routes),
    CommonModule,
    ReactiveFormsModule,
    DataTablesModule,
    MarkAsteriskDirectiveModule
    
  ],
  
  entryComponents: [
    OccupationcreateComponent  ]
})
export class OccupationModule { }