import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from "@angular/router";
import { LocationStrategy, HashLocationStrategy } from "@angular/common";
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DataTablesModule } from 'angular-datatables';
import { ServicesCreateComponent } from './services-create/services-create.component';
import { ServicesListComponent } from './services-list/services-list.component';
import {MarkAsteriskDirectiveModule} from '../../directives/mark-asterisk.directive';


const routes: Routes = [
  
  {
    path: "serviceslist",
    component: ServicesListComponent
  },
  {
    path: "servicescreate",
    component: ServicesCreateComponent
  }
];


@NgModule({
  declarations: [ServicesCreateComponent,ServicesListComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    CommonModule,
    ReactiveFormsModule,
    DataTablesModule,
    MarkAsteriskDirectiveModule
  ],
  entryComponents: [
    ServicesCreateComponent  
  ]
})
export class ServicesModule { }
