import { Component, OnInit } from '@angular/core';
import { Input } from '@angular/core';
import {ActivatedRoute,Router } from '@angular/router';
import { ToastrService } from "ngx-toastr";
import { OpportunityModuleService } from 'src/app/services/opportunity-module.service';

@Component({
  selector: 'app-opportunity-view',
  templateUrl: './opportunity-view.component.html',
  styleUrls: ['./opportunity-view.component.sass']
})
export class OpportunityViewComponent implements OnInit {
  @Input() id;
  opportunityData = [];
  opportunityDetailsData = [];
  contactDetailsData = [];

  constructor(
    private toastr: ToastrService,
    private router: Router,
    private route: ActivatedRoute,
    public OpportunityModuleService: OpportunityModuleService,
  ) { }

  ngOnInit() {
    const token_key = localStorage.getItem("token");
    this.id = this.route.snapshot.paramMap.get("id");
    this.OpportunityModuleService.GetOpportunityDetailsByIdForView(this.id).then(data => {
      this.opportunityData = data.data.opportunity;
      this.opportunityDetailsData = data.data.opportunityDetails;
      this.contactDetailsData = data.data.contactDetails;

    });
  }
  getstatus(status:any){

    if(status == 1){
      return "Pending";
    }else if(status == 2){
      return "Partial Interested";
    }else if(status == 3){
      return "Interested";
    }else if(status == 4){
      return "Not Interested";
    }
    return "NA";
  }


  ProjectScale(status:any){
    if(status == 1){
      return "Large >4cr";
    }else if(status == 2){
      return "Mid 40L< 4cr";
    }else if(status == 3){
      return "Small < 40L";
    }
    return "NA";
  }
  onCancel(){
    this.router.navigateByUrl("/app/refresh", { skipLocationChange: true }).then(() => {
      this.router.navigate(["/opportinityModule/opportunity/opportunityList"]);
    });
  }
}
